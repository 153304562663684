import {
  colorBlueLighter,
  colorSteelDarkest,
  colorSteelLighter,
  fontSizeMedium,
  fontWeightMedium,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { HTMLProps, ReactNode } from "react";

type PassThroughProps = Pick<HTMLProps<HTMLDivElement>, "style" | "className">;

interface Props extends PassThroughProps {
  children: ReactNode;
}

export const Alert = ({ children, ...passThroughProps }: Props) => {
  return (
    <div
      {...passThroughProps}
      css={css`
        background: ${colorBlueLighter};
        border: 1px solid ${colorSteelLighter};
        border-radius: 6px;
        box-sizing: border-box;
        color: ${colorSteelDarkest};
        font-weight: ${fontWeightMedium};
        font-size: ${fontSizeMedium};
        padding: 11px 24px;
      `}
    >
      {children}
    </div>
  );
};

export default Alert;
