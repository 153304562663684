import { pagesIndexName } from "@10xdev/algolia-utils/index-names";
import type { FunctionComponent } from "react";

import Search from "./Search";
import SearchLayout from "./SearchLayout";

const SiteSearch: FunctionComponent = () => {
  return (
    <Search
      hitsRenderer={({ onHits, searchState }) => {
        return <SearchLayout onHits={onHits} searchState={searchState} />;
      }}
      indexName={pagesIndexName.toString()}
    />
  );
};

export default SiteSearch;
