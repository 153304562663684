import { css } from "@emotion/react";

import ConditionalLink from "../../../ConditionalLink";
import Image from "../../../Image";
import Text from "../../../Text";

type Row = {
  href?: string;
  image: string;
  label: string;
};

export interface Props {
  list: Row[];
}

const Row = ({ href, image, label }: Row) => {
  return (
    <li
      css={css`
        display: grid;
        grid-template-columns: 38px 1fr;
        gap: 2.25rem;
        align-items: center;
        @media (max-width: 600px) {
          gap: 1rem;
        }
      `}
    >
      <Image
        alt={label}
        css={css`
          transform: translateY(2px);
        `}
        src={image}
      />
      <ConditionalLink href={href}>
        <Text
          as={"span"}
          color={href ? "inherit" : "base"}
          size={"small"}
          weight={href ? "medium" : "regular"}
        >
          {label}
        </Text>
      </ConditionalLink>
    </li>
  );
};

const CardF = ({ list }: Props) => {
  return (
    <ul
      css={css`
        display: grid;
        gap: 1rem;
        padding: 0;
        margin: 0;
      `}
    >
      {list.map((item) => {
        return <Row key={item.label} {...item} />;
      })}
    </ul>
  );
};

export default CardF;
