import { borderStandard, boxShadowStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../Icon";

interface Props {
  /**
   * A flag indicating whether the component should
   * render in an active state, such as when the
   * user is interacting with it or a related component.
   */
  active?: boolean;

  /**
   * A shorthand value for both icon size, in pixels.
   */
  iconSize?: number;

  /**
   * A shorthand value for both height and width, in pixels.
   */
  size?: number;

  /**
   * Nudge the play icon left/right using neg/pos number in pixels
   */
  iconXPos?: string;

  /**
   * Nudge the play icon up/down using neg/pos number in pixels
   */
  iconYPos?: string;
}

const PlayButton: FunctionComponent<Props> = ({
  active,
  iconSize = 28,
  size = 64,
  iconXPos = "3px",
  iconYPos = "1px",
}) => {
  return (
    <div
      css={css`
        align-items: center;
        background-color: rgba(255, 255, 255, 0.9);
        border: ${borderStandard};
        border-radius: 100%;
        box-shadow: ${boxShadowStandard};
        display: flex;
        height: 100%;
        justify-content: center;
        margin: 0;
        max-height: ${size}px;
        max-width: ${size}px;
        padding: 0;
        position: relative;
        width: 100%;
      `}
    >
      <Icon
        color={active ? "blue" : "base"}
        size={`${iconSize}px`}
        source={"play"}
        xPos={iconXPos}
        yPos={iconYPos}
      />
    </div>
  );
};

export default PlayButton;
