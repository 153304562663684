import { borderRadiusMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Icon from "../../Icon";
import Text from "../../Text";
import type { CalloutLevel } from "./types";
import { getCalloutLevel } from "./utils";

interface Props {
  children: ReactNode;
  className?: string;
  icon?: boolean;
  level?: CalloutLevel;
  title?: string;
}

const Callout = ({
  children,
  className,
  icon = true,
  level = "info",
  title,
}: Props) => {
  const { iconSource, iconColor, backgroundColor } = getCalloutLevel(level);
  return (
    <div
      className={className}
      css={css`
        background: ${backgroundColor};
        border-radius: ${borderRadiusMedium};
        display: grid;
        gap: 0.5rem 1rem;
        grid-template-columns: ${icon ? "16px 1fr" : "1fr"};
        padding: ${title ? "1rem 2rem 1.75rem" : "0.75rem 1.5rem"};
      `}
    >
      {icon ? (
        <Icon
          color={title ? "midgray" : iconColor}
          css={css`
            align-self: start;
          `}
          size={"16px"}
          source={iconSource}
          yPos={"5px"}
        />
      ) : null}

      {title ? (
        <Text
          as={"div"}
          color={"steelDarkest"}
          size={"medium"}
          weight={"semibold"}
        >
          {title}
        </Text>
      ) : null}

      <Text
        as={"div"}
        css={css`
          ${title ? "grid-column: 1 / 3" : null};
        `}
        size={"small"}
        weight={title ? "regular" : "medium"}
      >
        {children}
      </Text>
    </div>
  );
};

export default Callout;
