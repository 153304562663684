import type { TextColor } from "@10xdev/types";
import {
  colorBlueMedium,
  colorGreyscale7,
  colorNeonGreenLight,
  colorWhite,
  layoutWidth1200,
  mediaDesktop,
  mediaTabletLandscape,
  spacing16,
  spacing24,
  spacing32,
  spacing48,
  spacing64,
  spacing72,
  spacing128,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Heading from "../../Heading";
import Slash from "../../Slash";
import type { colors } from "../../Text";
import Text from "../../Text";
import type { ComponentColorMode, Mode } from "../types";

interface Props {
  body?: string;
  bottomSpacing?: SectionSpacings;
  children: ReactNode;
  className?: string;
  layout?: "horizontal" | "vertical";
  mode?: Mode;
  slash?: boolean;
  title?: string;
  topSpacing?: SectionSpacings;
}
interface ThemeProps {
  background: string;
  color: keyof typeof colors;
  headingColor: TextColor;
  slashColor: string;
}
const themes: ComponentColorMode<ThemeProps> = {
  dark: {
    background: colorGreyscale7,
    color: "white",
    headingColor: "white",
    slashColor: colorNeonGreenLight,
  },
  light: {
    background: colorWhite,
    color: "base",
    headingColor: "base",
    slashColor: colorBlueMedium,
  },
};

const sectionSpacings = {
  narrow: { desktop: spacing48, mobile: spacing24 },
  none: { desktop: 0, mobile: 0 },
  regular: { desktop: spacing72, mobile: spacing32 },
  wide: { desktop: spacing128, mobile: spacing48 },
};
export type SectionSpacings = keyof typeof sectionSpacings;

const Section = ({
  body,
  bottomSpacing,
  className,
  children,
  layout = "vertical",
  mode = "dark",
  slash = false,
  title,
  topSpacing,
}: Props) => {
  const theme = mode === "dark" ? themes.dark : themes.light;
  const marginBottom = bottomSpacing && sectionSpacings[bottomSpacing];
  const marginTop = topSpacing && sectionSpacings[topSpacing];

  return (
    <div
      className={className}
      css={css`
        background: ${theme.background};
        box-sizing: border-box;
        color: ${theme.color};
        margin: 0 auto;
        margin-bottom: ${marginBottom?.mobile};
        margin-top: ${marginTop?.mobile};
        max-width: ${layoutWidth1200};
        padding: 0 ${spacing24};
        width: 100%;

        @media (min-width: ${mediaTabletLandscape}) {
          margin: 0 auto;
          margin-bottom: ${marginBottom?.desktop};
          margin-top: ${marginTop?.desktop};
        }

        @media (min-width: ${mediaDesktop}) {
          padding: 0;
        }
      `}
    >
      <Slash slash={slash} slashColor={theme.slashColor}>
        <div
          css={css`
            display: flex;
            flex-direction: ${layout === "vertical" ? "column" : "row"};
            gap: ${spacing16};

            @media (min-width: ${mediaTabletLandscape}) {
              gap: ${spacing24};
            }
          `}
        >
          {title || body ? (
            <div
              css={css`
                display: flex;
                ${layout === "horizontal" && "flex: 46%;"}
                flex-direction: column;
                gap: ${spacing16};
                max-width: 720px;

                @media (min-width: ${mediaTabletLandscape}) {
                  gap: ${spacing24};
                }
              `}
            >
              <Heading
                as={"h3"}
                color={theme.headingColor}
                css={css`
                  margin: 0;
                `}
                responsive={true}
                size={"xlarge"}
              >
                {title}
              </Heading>
              <Text as={"p"} color={theme.color} size={"small"}>
                {body}
              </Text>
            </div>
          ) : null}
          <div
            css={css`
              ${layout === "horizontal" && "flex: 54%;"}
              display: flex;
              flex-direction: column;
              gap: ${spacing16};

              @media (min-width: ${mediaTabletLandscape}) {
                gap: ${spacing64};
              }
            `}
          >
            {children}
          </div>
        </div>
      </Slash>
    </div>
  );
};

export default Section;
