import { compatibleProductsIndexName } from "@10xdev/algolia-utils/index-names";
import type { FunctionComponent } from "react";

import Search from "../Search";
import Filters from "../Search/Filters";
import { COMPATIBLE_PRODUCT_FACETS } from "./constants";
import ProductGroupList from "./ProductGroupList";

const CompatibleProducts: FunctionComponent = () => {
  return (
    <>
      <Search
        hitsRenderer={({ onHits }) => {
          return <ProductGroupList onHits={onHits} />;
        }}
        includeStats={false}
        indexName={compatibleProductsIndexName.toString()}
        placeholder={"Search compatible products"}
        sidebar={<Filters facets={COMPATIBLE_PRODUCT_FACETS} title={""} />}
      />
    </>
  );
};

export default CompatibleProducts;
