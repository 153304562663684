import type { TextColor, TextSize } from "@10xdev/types";
import { colorBlueLighter, colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  color?: TextColor;
  container?: ContainerProps;
  list: string[];
  size?: TextSize;
}

const OrderedList = ({
  color = "base",
  container,
  list,
  size = "medium",
}: Props) => {
  if (container) {
    container.as = "ol";
  }

  return (
    <Container {...container}>
      {list.map((item, index) => {
        return (
          <div
            css={css`
              display: flex;
              gap: 1rem;
              align-items: center;
            `}
            key={item}
          >
            <Text
              as={"div"}
              color={"blue"}
              css={css`
                align-items: center;
                background: ${colorBlueLighter};
                border-radius: 50%;
                color: ${colorBlueMedium};
                display: flex;
                font-weight: bold;
                height: 30px;
                justify-content: center;
                width: 30px;
              `}
              size={"small"}
            >
              {index + 1}
            </Text>
            <Text
              as={"li"}
              color={color}
              css={css`
                list-style: none;
              `}
              size={size}
            >
              {item}
            </Text>
          </div>
        );
      })}
    </Container>
  );
};

export default OrderedList;
