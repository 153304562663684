import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import type { ScrollbarProps } from "react-scrollbars-custom";
import ReactScrollbarsCustom from "react-scrollbars-custom";
import type { ElementPropsWithElementRef } from "react-scrollbars-custom/dist/types/types";
import { useIntersection } from "react-use";

interface ScrollbarsProps extends Omit<ScrollbarProps, "ref"> {
  children?: ReactNode;
}

const Scrollbar = (props: ScrollbarsProps) => {
  const { children, ...rest } = props;
  const intersectionRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, {});

  const trackProps = {
    renderer: ({
      elementRef,
      style,
      ...restProps
    }: ElementPropsWithElementRef) => {
      const isObscured = intersection && intersection.intersectionRatio < 1;
      const offsetLeft =
        intersectionRef.current?.getBoundingClientRect().left ?? 0;
      return (
        <div
          css={css`
            bottom: 0;
            position: ${isObscured ? "fixed" : "absolute"};
            width: calc(100vw - ${offsetLeft}px - 20px);
          `}
        >
          <span
            {...restProps}
            ref={elementRef}
            style={{
              ...style,
            }}
          />
        </div>
      );
    },
  };

  const wrapperProps = {
    renderer: ({
      elementRef,
      style,
      ...restProps
    }: ElementPropsWithElementRef) => (
      <div {...restProps} ref={elementRef} style={{ ...style, right: 0 }} />
    ),
  };

  const FallbackComponent = () => <>{children}</>;
  return (
    /** Render the fallback component if there is an error rendering the Scrollbar component */
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <ReactScrollbarsCustom
        scrollDetectionThreshold={500} //ms
        trackXProps={trackProps}
        trackYProps={trackProps}
        wrapperProps={wrapperProps}
        {...rest}
      >
        {children}
      </ReactScrollbarsCustom>
      <div ref={intersectionRef} />
    </ErrorBoundary>
  );
};

export default Scrollbar;
