import { css } from "@emotion/react";
import { memo } from "react";

import CheckBox from "../../Form/CheckBox";
import RadioOrCheckBoxLabel from "../../Form/RadioOrCheckboxLabel";
import Text from "../../Text";
import type { MultiSelectFilterProp, MultiSelectMenuValue } from "./types";

interface Props {
  id: string;
  items?: MultiSelectFilterProp["items"];
  onSelect: (selected: {
    checkedValue: string | number;
    id: string;
    value: MultiSelectMenuValue;
  }) => void;
  selectedValues?: MultiSelectMenuValue;
  showCount?: boolean;
}

const MultiSelectMenu = ({
  id,
  items,
  onSelect,
  selectedValues = [],
  showCount = false,
}: Props) => {
  const handleChange = (checkedValue: string | number) => () => {
    const newValue = !selectedValues.includes(checkedValue)
      ? [...selectedValues, checkedValue]
      : selectedValues.filter((item) => item !== checkedValue);
    onSelect({ checkedValue, id, value: newValue });
  };

  return (
    <>
      {items?.map((item) => (
        <RadioOrCheckBoxLabel key={item.label}>
          <CheckBox
            checked={item.checked || selectedValues.includes(item.value)}
            onChange={handleChange(item.value)}
            value={""}
          />
          <div css={css``}>
            <Text as={"span"} size={"xsmall"}>
              {item.label}
            </Text>
            {showCount ? (
              <Text as={"span"} color={"steelMedium"} size={"xsmall"}>
                {` (${item.count})`}
              </Text>
            ) : null}
          </div>
        </RadioOrCheckBoxLabel>
      ))}
    </>
  );
};

export default memo(MultiSelectMenu);
