import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Image from "../../../Image";
import Links from "../../../Links";
import Text from "../../../Text";
import type { SectionImage, SectionLink } from "../types";

export interface Props {
  description: string;
  image: SectionImage;
  links?: SectionLink[];
  title?: string;
}

const CardH = ({ description, image, links, title }: Props) => {
  return (
    <Grid
      alignContent={"start"}
      css={css`
        flex: 1 1 0;
      `}
      gap={{ base: "0.5rem", tabletPortrait: "1.5rem" }}
      gridTemplateColumns={{ base: "1fr 1fr", tabletPortrait: "1fr" }}
    >
      {image.src && <Image alt={image.alt} src={image.src} />}
      <Grid gap={{ base: "0.5rem", tabletPortrait: "1rem" }}>
        {title && (
          <Text as={"h4"} size={"large"} weight={"semibold"}>
            {title}
          </Text>
        )}
        {description && (
          <Text as={"p"} color={"midgray"} size={"small"}>
            {description}
          </Text>
        )}
        {links?.length ? <Links links={links} size={"small"} /> : null}
      </Grid>
    </Grid>
  );
};

export default CardH;
