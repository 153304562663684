import {
  layoutWidth1200,
  mediaTabletLandscape,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";

interface Props {
  eyebrow?: string;
  highlights: string[];
  image: {
    alt: string;
    source: string;
  };
  links?: Array<{
    label: string;
    url: string;
  }>;
  title: string;
}

const InstrumentCard = ({
  eyebrow,
  highlights,
  image,
  links,
  title,
}: Props) => {
  return (
    <div
      css={css`
        display: grid;
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;
        row-gap: 2rem;
        @media (min-width: ${mediaTabletLandscape}) {
          grid-template-columns: 3fr 4fr 3fr;
        }
      `}
    >
      <Image
        alt={image.alt}
        css={css`
          transform: scale(1.25);
          @media (min-width: ${mediaTabletLandscape}) {
            transform: scale(2.5) translate(-27.5%, 1rem);
          }
        `}
        src={image.source}
      />
      <div
        css={css`
          box-sizing: border-box;
          padding-right: 2rem;
        `}
      >
        <Text
          as={"p"}
          color={"blue"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          size={"large"}
          weight={"medium"}
        >
          {eyebrow}
        </Text>
        <Text
          as={"p"}
          css={css`
            margin-bottom: 2.5rem;
          `}
          size={"xxlarge"}
          weight={"semibold"}
        >
          {title}
        </Text>
        {links ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1rem;
            `}
          >
            {links.map((link) => (
              <Anchor href={link.url} key={link.url}>
                <Text
                  as={"span"}
                  color={"inherit"}
                  css={css`
                    margin-right: 0.75rem;
                  `}
                  size={"small"}
                  weight={"medium"}
                >
                  {link.label}
                </Text>
                <Icon size={"11px"} source={"nav-right"} />
              </Anchor>
            ))}
          </div>
        ) : null}
      </div>
      <ul
        css={css`
          display: grid;
          gap: 3rem;
          margin: 0;
          padding: 0;
        `}
      >
        {highlights && highlights.length
          ? highlights.map((highlight) => {
              return (
                <li
                  css={css`
                    background: url("https://cdn.10xgenomics.com/image/upload/v1588968667/icons/checkmark.svg")
                      no-repeat;
                    list-style: none;
                    padding-left: ${sizeXxlarge};
                  `}
                  key={highlight}
                >
                  <Text as={"span"} size={"medium"}>
                    {highlight}
                  </Text>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default InstrumentCard;
