import { mediaTabletPortrait } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  description: string;
  product: {
    name: string | null;
    slug: string;
  };
  url: string;
}

const ProductCard: FunctionComponent<Props> = ({
  description,
  product,
  url,
}) => {
  return (
    <Anchor
      css={css`
        display: block;
        margin: 0;
      `}
      href={url}
    >
      <Card
        css={css`
          min-height: 215px;
          padding: 1.5rem 3rem 2rem 3rem;

          :hover {
            cursor: pointer;
            .product-card-link {
              opacity: 1;
            }
          }
        `}
        hoverable={true}
      >
        <div
          css={css`
            box-sizing: border-box;
            display: flex;
            flex: 1;
            flex-wrap: nowrap;
            width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: flex-start;
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: 1rem;
              `}
              responsive={true}
              size={"medium"}
            >
              {product.name}
            </Heading>
            <Text
              as={"p"}
              color={"midgray"}
              css={css`
                margin-bottom: 1rem;
              `}
              responsive={true}
              size={"small"}
            >
              {description}
            </Text>

            {url ? (
              <div
                aria-label={`learn more about ${product.name}`}
                className={"product-card-link"}
                css={css`
                  margin-top: auto;
                  opacity: 0;
                  transition: opacity 0.2s ease-in;

                  @media (max-width: ${mediaTabletPortrait}) {
                    margin-top: 0;
                    opacity: 1;
                  }
                `}
              >
                <Text
                  as={"span"}
                  color={"blue"}
                  size={"small"}
                  weight={"medium"}
                >
                  {"Learn more"}
                  <Icon
                    size={"11px"}
                    source={"nav-right"}
                    xPos={"16px"}
                    yPos={"1px"}
                  />
                </Text>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
    </Anchor>
  );
};

export default ProductCard;
