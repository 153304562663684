import {
  sizeLarge,
  sizeMedium,
  sizeSmall,
  sizeXlarge,
  sizeXsmall,
  sizeXxlarge,
  sizeXxsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { TabGroup } from "@headlessui/react";
import type { ReactNode } from "react";

import { Tab } from "./Tab";
import { TabHeader } from "./TabHeader";
import { TabList } from "./TabList";
import { TabPanel, TabPanels } from "./TabPanels";

const gapSizeStyles = {
  xxlarge: sizeXxlarge,
  xlarge: sizeXlarge,
  large: sizeLarge,
  medium: sizeMedium,
  small: sizeSmall,
  xsmall: sizeXsmall,
  xxsmall: sizeXxsmall,
};

type GapSize = keyof typeof gapSizeStyles;

export const Tabs = ({
  children,
  gapSize = "medium",
  maxWidth,
  minWidth,
  onChange,
  selectedIndex,
}: {
  children: ReactNode;
  gapSize?: GapSize;
  maxWidth?: string;
  minWidth?: string;
  onChange?: (index: number) => void;
  selectedIndex?: number;
}) => {
  return (
    <TabGroup
      css={css`
        display: grid;
        grid-gap: ${gapSizeStyles[gapSize]};
        grid-template-columns: 100%;

        ${maxWidth ? `max-width: ${maxWidth};` : ""}
        ${minWidth ? `min-width: ${minWidth};` : ""}
      `}
      onChange={onChange && onChange}
      selectedIndex={selectedIndex}
    >
      {children}
    </TabGroup>
  );
};

Tabs.Header = TabHeader;
Tabs.HeaderList = TabList;
Tabs.Panels = TabPanels;
Tabs.Panel = TabPanel;
Tabs.Tab = Tab;
export default Tabs;
