import { borderStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  border?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
  children: ReactNode;
  gap?: string;
  gapBottom?: string;
  gapTop?: string;
}

const Stack: FunctionComponent<Props> = ({
  border,
  borderBottom,
  borderTop,
  children,
  gap,
  gapBottom,
  gapTop,
}) => {
  const halfgap = gap ? `calc(${gap}/2)` : null;

  return (
    <div
      css={css`
        border-bottom: ${borderBottom ? borderStandard : "none"};
        border-top: ${borderTop ? borderStandard : "none"};

        > * {
          border-bottom: ${border ? borderStandard : "none"};
          box-sizing: border-box;
          padding-bottom: ${halfgap || gapBottom || 0};
          padding-top: ${halfgap || gapTop || 0};
        }

        > *:last-of-type {
          border-bottom: none;
        }
      `}
    >
      {children}
    </div>
  );
};

export default Stack;
