import Grid from "../../../Grid";
import Text from "../../../Text";

export interface Props {
  list?: {
    items: string[];
    label: string;
  }[];
  title?: string;
}

const CardG = ({ list, title }: Props) => {
  return (
    <Grid gap={"1.5rem"}>
      <Text as={"h4"} size={"medium"} weight={"semibold"}>
        {title}
      </Text>
      {list?.map((listItem) => (
        <Grid
          gap={"1rem"}
          gridTemplateColumns={{ base: "1fr", tabletLandscape: "200px 1fr" }}
          key={listItem.label}
        >
          <Text as={"h5"} size={"small"} weight={"semibold"}>
            {listItem.label}
          </Text>
          <div>
            {listItem.items?.map((item) => (
              <Text
                as={"h5"}
                color={"base"}
                key={item}
                size={"small"}
                weight={"regular"}
              >
                {item}
              </Text>
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardG;
