import type { Person } from "@10xdev/cms/model/person";
import type { ResolvedCMSEntry } from "@10xdev/cms/types";
import type { OptionalToNullable } from "@10xdev/netlify-cms-graphql/utils/types";
import {
  borderRadiusMedium,
  fontSizeMedium,
  fontWeightMedium,
  layoutWidth800,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Text from "../Text";

interface Props {
  person: OptionalToNullable<Person> & ResolvedCMSEntry;
}

const PersonCard: FunctionComponent<Props> = ({ person }) => {
  const { avatar, foreName, lastName, position, slug } = person;
  const fullName = `${foreName} ${lastName}`;
  return (
    <li
      css={css`
        list-style: none;
        margin-right: 2rem;
        max-width: 100%;
        min-width: 200px;

        @media (min-width: ${layoutWidth800}) {
          :hover .Image {
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
          }
        }

        @media (min-width: ${layoutWidth800}) {
          margin-right: 0;
          max-width: 480px;
        }
      `}
    >
      <Anchor
        aria-label={`${fullName} exective profile`}
        color={"blue"}
        css={{
          fontSize: fontSizeMedium,
          fontWeight: fontWeightMedium,
          marginBottom: ".25rem",
        }}
        href={`/company/team/${slug}`}
      >
        {avatar ? (
          <div
            className={"Image"}
            css={css`
              border-radius: ${borderRadiusMedium};
              margin-bottom: 1rem;
              transition: box-shadow 0.3s ease;
            `}
          >
            <img
              alt={""}
              css={css`
                border-radius: 6px;
                display: block;
                max-width: 100%;
              `}
              src={avatar}
            />
          </div>
        ) : null}
        {fullName}
      </Anchor>

      {position ? (
        <Text
          as={"div"}
          css={css`
            margin-bottom: 1rem;
          `}
          responsive={true}
          size={"medium"}
        >
          {position}
        </Text>
      ) : null}
    </li>
  );
};

export default PersonCard;
