import { css } from "@emotion/react";

import Text from "../../Text";

interface Props {
  marker?: boolean;
}

const Blank = ({ marker = true }: Props) => {
  return (
    <Text
      as={"div"}
      css={css`
        padding-top: 6rem;
      `}
      size={"small"}
      weight={"regular"}
    >
      {marker ? "–" : ""}
    </Text>
  );
};

export default Blank;
