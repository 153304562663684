import type { TextColor, TextSize } from "@10xdev/types";
import {
  borderRadiusMedium,
  colorSteelLighter,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../Anchor";
import type { Props as ContainerProps } from "../Blocks/Section/Layout/Container";
import Container from "../Blocks/Section/Layout/Container";
import { buttonCss } from "../Button";
import type { StyleProps as ButtonProps } from "../Button/Button";
import Flex from "../Flex";
import Grid from "../Grid";
import Icon from "../Icon";
import type { IconSource } from "../Icon/types";
import Text from "../Text";

interface Props {
  backgroundColor?: string;
  columnWidths?: string[];
  container?: ContainerProps;
  ctas?: {
    buttonType: "primary" | "secondary";
    label: string;
    size: TextSize;
    target?: "_blank" | "_self";
    url: string;
  }[];
  eyebrow?: string;
  gap?: string;
  image: {
    position?: string;
    src: string;
    width?: string;
  };
  links?: {
    icon?: IconSource;
    label: string;
    url: string;
  }[];
  list?: {
    color: TextColor;
    content: string[];
    size: TextSize;
  };
  padding?: string;
  paragraphs?: {
    color: TextColor;
    content: string[];
    size: TextSize;
  };
  title?: {
    color: TextColor;
    content: string;
    size: TextSize;
  };
}

const ctaCss = `
  align-content: center;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
  width: clamp(150px, 100%, 240px);
  @media (min-width: ${mediaTabletLandscape}) {
    justify-self: center;
  }
`;

const ImageCtaCard = ({
  backgroundColor = "transparent",
  columnWidths = ["4fr", "1fr"],
  container,
  ctas,
  eyebrow,
  gap = "1.5rem 2.5rem",
  image,
  links,
  list,
  padding = "2rem 2.5rem",
  paragraphs,
  title,
}: Props) => {
  return (
    <Container {...container}>
      <Grid
        css={css`
          background: ${backgroundColor};
          border: solid 1px ${colorSteelLighter};
          border-radius: ${borderRadiusMedium};
          overflow: hidden;
          width: 100%;
        `}
        gridTemplateColumns={{
          base: "1fr",
          tabletPortrait: `${image.width || "25%"} 1fr`,
        }}
      >
        <div
          css={css`
            background-image: url(${image.src});
            background-position: ${image.position || "center center"};
            background-size: cover;
            min-height: 33vw;
            @media (min-width: ${mediaTabletLandscape}) {
              min-height: auto;
            }
          `}
        />
        <Grid
          css={css`
            box-sizing: border-box;
            padding: clamp(1rem, 4vw, 2rem);
            @media (min-width: ${mediaTabletLandscape}) {
              padding: ${padding};
            }
          `}
          gap={gap}
          gridTemplateColumns={{
            base: "1fr",
            tabletLandscape: `${columnWidths[0] || "1fr"} ${
              columnWidths[1] || "1fr"
            }`,
          }}
        >
          <Grid gap={"1rem"}>
            {eyebrow ? (
              <Text
                as={"p"}
                color={"blue"}
                responsive={true}
                size={"medium"}
                weight={"semibold"}
              >
                {eyebrow}
              </Text>
            ) : null}

            {title?.content ? (
              <Text
                as={"h3"}
                color={title.color}
                responsive={true}
                size={title.size}
                weight={"semibold"}
              >
                {title.content}
              </Text>
            ) : null}

            {paragraphs?.content ? (
              <Grid gap={"1rem"}>
                {paragraphs.content.map((paragraph) => (
                  <Text
                    as={"p"}
                    color={paragraphs.color || "midgray"}
                    key={paragraph}
                    responsive={true}
                    size={paragraphs.size || "medium"}
                    weight={"regular"}
                  >
                    {paragraph}
                  </Text>
                ))}
              </Grid>
            ) : null}

            {list?.content?.length ? (
              <ul
                css={css`
                  margin: 0;
                  padding: 0 0 0 2rem;
                `}
              >
                {list.content.map((item) => (
                  <Text
                    as={"li"}
                    color={"midgray"}
                    key={item}
                    responsive={true}
                    size={"medium"}
                  >
                    {item}
                  </Text>
                ))}
              </ul>
            ) : null}

            {links?.length ? (
              <ul
                css={css`
                  margin: 0;
                  padding: 0;
                `}
              >
                {links.map((link) => (
                  <Anchor href={link.url} key={link.url}>
                    <Text
                      as={"span"}
                      color={"inherit"}
                      css={css`
                        margin-right: 0.75rem;
                      `}
                      responsive={true}
                      size={"small"}
                      weight={"medium"}
                    >
                      {link.label}
                    </Text>
                    {link.icon ? (
                      <Icon size={"11px"} source={link.icon} yPos={"1px"} />
                    ) : null}
                  </Anchor>
                ))}
              </ul>
            ) : null}
          </Grid>

          {ctas?.length ? (
            <Flex
              flexDirection={"column"}
              gap={"1rem"}
              justifyContent={"center"}
            >
              {ctas.map((cta) => {
                cta.buttonType = cta.buttonType || "primary";
                const types: Record<"primary" | "secondary", ButtonProps> = {
                  primary: {},
                  secondary: {
                    background: "white",
                    border: true,
                    color: "blue",
                    shadow: "none",
                  },
                };
                return (
                  <Anchor
                    css={[buttonCss(types[cta.buttonType]), ctaCss]}
                    href={cta.url}
                    key={cta.url}
                  >
                    <Text
                      as={"span"}
                      color={"inherit"}
                      responsive={true}
                      size={"small"}
                      weight={"semibold"}
                    >
                      {cta.label}
                    </Text>
                  </Anchor>
                );
              })}
            </Flex>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImageCtaCard;
