export const SERVICE_PROVIDER_FACETS = [
  {
    attribute: "products.name",
    label: "Services",
  },
  {
    attribute: "regions.label",
    label: "Regions",
  },
  {
    attribute: "serviceTypes",
    label: "Organization Type",
  },
];
