enum FACETS {
  categoryLabel = "category.label",
  country = "country",
  productsName = "products.name",
  regionLabel = "regions.label",
  researchAreas = "researchAreas",
  timestamp = "timestamp",
}

export default FACETS;
