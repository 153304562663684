import type { AlgoliaDocument } from "@10xdev/algolia-utils/documents";
import {
  borderStandard,
  fontSizeXsmall,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import MDXComponents from "../Blocks/MDX/MDXComponents";
import Card from "../Card";
import Text from "../Text";
import { THUMBNAIL_FLAGS } from "./constants";

interface Props {
  document: Pick<
    AlgoliaDocument,
    "mdx" | "slug" | "thumbnailUrl" | "title" | "type"
  >;
}

const contentCss = css`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-height: 208px;
  padding: 24px 56px;
  text-decoration: none;

  @media (max-width: ${mediaTabletLandscape}) {
    flex-direction: column;
    align-items: center;
  }
`;

const thumbnailCss = css`
  border: ${borderStandard};
  margin: 0 64px 0 0;
  max-height: 154px;
  min-width: 120px;
  pointer-events: none;
  width: 120px;

  @media (max-width: ${mediaTabletLandscape}) {
    margin: 0 0 32px 0;
  }
`;

const textCss = css`
  @media (max-width: ${mediaTabletLandscape}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & {
      text-align: center;
    }
  }
`;

/**
 * A card that displays a summary view of a document.
 */
const DocumentCard: FunctionComponent<Props> = ({ document }) => {
  const { mdx, slug, thumbnailUrl, title, type } = document;

  // Add Cloudinary transformation flags
  const getThumbnailUrl = (url: string): string => {
    return url.replace("upload", `upload/${THUMBNAIL_FLAGS}`);
  };

  return (
    <>
      <Anchor
        css={css`
          display: block;
          margin: 0;
        `}
        href={{ pathname: `/library/${slug}` }}
      >
        <Card elevate={true} hoverable={true} outline={true}>
          <div css={contentCss} role={"button"} tabIndex={-1}>
            <img
              alt={title}
              css={thumbnailCss}
              src={
                thumbnailUrl
                  ? getThumbnailUrl(thumbnailUrl)
                  : "https://cdn.10xgenomics.com/image/upload/v1726786475/library/placeholder-thumbnail.png"
              }
            />
            <div css={textCss}>
              <Text as={"h1"} color={"blue"} size={"xsmall"} weight={"medium"}>
                {type.label}
              </Text>

              <Text
                as={"h2"}
                css={css`
                  margin-bottom: 0.5rem;
                `}
                size={"medium"}
                weight={"semibold"}
              >
                {title}
              </Text>

              <div
                css={css`
                  & p {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: ${fontSizeXsmall};
                  }
                `}
              >
                {mdx.compiledSource ? (
                  <MDXRemote {...mdx} components={MDXComponents} />
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      </Anchor>
    </>
  );
};

export default DocumentCard;
