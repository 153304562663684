export enum Facets {
  AdditionalApplicationSlug = "additionalApplications.slug",
  CGNumber = "attachments.cgNumber",
  CompatibilityChemistryVersion = "compatibility.chemistryVersion",
  Description = "description",
  DocType = "docType",
  InstrumentSlug = "instruments.slug",
  IsAssayDocument = "isAssayDocument",
  IsCompatibilityTable = "isCompatibilityTable",
  ProductSlug = "steps.productSlug",
  Slug = "slug",
  StepSlug = "steps.slug",
  Title = "title",
  WorkflowSlug = "steps.workflowSlug",
}
