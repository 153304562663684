import { borderStandard, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Text from "../../Text";
import type { AssayComparison, AssayGroup, Assays } from "../types";
import { getAssayGroup } from "../utils";
import Segment from "./Segment";

interface Props {
  active: AssayGroup;
  assays: Assays;
  segments: { id: AssayGroup; label: string }[];
  setActive: (slug: AssayGroup) => void;
  setComparisons: (assays: AssayComparison) => void;
}

const SegmentedControl = ({
  active,
  assays,
  segments,
  setActive,
  setComparisons,
}: Props) => {
  const handleSegmentClick = (groupSlug: AssayGroup) => {
    setActive(groupSlug);
    setComparisons(getAssayGroup(groupSlug, assays));
  };

  return (
    <div
      css={css`
        padding: 0 1rem;
      `}
    >
      <div
        css={css`
          background: ${colorWhite};
          border-radius: 3rem;
          box-shadow: 0px 1.718px 4.294px 0px rgba(0, 0, 0, 0.06);
          display: flex;
          gap: 0.5rem;
          justify-content: space-between;
          margin: 0 auto 3.5rem;
          max-width: 1200px;
          outline: ${borderStandard};
          transform: translateY(1px);
          @media (max-width: 900px) {
            gap: 0.25rem;
            overflow-x: scroll;
          }
        `}
      >
        {segments.map((tab) => {
          return (
            <Segment
              active={active === tab.id}
              css={css`
                height: 46px;
                padding: 0rem 1.5rem;
              `}
              id={tab.id}
              key={tab.id}
              onClick={() => handleSegmentClick(tab.id)}
            >
              <Text
                as={"span"}
                color={"inherit"}
                css={css`
                  line-height: 1;
                `}
                responsive={true}
                size={"small"}
                weight={"medium"}
              >
                {tab.label}
              </Text>
            </Segment>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentedControl;
