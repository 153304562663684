import type { FunctionComponent } from "react";
import type { SearchState } from "react-instantsearch-core";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  link: {
    label: string;
    url: string;
  };
  searchState: SearchState;
}

const PageLink: FunctionComponent<Props> = ({ link, searchState }) => {
  return (
    <Anchor
      href={{
        pathname: link.url,

        query: { query: searchState?.query },
      }}
    >
      <Text as={"p"} color={"inherit"} size={"small"} weight={"medium"}>
        {link.label}
        <Icon size={"9px"} source={"nav-right"} xPos={"12px"} />
      </Text>
    </Anchor>
  );
};

export default PageLink;
