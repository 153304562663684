import { css } from "@emotion/react";
import { TabList as HeadlessTabList } from "@headlessui/react";
import type { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const TabList = ({ children }: Props) => {
  return (
    <HeadlessTabList
      css={css`
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 2.5rem;
        justify-content: start;
        justify-items: start;
        margin: 0;
        overflow-y: auto;
        padding: 0;
      `}
    >
      {children}
    </HeadlessTabList>
  );
};

export default TabList;
