import {
  colorSteelLightest,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Text from "../Text";

interface Props {
  description?: string;
  tag?: string;
  title?: string;
}

const RoadMapCard: FunctionComponent<Props> = ({ description, tag, title }) => {
  return (
    <li
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
      `}
    >
      {title ? (
        <Heading as={"h4"} size={"medium"}>
          {title}
        </Heading>
      ) : null}

      {description ? (
        <Text
          as={"div"}
          color={"midgray"}
          css={css`
            margin-bottom: 1.5rem;
          `}
          size={"small"}
        >
          {description}
        </Text>
      ) : null}

      {tag ? (
        <div
          css={css`
            align-self: flex-start;
            background: ${colorSteelLightest};
            border-radius: 2rem;
            margin-top: auto;
            padding: 0.5rem 1rem;

            @media (max-width: ${mediaTabletLandscape}) {
              margin-bottom: 3rem;
            }
          `}
        >
          <Text as={"div"} color={"midgray"} size={"xsmall"}>
            {tag}
          </Text>
        </div>
      ) : null}
    </li>
  );
};

export default RoadMapCard;
