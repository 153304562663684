import { useEffect, useState } from "react";
import type { SearchState } from "react-instantsearch-core";

const useCurrentSearchPresent = (
  searchState: SearchState,
): { searchPresent: boolean } => {
  const [searchPresent, setSearchPresent] = useState(false);
  useEffect(() => {
    const productsMenuSelected = Boolean(
      searchState.menu ? searchState.menu["products.name"] : "",
    );
    const areasOfInterestSelected = Boolean(searchState.menu?.researchAreas);
    setSearchPresent(
      searchState.query !== "" ||
        productsMenuSelected ||
        areasOfInterestSelected,
    );
  }, [searchState]);

  return {
    searchPresent,
  };
};

export default useCurrentSearchPresent;
