import type { Hit } from "react-instantsearch-core";

import type { AlgoliaRecord, HitComponent } from "../types";

interface Props {
  hitComponent: HitComponent;
  hits: Hit<AlgoliaRecord>[];
}

function Hits({ hits, hitComponent }: Props) {
  const Hit = hitComponent;
  return (
    <ul>
      {hits.map((hit) => {
        return (
          <li key={hit.objectID}>
            <Hit hit={hit} />
          </li>
        );
      })}
    </ul>
  );
}

export default Hits;
