import { colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { getUnixTime, startOfDay } from "date-fns";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import { connectRange } from "react-instantsearch-dom";

import ToggleWithLabel from "../ToggleWithLabel";

type RangeRefinement = {
  max?: number;
  min?: number;
};

interface Props {
  /** The name of the attribute in the record. */
  attribute?: string;

  // /** The currently applied refinement or the minimum/maximum value. */
  currentRefinement?: RangeRefinement;

  /** The default state of the widget containing the min and/or the max of the range. */
  defaultRefinement?: RangeRefinement;

  /** Selects a range. */
  refine: (refinement?: RangeRefinement) => void;
}

/**
 * A switch/toggle that, when switched on,
 * instructs Algolia to include past events.
 */
const PastEventsToggle: FunctionComponent<Props> = ({ refine }) => {
  const query = useRouter()?.query || {};

  /**
   * The URL query param pertaining to this toggle
   * has three possible states on startup, due to
   * the way that Algolia keeps (or doesn't keep)
   * the URL in sync. This function figures out
   * what the initial state of the toggle should
   * be, based on the condition of the URL.
   */
  const getInitialState = (query: Record<string, any>) => {
    const filterKey = "range[timestamp][min]";

    // ?page=1&range%5Btimestamp%5D%5Bmin%5D=1603954800

    /**
     * The query does not have the key for the range minimum,
     * which means we're probably looking at `/events` with no
     * query string at all. In this case, exclude past events.
     * The default is to exclude past events on fresh init.
     */

    if (!Object.prototype.hasOwnProperty.call(query, filterKey)) {
      return false;
    }

    /**
     * The query has the key for the filter, and the filter
     * has a value other than zero. This indicates that we
     * should exclude past events.
     */
    if (
      Object.prototype.hasOwnProperty.call(query, filterKey) &&
      query[filterKey]?.toString() !== "0"
    ) {
      return false;
    }

    /**
     * Without specifying other URL conditions explicitly,
     * we can assume that in all other cases we should
     * include past events.
     */
    return true;
  };

  const [includePastEvents, setIncludePastEvents] = useState(
    getInitialState(query),
  );
  const handleClick = () => setIncludePastEvents(!includePastEvents);

  const [threshold] = useState(getUnixTime(startOfDay(new Date())));
  /**
   * Refine when the toggle transition is complete,
   * so as not to choke the layout with Algolia activity.
   */
  const handleTransitionEnd = () => {
    refine(includePastEvents ? {} : { min: threshold });
    // Imperatively scroll to top, because scroll position
    // can be strange when changing the number of items
    // in an infinite scroll list.
    // window.scrollTo(0, 0);
  };

  const refineRef = useRef<((refinement?: RangeRefinement) => void) | null>(
    refine,
  );

  useEffect(() => {
    refineRef.current?.(includePastEvents ? {} : { min: threshold });
  }, [includePastEvents, threshold]);

  useEffect(() => {
    refineRef.current = refine;
  }, [refine]);

  return (
    <ToggleWithLabel
      checked={includePastEvents}
      css={css`
        border-top: 1px solid ${colorSteelLighter};
        padding-top: 16px;
      `}
      onClick={handleClick}
      onTransitionEnd={handleTransitionEnd}
    >
      {"Include past events"}
    </ToggleWithLabel>
  );
};

export default connectRange(PastEventsToggle);
