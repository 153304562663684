export const SPACINGS = {
  ["none"]: 0,
  ["small"]: "3rem",
  ["medium"]: "4rem",
  ["large"]: "6rem",
};

export const EASE = "cubic-bezier(.46, .04, .7, .33)";

export const PRODUCT_NAME_ORDER = Object.freeze([
  "Single Cell Gene Expression",
  "Single Cell Immune Profiling",
  "Single Cell Multiome ATAC + Gene Expression",
  "Single Cell ATAC",
  "Single Cell CNV",
  "Spatial Gene Expression",
  "Targeted Gene Expression",
  "Genome Sequencing",
  "De Novo Assembly",
]);

export const MARKETO_FORM_CHROMIUM_IMAGE_URL =
  "https://cdn.10xgenomics.com/image/upload/f_auto,q_auto/v1589931915/products/targeted-gene-expression-all.png";
export const MARKETO_FORM_XENIUM_IMAGE_URL =
  "https://cdn.10xgenomics.com/image/upload/f_auto,q_auto/v1664555795/xenium-datasets/analyzer_outside.png";
