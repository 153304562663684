import { css } from "@emotion/react";
import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-core";

import ActionButton from "./Filter/ActionButton";

interface ClearSelectionProps extends CurrentRefinementsProvided {
  attribute: string;
  onClearSelection?: () => void;
}
const ConnectedClearSelection = ({
  attribute,
  items,
  onClearSelection,
  refine,
}: ClearSelectionProps) => {
  const selected = items.find((item) => item.attribute === attribute);
  return selected?.value ? (
    <ActionButton
      css={css`
        justify-self: start;
      `}
      onClick={() => {
        if (selected) {
          refine(selected.value);
        }
        onClearSelection?.();
      }}
      text={"Clear selection"}
    />
  ) : null;
};
export default connectCurrentRefinements(ConnectedClearSelection);
