import { borderRadiusMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  container?: ContainerProps;
  src: string;
}

const Gif = ({ container, src }: Props) => {
  return (
    <Container {...container}>
      <video
        autoPlay
        css={css`
          border-radius: ${borderRadiusMedium};
          height: auto;
          width: 100%;
        `}
        loop
        muted
        playsInline
      >
        <source src={src} type={"video/mp4"} />
      </video>
    </Container>
  );
};

export default Gif;
