import {
  borderRadiusMedium,
  colorBlueLighter,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import type { IconSource } from "../../Icon/types";
import BatchDownload from "../../SoftwareSupport/SupportSoftwareDownload/BatchDownload";
import Summary from "../../SoftwareSupport/SupportSoftwareDownload/Summary";
import Text from "../../Text";
import { formatFileSize } from "../utils";
import DownloadButton from "./DownloadButton";
import type { DownloadLink } from "./types";

interface Props {
  className?: string;
  downloadLinks: DownloadLink[];
  eula?: boolean;
  softwareSlug: string;
}

const DownloadLinkList = ({
  className,
  eula = true,
  downloadLinks,
  softwareSlug,
}: Props) => {
  return (
    <>
      <ul
        className={className}
        css={css`
          display: flex;
          flex-direction: column;
          gap: 2rem;
          list-style: none;
          margin: 0 0 0.5rem;
          padding: 0;

          @media (max-width: ${mediaPhoneOnly}) {
            display: none;
          }
        `}
      >
        {downloadLinks.map(
          ({
            batch,
            filename,
            fileSizeKB,
            icon,
            md5Sum,
            subtitle,
            summary,
            title,
            url,
          }) => {
            return (
              <li
                css={css`
                  display: grid;
                  gap: 1.5rem;
                `}
                key={title}
              >
                {subtitle ? (
                  <Text as={"p"} size={"large"} weight={"semibold"}>
                    {subtitle}
                  </Text>
                ) : null}

                {summary?.length ? <Summary summary={summary} /> : null}

                <div
                  css={css`
                    display: flex;
                    gap: 1.5rem;

                    @media (max-width: ${mediaTabletLandscape}) {
                      flex-direction: column;
                      gap: 1rem;
                    }
                  `}
                >
                  <DownloadButton
                    css={css`
                      min-width: 256px;
                      padding: 0 1rem;
                      width: fit-content;
                    `}
                    eula={eula}
                    icon={icon as IconSource}
                    softwareSlug={softwareSlug}
                    title={title}
                    url={url}
                  />
                  <div
                    css={css`
                      display: grid;
                      gap: 0.5rem;
                    `}
                  >
                    <Text as={"div"} color={"steelDarker"} size={"small"}>
                      {formatFileSize(fileSizeKB)}
                    </Text>
                    <Text as={"div"} color={"steelDarker"} size={"small"}>
                      {`md5sum: ${md5Sum}`}
                    </Text>
                  </div>
                </div>

                {batch ? <BatchDownload filename={filename} url={url} /> : null}
              </li>
            );
          },
        )}
      </ul>
      <div
        css={css`
          display: none;
          background: ${colorBlueLighter};
          border-radius: ${borderRadiusMedium};
          box-sizing: border-box;
          padding: 1.5rem;
          width: 100%;

          @media (max-width: ${mediaPhoneOnly}) {
            display: block;
          }
        `}
      >
        <Text as={"p"} color={"steelDarkest"} size={"medium"} weight={"medium"}>
          {"Download is unavailable on mobile devices."}
        </Text>
      </div>
    </>
  );
};

export default DownloadLinkList;
