import { spacing16 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { RefinementListProvided } from "react-instantsearch-core";
import ConnectedClearAllSelections from "../AlgoliaConnected/ConnectedClearAllSelections";
import ConnectedMultiSelectMenu from "../AlgoliaConnected/ConnectedMultiSelectMenu";
import Filter, { FilterContext } from "../AlgoliaConnected/Filter";
import {
  FilterItemValue,
  MenuExpandedState,
  MultiSelectFilterProp,
  OnSelectCallback,
} from "../AlgoliaConnected/Filter/types";
import { HEADER_HEIGHT } from "./constants";
import {
  DISPLAYED_CATEGORY_FILTERS,
  DISPLAYED_LANGUAGE_FILTERS,
  DISPLAYED_PRODUCT_FILTERS,
  videosFilterGroups,
} from "./constants";
import Flex from "../Flex";
import { useRouter } from "next/router";

const LOCALE_MAP: Record<string, string> = {
  cn: "中文",
  jp: "日本語",
};

const renderFilterItem = (
  item: MultiSelectFilterProp,
  menuExpandedState: MenuExpandedState,
  onClearSelection: (id: string) => () => void,
  onToggleMenu: (
    item: MultiSelectFilterProp,
    setMenuExpandedState: Dispatch<SetStateAction<MenuExpandedState>>,
  ) => (expanded: boolean) => void,
  onFilterSelect: OnSelectCallback<FilterItemValue>,
  setMenuExpandedState: Dispatch<SetStateAction<MenuExpandedState>>,
  locale: string = "en",
) => {
  const defaultRefinement: string[] = [];
  if (item.id === "language") {
    defaultRefinement.push("English");
    defaultRefinement.push(LOCALE_MAP[locale]);
  }
  return (
    <ConnectedMultiSelectMenu
      key={item.id}
      attribute={item.id}
      defaultRefinement={defaultRefinement}
      expanded={menuExpandedState[item.id]}
      id={item.id}
      label={item.label}
      limit={12}
      onClearSelection={onClearSelection(item.id)}
      onExpand={onToggleMenu(item, setMenuExpandedState)}
      onSelect={onFilterSelect}
      operator={"or"}
      transformItems={(items: RefinementListProvided["items"]) => {
        let filterList = DISPLAYED_LANGUAGE_FILTERS;
        if (item.id === "category.label") {
          filterList = DISPLAYED_CATEGORY_FILTERS;
        } else if (item.id === "products.name") {
          filterList = DISPLAYED_PRODUCT_FILTERS;
        }

        return items
          .filter((i) => filterList.includes(i.label))
          .sort(
            (a, b) => filterList.indexOf(a.label) - filterList.indexOf(b.label),
          );
      }}
    />
  );
};

const VideosFilter = ({ className }: { className?: string }) => {
  const router = useRouter();
  const [topPos, setTopPos] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleFilterChange = useCallback(() => {
    if (window.scrollY > HEADER_HEIGHT) {
      window.scrollTo(0, HEADER_HEIGHT);
    }
  }, []);
  const handleWindowResize = useCallback(() => {
    if (contentRef.current) {
      setTopPos(contentRef.current.getBoundingClientRect().top);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleWindowResize();
    }, 100);
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("scroll", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, [handleWindowResize]);

  const onToggleMenu = useCallback(
    (
        item: MultiSelectFilterProp,
        setMenuExpandedState: Dispatch<SetStateAction<MenuExpandedState>>,
      ) =>
      (expanded: boolean) => {
        setMenuExpandedState((prev) => {
          return {
            ...prev,
            [item.id]: expanded,
          };
        });
      },
    [],
  );

  const defaultExpandedMenus = Object.fromEntries(
    videosFilterGroups.map((filter) => [filter.id, true]),
  );

  return (
    <Filter
      className={className}
      clearAllButton={<ConnectedClearAllSelections />}
      defaultExpandedMenus={defaultExpandedMenus}
      heading={"Filter videos"}
      onChange={handleFilterChange}
    >
      <FilterContext.Consumer>
        {({
          menuExpandedState,
          onClearSelection,
          onFilterSelect,
          setMenuExpandedState,
        }) => {
          return (
            <div
              css={css`
                box-sizing: border-box;
                max-height: calc(100vh - ${topPos}px);
                overflow-y: auto;
                overscroll-behavior: contain;
                padding: 0 ${spacing16} ${spacing16};
              `}
              ref={contentRef}
            >
              <Flex flexDirection={"column"}>
                {videosFilterGroups.map((item) => {
                  return renderFilterItem(
                    item,
                    menuExpandedState,
                    onClearSelection,
                    onToggleMenu,
                    onFilterSelect,
                    setMenuExpandedState,
                    router.locale,
                  );
                })}
              </Flex>
            </div>
          );
        }}
      </FilterContext.Consumer>
    </Filter>
  );
};

export default VideosFilter;
