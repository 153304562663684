import { colorBlueLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";

import Select from "../Select";
import Text from "../Text";

export function CountrySelect({
  items,
  currentRefinement,
  refine,
}: MenuProvided) {
  const options = [
    {
      label: "All Locations",
      value: "",
    },
    ...items.map((item) => {
      return {
        label: item.label,
        value: item.isRefined ? currentRefinement : item.value,
      };
    }),
  ];

  return (
    <div
      css={css`
        background-color: ${colorBlueLightest};
        border-radius: 6px;
        box-sizing: border-box;
        max-width: 660px;
        padding-bottom: 24px;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 24px;
      `}
    >
      <Text
        as={"h6"}
        color={"base"}
        css={css`
          margin-bottom: 8px;
        `}
        size={"small"}
        weight={"semibold"}
      >
        {"Select from all of our global distributors locations"}
      </Text>
      <Select
        onChange={(event) => refine(event.currentTarget.value)}
        options={options}
        value={currentRefinement || ""}
      />
    </div>
  );
}

export default connectMenu(CountrySelect);
