import { css } from "@emotion/react";
import Anchor from "../../../Anchor";
import Flex from "../../../Flex";
import Grid from "../../../Grid";

import Image from "../../../Image";
import Text from "../../../Text";
import type { SectionImage } from "../types";
import { TextSize } from "@10xdev/types";
import Icon from "../../../Icon";
import { IconSource } from "../../../Icon/types";
import { colorSteelLighter, mediaTabletLandscape } from "@10xdev/design-tokens";

export interface Props {
  id?: string;
  sections: {
    title?: string;
    titleSize?: TextSize;
    description?: string;
  }[];
  linkGroups?: {
    title: string;
    links: {
      label: string;
      href: string;
      icon?: IconSource;
    }[];
  }[];
  image?: SectionImage;
}

const CardI = ({ id, image, sections, linkGroups }: Props) => {
  return (
    <Grid
      css={css`
        border-bottom: 1px solid ${colorSteelLighter};
        padding: 1.5rem 0;
      `}
      alignItems={{ base: "flex-start", tabletLandscape: "center" }}
      gridTemplateColumns={{ base: "1fr", tabletLandscape: "1fr 420px" }}
      gap={{ base: "1.5rem", tabletLandscape: "4rem" }}
      id={id}
    >
      <Flex
        alignItems={{ base: "flex-start", tabletLandscape: "center" }}
        flexDirection={{ base: "column", tabletLandscape: "row" }}
        gap={{ base: "1.5rem", tabletLandscape: "2rem" }}
      >
        {image?.src && (
          <Image
            alt={image.alt}
            css={css`
              flex: 0 0 auto;
            `}
            src={image.src}
            width="72px"
          />
        )}
        <Flex flexDirection={"column"} gap={"1rem"}>
          {sections.map(
            ({ title, titleSize = "large", description }, index) => {
              return (
                <div key={index}>
                  {title && (
                    <Text
                      as={"h4"}
                      css={css`
                        margin-bottom: ${titleSize === "large"
                          ? "0.5rem"
                          : "0.25rem"};
                      `}
                      size={titleSize}
                      weight={"semibold"}
                    >
                      {title}
                    </Text>
                  )}
                  {description && (
                    <Text as={"p"} size={"small"}>
                      {description}
                    </Text>
                  )}
                </div>
              );
            },
          )}
        </Flex>
      </Flex>
      {linkGroups && (
        <Flex
          flexDirection={{ base: "column", tabletLandscape: "row" }}
          gap={"1rem"}
        >
          {linkGroups.map(({ title, links }, index) => {
            return (
              <Flex
                css={css`
                  flex: 1;

                  @media (min-width: ${mediaTabletLandscape}) {
                    flex: ${title === "Products" ? "0 1 140px" : "1"};
                  }
                `}
                flexDirection="column"
                gap="0.5rem"
                key={index}
              >
                <Text as="span" size="small" weight="semibold">
                  {title}
                </Text>
                <Flex flexDirection="column" gap="0.25rem">
                  {links.map(({ label, href, icon }, index) => {
                    return (
                      <Anchor href={href} key={index} target="_blank">
                        <Flex gap="0.5rem">
                          {icon && (
                            <Icon
                              color="transparent"
                              css={css`
                                flex: 0 0 auto;
                              `}
                              size="1.5rem"
                              source={icon}
                            />
                          )}
                          <Text
                            as="span"
                            color="inherit"
                            size="small"
                            weight="medium"
                          >
                            {label}
                          </Text>
                        </Flex>
                      </Anchor>
                    );
                  })}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Grid>
  );
};

export default CardI;
