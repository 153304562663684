import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { connectPagination } from "react-instantsearch-dom";

import Button from "../../Button";
import Icon from "../../Icon";
import Stats from "./Stats";

interface Props {
  currentRefinement: number;
  hitsPerPage: number;
  includeStats: boolean;
  nbPages: number;
  refine: (page: number) => void;
}

const Pagination: FunctionComponent<Props> = ({
  currentRefinement,
  hitsPerPage,
  nbPages,
  refine,
}) => {
  return (
    <>
      <Stats currentPage={currentRefinement} hitsPerPage={hitsPerPage} />
      <ul
        css={css`
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        <li>
          <Button
            background={"white"}
            border={true}
            color={"blue"}
            css={{ height: "2rem", marginRight: ".5rem", width: "2rem" }}
            disabled={currentRefinement <= 1}
            onClick={(event) => {
              event.preventDefault();
              refine(currentRefinement - 1);
            }}
          >
            <Icon size={"9px"} source={"nav-left"} />
          </Button>
        </li>
        <li>
          <Button
            background={"white"}
            border={true}
            color={"blue"}
            css={{
              height: "2rem",
              width: "2rem",
            }}
            disabled={currentRefinement >= nbPages}
            onClick={(event) => {
              event.preventDefault();
              refine(currentRefinement + 1);
            }}
          >
            <Icon size={"9px"} source={"nav-right"} />
          </Button>
        </li>
      </ul>
    </>
  );
};

export default connectPagination(Pagination);
