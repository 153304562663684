import {
  borderRadiusMedium,
  borderStandard,
  colorBlueLighter,
  colorBlueLightest,
  colorBlueMedium,
  colorSteelDarker,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Text from "../Text";

interface Props {
  active: string;
  description: string;
  options: {
    label: string;
    slug: string;
    url: string;
  }[];
}

const primaryButton = css`
  background: ${colorBlueMedium};
`;

const secondaryButton = css`
  background: ${colorWhite};
  border: ${borderStandard};
  color: ${colorSteelDarker};
  :hover {
    background: ${colorBlueLighter};
  }
`;

const SoftwareToggle: FunctionComponent<Props> = ({
  active,
  description,
  options,
}) => {
  return (
    <div
      css={css`
        border-radius: ${borderRadiusMedium};
        background: ${colorBlueLightest};
        padding: 1.5rem 2rem;
      `}
    >
      <Text
        as={"p"}
        css={css`
          margin-bottom: 1rem;
        `}
        size={"medium"}
        weight={"semibold"}
      >
        {description}
      </Text>
      <div
        css={css`
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
        `}
      >
        {options.map((option) => {
          return (
            <Anchor href={option.url} key={option.url}>
              <Text
                as={"div"}
                color={"white"}
                css={css`
                  border-radius: 100px;
                  padding: 0.5rem 1rem;
                  white-space: nowrap;
                  ${active === option.slug ? primaryButton : secondaryButton}
                `}
                size={"small"}
                weight={"medium"}
              >
                {option.label}
              </Text>
            </Anchor>
          );
        })}
      </div>
    </div>
  );
};

export default SoftwareToggle;
