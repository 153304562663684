import type { AssayGroup, AssaySlug } from "./types";

export const ASSAY_GROUPS: Record<AssayGroup, AssaySlug[]> = {
  ["all"]: [null, null, null],
  ["epigenetic_applications"]: [
    "chromium-single-cell-atac",
    "chromium-single-cell-multiome",
    null,
  ],
  ["protein-applications"]: [
    "chromium-single-cell-immune-profiling",
    "chromium-single-cell-gene-expression-flex",
    "visium-spatial-gene-expression-ffpe",
  ],
  ["single-cell-gene-expression"]: [
    "chromium-single-cell-gene-expression-flex",
    "chromium-single-cell-gene-expression",
    "chromium-single-cell-immune-profiling",
  ],
  ["spatial-assays"]: [
    "visium-hd-spatial-gene-expression",
    "visium-spatial-gene-expression-ffpe",
    "xenium-in-situ-gene-expression",
  ],
};

export const ASSAY_CATEGORIES: { id: AssayGroup; label: string }[] = [
  {
    id: "single-cell-gene-expression",
    label: "Single Cell Assays",
  },
  { id: "spatial-assays", label: "Spatial Assays" },
  { id: "epigenetic_applications", label: "Epigenetic Applications" },
  { id: "protein-applications", label: "Protein Applications" },
  { id: "all", label: "All Assays" },
];

export const ASSAY_SLUGS = [
  "chromium-single-cell-gene-expression",
  "chromium-single-cell-gene-expression-flex",
  "chromium-single-cell-immune-profiling",
  "chromium-single-cell-atac",
  "chromium-single-cell-multiome",
  "visium-spatial-gene-expression",
  "visium-spatial-gene-expression-ffpe",
  "xenium-in-situ-gene-expression",
];

export const COMPARE_ROWS = [
  {
    component: "feature",
    heading: "Primary Capability",
    id: "baseAnalysis",
  },
];
