export const BLOG_FACETS = [
  {
    attribute: "products.name",
    label: "All Products",
  },
  {
    attribute: "researchAreas",
    label: "All Areas of Interest",
  },
];
