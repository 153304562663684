import Icon from "../../Icon";
import type { IconColor, IconSource } from "../../Icon/types";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

export interface Props {
  color?: IconColor;
  container?: ContainerProps;
  size?: string;
  source: IconSource;
  xPos?: string;
  yPos?: string;
}

const SectionIcon = ({
  color = "base",
  container,
  size = "12px",
  source,
  xPos,
  yPos,
}: Props) => {
  return (
    <Container {...container}>
      <Icon color={color} size={size} source={source} xPos={xPos} yPos={yPos} />
    </Container>
  );
};

export default SectionIcon;
