import {
  borderRadiusMedium,
  borderStandard,
  boxShadowLight,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import WistiaVideo from "../WistiaVideo";

interface Props {
  videos: Array<{
    description: string;
    title: string;
    wistiaID: string;
  }>;
}

const VideoCarousel: FunctionComponent<Props> = ({ videos }) => {
  return (
    <ul
      css={css`
        display: flex;
        gap: 2rem;
        list-style: none;
        margin: 0;
        overflow: scroll visible;
        padding: 0 0 8px 0;
      `}
    >
      {videos.map((video) => {
        const { description, title, wistiaID } = video;

        return (
          <li
            css={css`
              border: ${borderStandard};
              border-radius: ${borderRadiusMedium};
              box-shadow: ${boxShadowLight};
              box-sizing: border-box;
              min-width: 360px;
              overflow: hidden;
              width: 360px;

              @media (max-width: ${mediaTabletLandscape}) {
                min-width: 280px;
                width: 280px;
              }
            `}
            key={wistiaID}
          >
            <WistiaVideo wistiaID={wistiaID} />
            <div
              className={"Labels"}
              css={css`
                padding: 1rem 1.5rem;
              `}
            >
              <Text as={"div"} size={"medium"} weight={"semibold"}>
                {title}
              </Text>
              <Text as={"div"} color={"gray"} size={"small"}>
                {description}
              </Text>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default VideoCarousel;
