import FACETS from "@10xdev/algolia-utils/FACETS";

export const DOCUMENT_FACETS = [
  {
    attribute: FACETS.Type,
    label: "Document Type",
  },
  {
    attribute: FACETS.AreasOfInterest,
    label: "Area of Interest",
  },
  {
    attribute: FACETS.Products,
    label: "Product",
  },
  {
    attribute: FACETS.Applications,
    label: "Application",
  },
  {
    attribute: FACETS.Instruments,
    label: "Instrument",
  },
  {
    attribute: FACETS.Language,
    label: "Language",
  },
];

// For images on the main document list search view
export const THUMBNAIL_FLAGS = [
  // double resolution (single looks horrible)
  "dpr_2.0",
  // auto format
  "f_auto",
  // limit height
  "h_154",
].join(",");

// For preview images on document detail pages
export const PREVIEW_FLAGS = [
  // double resolution (single looks horrible)
  "dpr_2.0",
  // auto format
  "f_auto",
  // limit height
  "h_344",
].join(",");
