import type { Distributor as DistributorInterface } from "@10xdev/cms/model/distributor";
import useAccordion from "@10xdev/hooks/use-accordion";
import {
  borderLighter,
  borderRadiusMedium,
  colorWhite,
  layoutWidth1200,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import classnames from "classnames";
import type { ReactNode } from "react";
import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-dom";

import AccordionToggle from "../Accordion/AccordionToggle";
import Stack from "../Blocks/Update/Layout/Stack";
import Text from "../Text";
import { Distributor } from "./Distributor";

interface OurProps {
  distributors: DistributorInterface[];
  region: string;
}

type Props = OurProps & CurrentRefinementsProvided;

function DistributorAccordion({
  distributors,
  items: appliedFilters,
  region,
}: Props) {
  const countryIsSelected = appliedFilters.length > 0;

  // Should really have algolia do this but don't have the time to figure it our right now
  const sortedDistributors = distributors.sort((distributorA, distributorB) => {
    // Need to rejigger these typings at a later point
    if (distributorA.country! < distributorB.country!) {
      return -1;
    }
    if (distributorA.country! > distributorB.country!) {
      return 1;
    }

    return 0;
  });

  const content: ReactNode = sortedDistributors.map((distributor) => {
    return (
      <Stack border={true} borderTop={true} gap={"4rem"} key={distributor.name}>
        <Distributor distributor={distributor} />
      </Stack>
    );
  });

  const { active, children, contentRef, height, toggleAccordion } =
    useAccordion({
      activeInitial: countryIsSelected,
      children: content,
    });

  return (
    <div
      className={classnames("Accordion", {
        active: active,
      })}
      css={css`
        background: ${colorWhite};
        border: ${borderLighter};
        border-radius: ${borderRadiusMedium};
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        display: flex;
        flex-direction: column;
        max-width: ${layoutWidth1200};
        overflow: hidden;
        width: 100%;
      `}
    >
      <button
        css={css`
          align-items: baseline;
          background: none;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          outline: none;
          padding: 1.5rem 2rem 1.5rem 2rem;
          text-align: left;
        `}
        onClick={toggleAccordion}
      >
        <div>
          <Text as={"h4"} responsive={true} size={"xlarge"} weight={"semibold"}>
            {region}
          </Text>
        </div>
        <AccordionToggle active={active} size={"medium"} />
      </button>

      <div
        css={css`
          max-height: ${height};
          overflow: hidden;
          transition: max-height 0.3s cubic-bezier(0.46, 0.01, 0.92, 0.77);
          @media (max-width: ${mediaPhoneOnly}) {
            padding-right: 1.5rem;
          }
        `}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
}

export default connectCurrentRefinements<Props>(DistributorAccordion);
