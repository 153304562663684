import type { ReactNode } from "react";
import { createElement } from "react";

import CardA from "./CardA";
import CardB from "./CardB";
import CardC from "./CardC";
import CardD from "./CardD";
import CardE from "./CardE";
import CardF from "./CardF";
import CardG from "./CardG";
import CardH from "./CardH";
import CardI from "./CardI";
import CardJ from "./CardJ";

export const Components = {
  CardA,
  CardB,
  CardC,
  CardD,
  CardE,
  CardF,
  CardG,
  CardH,
  CardI,
  CardJ,
};

import type { Component } from "./types";

export const getCard = (data?: Component): ReactNode => {
  if (!data) {
    return null;
  }

  const createComponent = (item: Component): ReactNode => {
    const { data, type } = item;
    return createElement(Components[type] as any, {
      ...data,
    });
  };

  return createComponent(data);
};
