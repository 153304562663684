import type { BlogPostsIndexRecord } from "@10xdev/algolia-utils/blog-posts";
import { borderRadiusMedium, colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { format } from "date-fns";
import { compact } from "lodash-es";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Mark from "../Connectors/Mark";
import Breadcrumb from "./Breadcrumb";

interface Props {
  hit: Hit<BlogPostsIndexRecord>;
}

const Blog: FunctionComponent<Props> = ({ hit }) => {
  const date = hit.timestamp
    ? format(new Date(hit.timestamp), "MMM yyyy")
    : undefined;
  const defaultImage =
    "https://cdn.10xgenomics.com/image/upload/v1642790479/blog/blog-socialImage-default.svg";

  const researchArea = hit.researchAreas?.[0];
  const blogBreadcrumb = compact(["Blog", researchArea, date]);

  return (
    <Anchor
      css={css`
        display: flex;
      `}
      href={`/blog/${hit.slug}`}
    >
      <img
        alt={`Blog article - ${hit.title}`}
        css={css`
          align-self: flex-start;
          aspect-ratio: 3 / 2;
          border-radius: ${borderRadiusMedium};
          border: solid 1px ${colorSteelLighter};
          margin-right: 2rem;
          object-fit: cover;
          width: 150px;
        `}
        src={hit.socialImage || defaultImage}
      />
      <div>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
        <Breadcrumb path={blogBreadcrumb} />
      </div>
    </Anchor>
  );
};

export default Blog;
