import { stringify } from "qs";

export const getDownloadUrl = (
  softwareEULA: boolean,
  loupeFileUrl: string,
  downloadPageUrl: string,
  softwareSlug: string,
) => {
  /**
   * If the software EULA has been signed,
   * use the download URL as passed.
   */
  if (softwareEULA) {
    return loupeFileUrl;
  }

  /**
   * The software EULA has not been signed.
   * Preserve download state in query params
   * and link to the EULA instead.
   */
  const loupeFile = loupeFileUrl.split("/").pop()?.split("?").shift();
  const params = stringify({
    closeUrl: downloadPageUrl,
    redirectUrl: `${downloadPageUrl}?start=${loupeFile}`,
  });
  return `/products/${softwareSlug}/downloads/eula?${params}`;
};
