import { css } from "@emotion/react";

import Anchor from "../../../Anchor";
import Heading from "../../../Heading";
import Mark from "../../../SiteSearch/Connectors/Mark";
import Snippet from "../../../SiteSearch/Connectors/Snippet";
import Breadcrumb from "../../../SiteSearch/Hits/Breadcrumb";
import type { HitComponent } from "../../../SiteSearch/types";
import Text from "../../../Text";

const Page: HitComponent = ({ hit }) => {
  const { path, url } = hit;
  const href = url ? url : `/${path.join("/")}`;
  return (
    <>
      <Anchor href={href}>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
      </Anchor>
      <Text
        as={"p"}
        color={"midgray"}
        css={css`
          margin-bottom: 0.5rem;
        `}
        responsive={true}
        size={"medium"}
      >
        <Snippet attribute={"description"} hit={hit} />
      </Text>
      {path ? <Breadcrumb path={path.slice(0, 3)} /> : null}
    </>
  );
};

export default Page;
