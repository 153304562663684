import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Switch from "../Switch";
import Text from "../Text";

interface Props {
  checked: boolean;
  children: string;
  className?: string;
  onClick: () => void;
  onTransitionEnd?: () => void;
}

const ToggleWithLabel: FunctionComponent<Props> = ({
  children,
  className,
  onTransitionEnd,
  onClick,
  checked,
}) => {
  return (
    <div
      className={className}
      css={css`
        align-items: center;
        display: flex;
        gap: 8px;
      `}
    >
      <Switch
        checked={checked}
        height={18}
        label={children}
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
      />
      <Text
        as={"span"}
        css={css`
          margin-top: -1px;
        `}
        size={"small"}
      >
        {children}
      </Text>
    </div>
  );
};

export default ToggleWithLabel;
