import type { CompatibleProductsIndexRecord } from "@10xdev/algolia-utils/compatible-products";
import { css } from "@emotion/react";
import { groupBy, uniqBy } from "lodash-es";
import type { FunctionComponent } from "react";
import type { Hit, HitsProvided } from "react-instantsearch-core";
import { connectHits } from "react-instantsearch-core";

import { useHitsFirstReceived } from "../Search/hooks";
import ProductGroup from "./ProductGroup";

type Props = {
  onHits: () => void;
} & HitsProvided<Hit<CompatibleProductsIndexRecord>>;

const ProductGroupList: FunctionComponent<Props> = ({
  hits,
  onHits,
}: Props) => {
  const companyNames = uniqBy(hits, "companyName").map(
    (hit) => hit.companyName,
  );
  const productGroups = groupBy(hits, "companyName");

  useHitsFirstReceived(hits, onHits);

  return (
    <ul
      css={css`
        list-style: none;
        padding: 0;
      `}
    >
      {companyNames.map((companyName) => {
        return (
          <li
            css={css`
              margin-bottom: 2.5rem;
            `}
            key={companyName}
          >
            <ProductGroup
              companyName={companyName}
              key={companyName}
              productGroup={productGroups[companyName]}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default connectHits<Props, Hit<CompatibleProductsIndexRecord>>(
  ProductGroupList,
);
