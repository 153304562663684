import { colorSteelLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";
import Tile from "./Tile";

interface Props {
  description?: string;
  list?: Array<string>;
  product: {
    name: string | null;
    slug: string;
  };
  url?: string;
}

const ProductCardXlarge: FunctionComponent<Props> = ({
  description,
  list,
  product,
  url,
}) => {
  return (
    <Tile
      elevate={true}
      height={url ? "485px" : "auto"}
      hoverable={url ? true : false}
      href={url}
      outline={true}
    >
      <div
        css={css`
          box-sizing: border-box;
          display: flex;
          flex-wrap: nowrap;
          padding: ${url ? "3rem 3rem 0" : "3rem"};
          width: 100%;
        `}
      >
        <div
          css={css`
            box-sizing: border-box;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-start;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-bottom: 1rem;
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin: 0;
                transform: translateY(-5px);
              `}
              responsive={true}
              size={"xlarge"}
            >
              {product.name}
            </Heading>
          </div>

          {description ? (
            <Text
              as={"p"}
              color={"midgray"}
              css={css`
                margin-bottom: 1.5rem;
              `}
              responsive={true}
              size={"medium"}
            >
              {description}
            </Text>
          ) : null}

          {list?.length ? (
            <ul
              css={css`
                margin: 0;
                padding-left: 1rem;
              `}
            >
              {list.map((item) => {
                return (
                  <Text
                    as={"li"}
                    color={"midgray"}
                    css={css`
                      margin-bottom: 1rem;
                    `}
                    key={item}
                    responsive={true}
                    size={"medium"}
                  >
                    {item}
                  </Text>
                );
              })}
            </ul>
          ) : null}
        </div>
      </div>
      {url ? (
        <div
          css={css`
            align-items: center;
            border-top: solid 1px ${colorSteelLightest};
            display: flex;
            flex-direction: column;
            height: 80px;
            justify-content: center;
            margin-top: auto;
            width: 100%;
          `}
        >
          <Text as={"div"} color={"blue"} size={"small"} weight={"medium"}>
            {"More info"}
            <Icon
              size={"11px"}
              source={"nav-right"}
              xPos={"16px"}
              yPos={"1px"}
            />
          </Text>
        </div>
      ) : null}
    </Tile>
  );
};

export default ProductCardXlarge;
