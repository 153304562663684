import { colorBlueLightest, colorWarningLight } from "@10xdev/design-tokens";

import type { Callout, CalloutLevel } from "./types";

export const getCalloutLevel = (type: CalloutLevel) => {
  const types: Record<string, Callout> = {
    ["announcement"]: {
      backgroundColor: colorBlueLightest,
      iconColor: "blue",
      iconSource: "megaphone",
    },
    ["info"]: {
      backgroundColor: colorBlueLightest,
      iconColor: "blue",
      iconSource: "info-solid",
    },
    ["warning"]: {
      backgroundColor: colorWarningLight,
      iconColor: "warning",
      iconSource: "warning",
    },
  };
  return types[type];
};
