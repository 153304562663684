import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

interface Props {
  border?: string;
  className?: string;
  height?: string;
  source: string;
  title: string;
  width?: string;
}

const Iframe: FunctionComponent<Props> = ({
  border = "none",
  className,
  height = "auto",
  source,
  title,
  width = "100%",
}) => {
  return (
    <iframe
      className={className}
      css={css`
        border: ${border};
      `}
      height={height}
      src={source}
      title={title}
      width={width}
    />
  );
};

export default Iframe;
