import type { GetAllEventsQuery } from "@10xdev/types/__generated__/graphql-types";
import { colorSteelDarker, colorSteelLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import ThematicBreak from "../ThematicBreak";

interface Props {
  collapsed?: boolean;
  event: NonNullable<GetAllEventsQuery["events"]>[0];
}

/**
 * A list of research areas addressed by a given event.
 */
const ResearchAreas: FunctionComponent<Props> = ({ collapsed, event }) => {
  const { researchAreas } = event;

  return researchAreas && researchAreas.length ? (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: ${collapsed ? "216px" : "240px"};
      `}
    >
      <ThematicBreak
        css={css`
          margin: 0 0 8px 0;
        `}
      />

      <Text
        as={"h4"}
        css={css`
          margin-bottom: 16px;
        `}
        size={"small"}
        weight={"semibold"}
      >
        {"Research Areas"}
      </Text>

      <ul
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {researchAreas.map((researchArea) => (
          <li
            css={css`
              align-items: center;
              background-color: ${colorSteelLightest};
              border-radius: 16px;
              box-sizing: border-box;
              display: flex;
              height: 32px;
              margin-bottom: 8px;
              padding: 0 16px 3px 16px;
            `}
            key={researchArea}
          >
            <Text
              as={"span"}
              css={css`
                color: ${colorSteelDarker};
              `}
              size={"small"}
              weight={"regular"}
            >
              {researchArea}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default ResearchAreas;
