import { colorBlueLightest, mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Image from "../../../Image";
import LoopingVideo from "../../../LoopingVideo";
import Text from "../../../Text";
import type { SectionImage } from "../types";
export interface Props {
  description: string;
  image?: SectionImage;
  title: string;
  video?: string;
}

const CardA = ({ description, title, image, video }: Props) => {
  return (
    <Grid
      css={css`
        background: ${colorBlueLightest};
        padding: 1.5rem;
        flex: 1 1 0;
        align-content: space-between;
      `}
      gap={"1rem"}
    >
      <Text as={"h4"} size={"large"} weight={"semibold"}>
        {title}
      </Text>
      <Text as={"p"} color={"midgray"} size={"medium"}>
        {description}
      </Text>

      {image && image.srcSmall ? (
        <Image
          alt={image.alt}
          css={css`
            display: block;
            @media (min-width: ${mediaPhoneOnly}) {
              display: none;
            }
          `}
          src={image.srcSmall}
        />
      ) : null}
      {image ? (
        <Image
          alt={image.alt}
          css={css`
            display: none;
            @media (min-width: ${mediaPhoneOnly}) {
              display: block;
            }
          `}
          src={image.src}
        />
      ) : null}

      {video ? <LoopingVideo src={video} /> : null}
    </Grid>
  );
};

export default CardA;
