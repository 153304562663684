import {
  borderStandard,
  colorWhite,
  fontSizeSmall,
  fontWeightMedium,
  fontWeightRegular,
  layoutWidth878,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import ClearFiltersButton from "../Search/ClearFiltersButton";
import Query from "../Search/Query";
import { BLOG_FACETS } from "./constants";
import CustomMenuSelect from "./CustomMenuSelect";

const productCss = css`
  width: 21rem;
  margin-right: 1.5rem;
  @media (max-width: ${layoutWidth878}) {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0;
  }
`;

const researchAreaCss = css`
  width: 13.5rem;
  @media (max-width: ${layoutWidth878}) {
    width: 100%;
  }
`;

const queryCss = css`
  height: 3rem;
  @media (max-width: ${layoutWidth878}) {
    padding: 0 1rem;
    width: 100%;
  }
`;

const selectsCss = css`
  flex: 2;
  display: flex;

  @media (max-width: ${layoutWidth878}) {
    height: 112px;
    padding: 0 1rem;
    flex-direction: column;
    align-items: center;
    flex-gap: 1rem;
    margin-bottom: 1rem;
  }
`;

const filtersContentCss = css`
  width: 100%;
  display: flex;
  height: 3rem;

  @media (max-width: ${layoutWidth878}) {
    flex-direction: column;
    height: 176px;
  }
`;

const queryContainerCss = css`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  @media (max-width: ${layoutWidth878}) {
    width: 100%;
    flex: 0;
  }
`;

const MenuSelects: FunctionComponent = () => {
  return (
    <div
      css={css`
        max-width: 1200px;
        display: flex;
        justify-content: center;
        background: ${colorWhite};
        box-sizing: border-box;
        padding: 1rem 0 3rem 0;
        margin: 0 auto;
        border-bottom: ${borderStandard};
      `}
    >
      <div css={filtersContentCss}>
        <div css={selectsCss}>
          {BLOG_FACETS.map((facet) => {
            return (
              <CustomMenuSelect
                attribute={facet.attribute}
                css={
                  facet.attribute === "products.name"
                    ? productCss
                    : researchAreaCss
                }
                key={facet.label}
                placeholder={facet.label}
              />
            );
          })}
          <ClearFiltersButton
            css={css`
              flex-shrink: 0;
              font-size: ${fontSizeSmall};
              font-weight: ${fontWeightMedium};
              margin-left: 2rem;
            `}
          >
            {"Reset filters"}
          </ClearFiltersButton>
        </div>
        <div css={queryContainerCss}>
          <Query
            activeWidth={"320px"}
            css={queryCss}
            inactiveWidth={"240px"}
            includeStats={false}
            inputAttrs={{
              style: {
                fontSize: fontSizeSmall,
                fontWeight: fontWeightRegular,
              },
            }}
            placeholder={"Search posts"}
            searchIconAttrs={{
              size: "14px",
            }}
            style={{ height: "3rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuSelects;
