import type { Section } from "@10xdev/cms/definitions/blocks/section";
import { css } from "@emotion/react";
import { Fragment } from "react";

import type { GridProps } from "../../../Grid";
import Grid from "../../../Grid";
import { getCard } from "../Cards/RenderCard";
import type { Card, CardType } from "../Cards/types";
import type { StyleProps } from "../types";
import { getContent, getStyles } from "../utils";

export type SectionCard = {
  data: Card;
  type: CardType;
};
type Component = Section | SectionCard;
export interface Props {
  content: Component[];
  layout?: GridProps;
  styles?: StyleProps;
}

const isCard = (item: Section | SectionCard): item is SectionCard => {
  return (item as SectionCard).data !== undefined;
};

const GridGroup = ({ content, layout, styles }: Props) => {
  const cssString = styles ? getStyles(styles) : "";
  return (
    <Grid
      {...layout}
      css={css`
        ${cssString}
        > * {
          align-self: start;
        }
      `}
    >
      {content?.map((item, index) => {
        return (
          <Fragment key={`${item.type}-${index}`}>
            {isCard(item) ? getCard(item) : getContent(item)}
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default GridGroup;
