import { fontWeightSemibold } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { HighlightProvided, Hit } from "react-instantsearch-core";
import { connectHighlight } from "react-instantsearch-dom";

import type { AlgoliaRecord } from "../types";

type Props = {
  attribute: string;
  hit: Hit<AlgoliaRecord>;
} & HighlightProvided<AlgoliaRecord>;

const Mark: FunctionComponent<Props> = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    attribute,
    highlightProperty: "_highlightResult",
    hit,
  });

  return (
    <>
      {parsedHit.map((part) =>
        part.isHighlighted ? (
          <span
            css={css`
              font-weight: ${fontWeightSemibold};
            `}
            key={part.value}
          >
            {part.value}
          </span>
        ) : (
          part.value
        ),
      )}
    </>
  );
};

export default connectHighlight<Props, AlgoliaRecord>(Mark);
