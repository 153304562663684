import { css } from "@emotion/react";

import Image from "../../../Image";
import type { SectionImage } from "../types";
import Flex from "../../../Flex";
import Anchor from "../../../Anchor";
import Text from "../../../Text";
import { getSecondaryButtonCss } from "../buttonStyles";

export interface Props {
  linkButtons: {
    image?: SectionImage;
    label: string;
    href: string;
    size?: {
      height: string;
      width: string;
    };
  }[];
}

const CardJ = ({ linkButtons }: Props) => {
  return (
    <Flex gap={"1rem"} flexWrap={"wrap"}>
      {linkButtons.map(
        (
          { image, size = { height: "40px", width: "250px" }, label, href },
          index,
        ) => {
          return (
            <Anchor
              css={css`
                ${getSecondaryButtonCss(size)}
                flex: 0 0 auto;
                max-width: none;
              `}
              href={href}
              id={label}
              key={index}
              target={"_self"}
            >
              <Flex gap="1rem" alignItems="center">
                {image?.src && (
                  <Image
                    alt={image.alt}
                    css={css`
                      flex: 0 0 auto;
                    `}
                    src={image.src}
                    width="38px"
                  />
                )}
                <Text as={"span"} size={"small"} weight={"medium"}>
                  {label}
                </Text>
              </Flex>
            </Anchor>
          );
        },
      )}
    </Flex>
  );
};

export default CardJ;
