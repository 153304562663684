import { fontWeightRegular, fontWeightSemibold } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { HighlightProvided, Hit } from "react-instantsearch-core";
import { connectHighlight } from "react-instantsearch-dom";

import type { AlgoliaRecord } from "../types";

type Props = {
  attribute: string;
  hit: Hit<AlgoliaRecord>;
} & HighlightProvided<AlgoliaRecord>;

const Snippet: FunctionComponent<Props> = ({ highlight, attribute, hit }) => {
  const parsedHits = highlight({
    attribute,
    highlightProperty: "_snippetResult",
    hit,
  });

  return (
    <span>
      {parsedHits.map((part) => (
        <span
          css={css`
            font-weight: ${part.isHighlighted
              ? fontWeightSemibold
              : fontWeightRegular};
          `}
          key={part.value}
        >
          {part.value}
        </span>
      ))}
    </span>
  );
};

export default connectHighlight(Snippet);
