import { css } from "@emotion/react";

import Anchor from "../Anchor";
import Text from "../Text";

interface LinkCardProps {
  link: string;
  subtext: string;
  target?: string;
  title: string;
}

const LinkCard = ({
  link,
  subtext,
  target = "_self",
  title,
}: LinkCardProps) => {
  return (
    <Anchor
      css={css`
        max-width: 320px;
      `}
      href={link}
      target={target}
    >
      <Text
        as={"div"}
        color={"inherit"}
        css={css`
          margin-bottom: 0.5rem;
        `}
        size={"small"}
        weight={"medium"}
      >
        {title}
      </Text>
      <Text as={"div"} size={"xsmall"} weight={"regular"}>
        {subtext}
      </Text>
    </Anchor>
  );
};

export default LinkCard;
