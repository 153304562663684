import type { PropsWithChildren } from "react";

import type { Props as AnchorProps } from "../Anchor";
import Anchor from "../Anchor";

type Props = PropsWithChildren<AnchorProps | object>;

export default function ConditionalLink(props: Props) {
  if ("href" in props && props.href) {
    return <Anchor {...props} />;
  }

  return <>{props.children}</>;
}
