import { VideoAlgoliaRecord } from "@10xdev/algolia-utils/types";
import { useState } from "react";
import Anchor from "../Anchor";
import Flex from "../Flex";
import Text from "../Text";
import VideoThumbnail from "./VideoThumbnail";

interface TitleCellProps {
  title: string;
  record: VideoAlgoliaRecord;
}

const TitleCell = ({ title, record }: TitleCellProps) => {
  const [active, setActive] = useState(false);

  return (
    <Anchor
      href={`/videos/${record.id}?autoplay=true`}
      onBlur={() => setActive(false)}
      onFocus={() => setActive(true)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onMouseOver={() => setActive(true)}
    >
      <Flex alignItems={"center"} gap={"1rem"}>
        <VideoThumbnail active={active} thumbnailUrl={record.thumbnail.url} />
        <Text as={"span"} color={"inherit"} size={"xsmall"} weight={"medium"}>
          {title}
        </Text>
      </Flex>
    </Anchor>
  );
};

export default TitleCell;
