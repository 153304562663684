import {
  colorBlueDark,
  colorBlueLighter,
  colorBlueMedium,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MouseEvent, ReactNode } from "react";

import { EASE } from "../../../constants";
import type { AssayGroup } from "../types";

interface Props {
  active: boolean;
  children: ReactNode;
  className?: string;
  id: AssayGroup | string;
  onClick: (id: AssayGroup | string) => void;
}

const Segment = ({ children, className, active, onClick, id }: Props) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick(id);
  };

  return (
    <button
      className={className}
      css={css`
        background-color: ${active ? colorBlueMedium : colorWhite};
        border: none;
        border-radius: 3rem;
        color: ${active ? colorWhite : colorBlueMedium};
        cursor: pointer;
        transition: all 0.1s ${EASE};
        :hover {
          background: ${active ? colorBlueDark : colorBlueLighter};
        }
      `}
      onClick={(event) => handleClick(event)}
    >
      {children}
    </button>
  );
};

export default Segment;
