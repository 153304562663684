import { css } from "@emotion/react";
import { Fragment, HTMLProps } from "react";
import { v4 as uuidv4 } from "uuid";

import Anchor from "../../Anchor";
import type { ButtonSize, ButtonType } from "../Masthead/types";
import {
  getPrimaryButtonCss,
  getSecondaryButtonCss,
  getTertiaryButtonCss,
} from "./buttonStyles";
import type { Props as IconProps } from "./Icon";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";
import type { Props as TextProps } from "./Text";
import { getContent } from "./utils";
type ButtonAlign = "center" | "left";

type ButtonContent = (TextProps & { type: string }) | IconProps;
interface Props {
  align?: ButtonAlign;
  container?: ContainerProps;
  content?: ButtonContent[];
  href: string;
  id?: string;
  size?: ButtonSize;
  target?: HTMLProps<HTMLAnchorElement>["target"];
  type?: ButtonType;
}

const Button = ({
  align = "center",
  container,
  content,
  href = "/",
  id,
  size = { height: "4rem", width: "15rem" },
  target = "_self",
  type = "primary",
}: Props) => {
  const getButtonStyles = (type: ButtonType, size: ButtonSize) => {
    const styles = {
      ["primary"]: getPrimaryButtonCss(size),
      ["secondary"]: getSecondaryButtonCss(size),
      ["tertiary"]: getTertiaryButtonCss(size),
    };
    return styles[type];
  };
  return (
    <Container {...container}>
      <div
        css={css`
          display: flex;
          justify-content: ${align};
        `}
      >
        <Anchor
          css={css`
            ${getButtonStyles(type, size)}
          `}
          href={href}
          id={id}
          target={target}
        >
          {content?.map((item) => {
            return <Fragment key={uuidv4()}>{getContent(item)}</Fragment>;
          })}
        </Anchor>
      </div>
    </Container>
  );
};

export default Button;
