import {
  borderRadiusMedium,
  borderStandard,
  colorWhite,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import Image from "next/image";

import Anchor from "../Anchor";
import { buttonCss } from "../Button";
import Flex from "../Flex";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  content: string[];
  cta?: {
    label: string;
    url: string;
    width?: number;
  };
  image: {
    alt: string;
    height?: number;
    source: string;
    width?: number;
  };
  link: {
    label: string;
    url: string;
  };
  title: string;
  width?: string;
}

const RowCard = ({
  content,
  cta,
  image,
  link,
  title,
  width = "100%",
}: Props) => {
  const ctaWidth = cta?.width || 240;
  return (
    <div
      css={css`
        background: ${colorWhite};
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        overflow: hidden;
        max-width: ${width};
        width: 100%;
        padding: 1rem;
      `}
    >
      <Flex
        alignItems={{ base: "flex-start", phoneOnly: "center" }}
        flexDirection={{ base: "row", phoneOnly: "column" }}
        gap={"1rem"}
      >
        <Image
          alt={image.alt}
          height={image.height ?? 135}
          src={image.source}
          width={image.width ?? 180}
        />
        <Flex
          alignItems={{ base: "flex-start", phoneOnly: "center" }}
          flexDirection={"column"}
          gap={"1rem"}
        >
          <Flex flexDirection={"column"} gap={"0.5rem"}>
            <Text as={"h4"} size={"medium"} weight={"semibold"}>
              {title}
            </Text>
            {content.map((item, index) => (
              <Text as={"p"} key={index} size={"small"}>
                {item}
              </Text>
            ))}
          </Flex>
          <div>
            {cta ? (
              <Anchor
                css={[
                  buttonCss({}),
                  `
                    height: auto;
                    width: ${ctaWidth}px;
                    padding: 0.5rem;
                    white-space: normal;
                    text-align: center;
                    word-wrap: break-word;

                    @media (max-width: ${mediaPhoneOnly}) {
                      padding: 0.75rem;
                    }
                  `,
                ]}
                href={cta.url}
              >
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"small"}
                  weight={"medium"}
                >
                  {cta.label}
                </Text>
              </Anchor>
            ) : null}

            {link ? (
              <Anchor href={link.url}>
                <Text
                  as={"span"}
                  color={"inherit"}
                  css={css`
                    margin-right: 0.75rem;
                  `}
                  size={"small"}
                  weight={"medium"}
                >
                  {link.label}
                </Text>
                <Icon size={"11px"} source={"nav-right"} />
              </Anchor>
            ) : null}
          </div>
        </Flex>
      </Flex>
    </div>
  );
};

export default RowCard;
