import { css } from "@emotion/react";
import type { StatsProvided } from "react-instantsearch-core";
import { connectStats } from "react-instantsearch-dom";

import Text from "../Text";

interface ConnectedStatsProps extends StatsProvided {
  indexName: string;
}

const nf = new Intl.NumberFormat("en-US");
const ConnectedStats = ({ indexName, nbHits }: ConnectedStatsProps) => (
  <div
    css={css`
      flex: 0 0 auto;
    `}
  >
    <Text as={"span"} color={"steelDarkest"} size={"xsmall"} weight={"regular"}>
      {nbHits
        ? `(Showing ${nf.format(nbHits)} ${indexName})`
        : `(No ${indexName} found)`}
    </Text>
  </div>
);

export default connectStats(ConnectedStats);
