export const COMPATIBLE_PRODUCT_FACETS = [
  {
    attribute: "type",
    label: "Product type",
  },
  {
    attribute: "compatibleProducts.name",
    label: "Compatible with",
  },
];
