import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLight,
  colorBlueLighter,
  colorBlueMedium,
  colorSteelMedium,
  colorWhite,
  fontFamilyBase,
  sizeSmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import type { ButtonSize } from "../Masthead/types";

export const getBaseButtonCss = (height: string, width: string) => {
  return css`
    align-items: center;
    background: none;
    border: none;
    border-radius: ${borderRadiusMedium};
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: ${fontFamilyBase};
    gap: ${sizeSmall};
    height: ${height};
    justify-content: center;
    max-width: 24rem;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    width: ${width};
    transition: border 0.225s ease-out, color 0.225s ease-out,
      background 0.225s ease-out;
  `;
};

const disabledBaseCss = css`
  cursor: default;
  pointer-events: none;
`;

export const getPrimaryButtonCss = (size: ButtonSize) => {
  return css`
    ${getBaseButtonCss(size.height, size.width)}
    background: ${colorBlueMedium};
    color: ${colorWhite};
    &:hover {
      background: ${colorBlueDark};
      border: none;
      color: ${colorWhite};
    }
    &:disabled {
      ${disabledBaseCss}
      background: ${colorBlueLight};
      color: ${colorWhite};
    }
  `;
};

export const getSecondaryButtonCss = (size: ButtonSize) => {
  return css`
    ${getBaseButtonCss(size.height, size.width)}
    background: ${colorWhite};
    border: ${borderStandard};
    color: ${colorBlueMedium};
    &:hover {
      background: ${colorBlueLighter};
      border: solid 1px ${colorBlueLight};
      color: ${colorBlueMedium};
    }
    &:disabled {
      ${disabledBaseCss}
      color: ${colorBlueLight};
    }
  `;
};

export const getTertiaryButtonCss = (size: ButtonSize) => {
  return css`
    ${getBaseButtonCss(size.height, size.width)}
    background: transparent;
    border: solid 1px;
    color: ${colorWhite};
    &:hover {
      background: rgba(255, 255, 255, 0.15);
      border: solid 1px;
    }
    &:disabled {
      ${disabledBaseCss}
      $color: ${colorSteelMedium}
    }
  `;
};
