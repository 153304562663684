import {
  colorBlueDark,
  colorSteelLighter,
  spacing8,
  spacing16,
  spacing24,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { Dispatch, ReactElement, SetStateAction } from "react";
import React, { createContext, useCallback, useState } from "react";
import { useUpdateEffect } from "react-use";

import Icon from "../../Icon";
import Text from "../../Text";
import ActionButton from "./ActionButton";
import type {
  FilterItemValue,
  FilterItemValues,
  MenuExpandedState,
  MultiSelectFilterProp,
  NumericFilterProp,
  OnSelectCallback,
  SingleSelectFilterProp,
} from "./types";
import { isFilterMenuSelected } from "./utils";

interface Props {
  children?: React.ReactNode;
  className?: string;
  clearAllButton?: React.ReactNode;
  collapseMenusOnClearAll?: boolean;
  defaultCollapsed?: boolean;
  defaultExpandedMenus?: Record<string, boolean>;
  heading: string;
  onChange?: (values: FilterItemValues) => void;
  values?: FilterItemValues;
}

export type FilterContextType = {
  menuExpandedState: MenuExpandedState;
  onClearSelection: (id: string) => () => void;
  onFilterSelect: OnSelectCallback<FilterItemValue>;
  onMenuClick: (
    item: SingleSelectFilterProp | MultiSelectFilterProp | NumericFilterProp,
  ) => (expanded: boolean) => void;
  selectedValues: FilterItemValues;
  setMenuExpandedState: Dispatch<SetStateAction<MenuExpandedState>>;
};

export const FilterContext = createContext<FilterContextType>({
  menuExpandedState: {},
  onClearSelection: () => () => {},
  onFilterSelect: () => {},
  onMenuClick: () => () => {},
  selectedValues: {},
  setMenuExpandedState: () => {},
});

const Filter = ({
  children,
  className,
  clearAllButton,
  collapseMenusOnClearAll = false,
  defaultCollapsed = false,
  defaultExpandedMenus = {},
  heading,
  onChange,
  values = {},
}: Props) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [menuExpandedState, setMenuExpandedState] =
    useState<MenuExpandedState>(defaultExpandedMenus);
  const [selectedValues, setSelectedValues] =
    useState<FilterItemValues>(values);

  useUpdateEffect(() => {
    onChange?.(selectedValues);
  }, [onChange, selectedValues]);

  const noSelectedFilter = Object.keys(selectedValues).length === 0;

  const onClearAll = () => {
    setSelectedValues({});
    if (collapseMenusOnClearAll) {
      setMenuExpandedState({});
    }
  };

  const onClearSelection = (id: string) => () => {
    setSelectedValues((prev) => {
      const { [id]: omitted, ...rest } = prev;
      return rest;
    });
  };

  const onMenuClick = useCallback(
    (
        item:
          | SingleSelectFilterProp
          | MultiSelectFilterProp
          | NumericFilterProp,
      ) =>
      (expanded: boolean) => {
        setMenuExpandedState((prev) => {
          const otherExpandedUnselectedMenu = Object.keys(prev)
            .filter((menuItemId) => {
              // if the clicked menu is collapsed, don't do anything
              if (!expanded) {
                return true;
              }
              // filter out other menus that are expanded but don't have selections
              return (
                !prev[menuItemId] ||
                (prev[menuItemId] &&
                  isFilterMenuSelected(item, selectedValues[menuItemId]))
              );
            })
            // map the keys to menuExpandedState object
            .reduce((obj, key) => {
              obj[key] = prev[key];
              return obj;
            }, {} as MenuExpandedState);

          return {
            ...otherExpandedUnselectedMenu,
            [item.id]: expanded,
          };
        });
      },
    [selectedValues],
  );

  const onFilterSelect: OnSelectCallback<FilterItemValue> = useCallback(
    ({ id, value }) => {
      setSelectedValues((prev) => ({ ...prev, [id]: value }));
    },
    [],
  );

  const value = {
    menuExpandedState,
    onClearAll,
    onClearSelection,
    onFilterSelect,
    onMenuClick,
    selectedValues,
    setMenuExpandedState,
  };

  return (
    <FilterContext.Provider value={value}>
      <div
        className={className}
        css={css`
          background: white;
          border-top: 1px solid ${colorSteelLighter};
          box-sizing: border-box;
          padding: ${collapsed ? `${spacing16} 0 0` : 0};
          width: ${collapsed ? "38px" : "272px"};
          position: relative;
        `}
      >
        {/** Filter UI when collapsed */}
        <button
          css={css`
            align-items: flex-start;
            display: ${collapsed ? "flex" : "none"};
            flex-direction: column;
            gap: ${spacing8};
            background: transparent;
            border: 0;
            box-sizing: border-box;
            color: inherit;
            cursor: pointer;
            font: inherit;
            padding: 0;
            margin: 0;
            outline: inherit;

            &:hover {
              color: ${colorBlueDark};
            }
          `}
          data-testid={"dataset-filter-expand-button"}
          onClick={() => setCollapsed(false)}
        >
          <Icon
            css={css`
              color: inherit;
            `}
            size={"18px"}
            source={"funnel"}
          />
          <Text
            as={"div"}
            color={"steelDarkest"}
            css={css`
              color: inherit;
              line-height: 0.8;
              writing-mode: vertical-lr;
              -webkit-transform: rotate(-180deg);
              -moz-transform: rotate(-180deg);
            `}
            size={"small"}
            weight={"medium"}
          >
            {heading}
          </Text>
        </button>

        {/** Filter UI when expanded */}
        <div
          css={css`
            display: ${collapsed ? "none" : "block"};
            position: relative;
          `}
        >
          {/** Filter Menu Header */}
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              padding-bottom: ${spacing16};
              padding: ${spacing16};
            `}
          >
            <Text
              as={"div"}
              color={"steelDarkest"}
              data-testid={"dataset-filter-heading"}
              size={"small"}
              weight={"semibold"}
            >
              {heading}
            </Text>
            <div
              css={css`
                display: flex;
                gap: ${spacing24};
              `}
            >
              {clearAllButton
                ? React.cloneElement(clearAllButton as ReactElement, {
                    onClearAll,
                  })
                : !noSelectedFilter && (
                    <ActionButton onClick={onClearAll} text={"Clear all"} />
                  )}
              <ActionButton
                data-testid={"dataset-filter-collapse-button"}
                icon={"collapse"}
                onClick={() => setCollapsed(true)}
              />
            </div>
          </div>

          {/** Collapsible Filter Menu */}
          {children}
        </div>
      </div>
    </FilterContext.Provider>
  );
};

export default Filter;
