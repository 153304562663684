import { colorBlueLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Code from "../../Code/Code";
import Tabs from "../../Datasets/Tabs";
import PreformattedText from "../../PreformattedText";
import Text from "../../Text";

interface Props {
  filename?: string | null;
  url: string;
}

const BatchDownload = ({ filename, url }: Props) => {
  const curlHref = filename
    ? `curl -o ${filename} "${url}"`
    : `curl -O "${url}"`;
  const wgetHref = filename ? `wget -O ${filename} "${url}"` : `wget "${url}"`;

  return (
    <Tabs maxWidth={"760px"} minWidth={"0"}>
      <Tabs.Header>
        <Tabs.HeaderList>
          <Tabs.Tab>
            <Text as={"span"} color={"inherit"} size={"medium"}>
              {"curl"}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text as={"span"} color={"inherit"} size={"medium"}>
              {"wget"}
            </Text>
          </Tabs.Tab>
        </Tabs.HeaderList>
      </Tabs.Header>

      <Tabs.Panels>
        <Tabs.Panel>
          <PreformattedText
            css={css`
              background: ${colorBlueLightest};
              margin: 0;
            `}
          >
            <Code className={"language-unix"}>{curlHref}</Code>
          </PreformattedText>
        </Tabs.Panel>

        <Tabs.Panel>
          <PreformattedText
            css={css`
              background: ${colorBlueLightest};
              margin: 0;
            `}
            highlight={true}
          >
            <Code className={"language-unix"}>{wgetHref}</Code>
          </PreformattedText>
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  );
};

export default BatchDownload;
