import { useCallback } from "react";

interface CallBack<Params extends any[]> {
  (...args: Params): void;
}

/**
 * Create a new event handler that calls the given event handlers in order with the same parameters.
 */
export const useEventHandlers = <Params extends any[]>(
  ...handlers: Array<CallBack<Params> | undefined>
) => {
  return useCallback<CallBack<Params>>(
    (...args: Params) => {
      handlers.forEach(
        (handler) => typeof handler === "function" && handler(...args),
      );
    },
    [handlers],
  );
};
