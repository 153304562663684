import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import type { TextColor, TextSize } from "@10xdev/types";
import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  color?: TextColor;
  container?: ContainerProps;
  paragraphs: string[];
  size?: TextSize;
}

const Paragraphs = ({
  container,
  color = "midgray",
  paragraphs,
  size = "medium",
}: Props) => {
  return (
    <Container {...container}>
      {paragraphs.map((item) => {
        return (
          <Text
            as={"p"}
            color={color}
            css={css`
              margin: 0;
            `}
            key={uuidv4()}
            responsive={true}
            size={size}
          >
            {item}
          </Text>
        );
      })}
    </Container>
  );
};

export default Paragraphs;
