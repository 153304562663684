import { compact } from "lodash-es";

export const getDocumentDetailCopy = ({
  cgNumber,
  documentType,
}: {
  cgNumber?: string | null;
  documentType?: string;
}) => {
  if (!cgNumber && !documentType) {
    return null;
  }

  return compact([documentType, cgNumber]).join(", ");
};
