import type { TextColor } from "@10xdev/types";
import { css } from "@emotion/react";

import Icon from "../../Icon/Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

export type Headline = {
  as?: "h1" | "h2" | "h3" | "h4";
  color?: TextColor;
  size?: "medium" | "large" | "xlarge" | "xxlarge" | "xxxlarge";
  text: string;
};

interface Props {
  container?: ContainerProps;
  eyebrow?: {
    color?: TextColor;
    icon: {
      source: IconSource;
    };
    text: string;
  };
  headline: Headline;
}

const Title = ({ eyebrow, headline, container }: Props) => {
  const { as = "h3", color = "base", size = "xxlarge" } = headline;

  return (
    <Container {...container}>
      <div>
        {eyebrow ? (
          <div
            css={css`
              ${container?.center?.text === true
                ? "justify-content: center;"
                : null}
              display: flex;
              gap: 1rem;
              margin-bottom: 1rem;
            `}
          >
            {eyebrow.icon ? (
              <Icon size={"40px"} source={eyebrow.icon.source} />
            ) : null}
            <Text
              as={"div"}
              color={eyebrow.color || "blue"}
              responsive={true}
              size={"large"}
              weight={"semibold"}
            >
              {eyebrow.text}
            </Text>
          </div>
        ) : null}

        <Text
          as={as}
          color={color}
          responsive={true}
          size={size}
          weight={"semibold"}
        >
          {headline.text}
        </Text>
      </div>
    </Container>
  );
};

export default Title;
