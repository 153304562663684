import type {
  FilterItemValue,
  MultiSelectFilterProp,
  MultiSelectMenuValue,
  NumericFilterProp,
  NumericMenuValue,
  SingleSelectFilterProp,
  SingleSelectMenuValue,
} from "./types";

export const isSingleSelectValue = (
  value: FilterItemValue,
): value is SingleSelectMenuValue => {
  return typeof value === "string" || typeof value === "number";
};

export const isMultiSelectValue = (
  value: FilterItemValue,
): value is MultiSelectMenuValue => {
  return Array.isArray(value);
};

export const isNumericValue = (
  value: FilterItemValue,
): value is NumericMenuValue => {
  return (
    typeof value === "object" &&
    !Array.isArray(value) &&
    typeof value.maxValue === "number" &&
    typeof value.minValue === "number"
  );
};

export const isFilterMenuSelected = (
  item: SingleSelectFilterProp | MultiSelectFilterProp | NumericFilterProp,
  value?: FilterItemValue,
) => {
  if (item.type === "numeric" && value && isNumericValue(value)) {
    return (
      value && (value.minValue !== item.min || value.maxValue !== item.max)
    );
  }
  return (
    value !== null &&
    value !== undefined &&
    value !== "" &&
    !(Array.isArray(value) && value.length === 0)
  );
};
