import type { AlgoliaRecord } from "@10xdev/algolia-utils/videos";
import { borderRadiusMedium, colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { compact } from "lodash-es";
import type { FunctionComponent } from "react";
import { useState } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import PlayButton from "../../PlayButton";
import Mark from "../Connectors/Mark";
import Breadcrumb from "./Breadcrumb";

interface Props {
  hit: Hit<AlgoliaRecord>;
}

const Video: FunctionComponent<Props> = ({ hit }) => {
  const [active, setActive] = useState(false);
  const category = hit.category?.label;
  const videoBreadcrumb = compact(["videos", category]);

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  return (
    <Anchor
      css={css`
        display: flex;
      `}
      href={`/videos/${hit.id}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        css={css`
          align-self: flex-start;
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 1fr;
          margin-right: 2rem;
          place-items: center;
        `}
      >
        {hit.thumbnail?.url ? (
          <img
            alt={`Screenshot of ${hit.title}`}
            css={css`
              aspect-ratio: 3 / 2;
              border-radius: ${borderRadiusMedium};
              border: solid 1px ${colorSteelLighter};
              grid-column: 1;
              grid-row: 1;
              object-fit: cover;
              width: 150px;
            `}
            src={hit.thumbnail.url}
          />
        ) : null}
        <div
          css={css`
            align-items: center;
            display: flex;
            grid-column: 1;
            grid-row: 1;
            height: 100%;
            justify-content: center;
            width: 100%;
          `}
        >
          <PlayButton active={active} iconSize={20} size={48} />
        </div>
      </div>
      <div>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
        <Breadcrumb path={videoBreadcrumb} />
      </div>
    </Anchor>
  );
};

export default Video;
