import type { NextRouter } from "next/router";

import type { CardTextSizes } from "./types";

export const handleClick = async (
  router: NextRouter,
  href: string,
  target?: string,
) => {
  if (target === "_blank") {
    window.open(href, target, "noopener,noreferrer");
  } else {
    await router.push(href);
    // Without this, Next.js might render a distance down the page
    window.scrollTo(0, 0);
  }
};

export const getCardTextSizes = () => {
  const sizes: CardTextSizes = {
    large: {
      descriptionSize: "medium",
      headingMargin: "1.5rem",
      headingSize: "xlarge",
    },
    medium: {
      descriptionSize: "small",
      headingMargin: "1rem",
      headingSize: "large",
    },
  };
  return sizes;
};
