import { NO_GATE_KEY } from "@10xdev/site/utils/local-storage-keys";
import { useRouter } from "next/router";
import qs from "qs";
import { useLocalStorage } from "react-use";

export type MarketoFormProductParam = "chromium" | "xenium";

interface Args {
  /**
   * The URL to send the user to, in the event
   * that they close the gating form without
   * successfully submitting it.
   */
  closeUrl: string;

  /**
   * The URL of the form that the user must
   * fill out to remove the gate. The default
   * form is `/welcome`, but you can override
   * this by passing an alternate in `formUrl`.
   *
   * NOTE: If you override this, you must also
   * pass an alternate value to `localStorageKey`
   * to ensure that the flag is written to the
   * right place once the form is successfully
   * submitted.
   */
  formUrl?: string;

  /**
   * A flag indicating whether or not something should be gated at all.
   */
  gated: boolean;

  /**
   * The campaign name
   */
  lastTouchCampaign?: string;

  /**
   * The title of the content item, eg. a video title.
   */
  lastTouchOfferName: string;

  /**
   * The type of content, eg. "Marketing Video".
   */
  lastTouchOfferType: string;

  /**
   * The key of the local storage item that will
   * be set to `true` once the user successfully
   * submits the gating form.
   *
   * NOTE: If you override the default `formUrl`,
   * you must also override this, or at least
   * make sure that it's pointing to the piece
   * of local storage that you expect.
   */
  localStorageKey?: string;

  /**
   * The product, of which image will be shown on the form
   */
  product?: MarketoFormProductParam;
}

/**
 * A hook that gates content by sending users to Marketo
 * forms.
 */
const useGate = ({
  closeUrl,
  formUrl = "/welcome",
  gated,
  lastTouchCampaign,
  lastTouchOfferName,
  lastTouchOfferType,
  localStorageKey = NO_GATE_KEY,
  product = "chromium",
}: Args) => {
  const router = useRouter();
  const [noGate] = useLocalStorage(localStorageKey, false);
  const restricted = gated && !noGate;

  /*
  Disable gating everywhere except production,
  because it's extremely annoying for internal
  users to have to keep filling out forms.
  */
  if (process.env.VERCEL_ENV !== "production") {
    return { unrestricted: true };
  }

  if (typeof window !== "undefined" && restricted) {
    // No flag in local storage. Redirect to gating form.
    // For some reason, if we pass this as a raw hash to the next router,
    // the query params do not show up in `router.query`
    // when we need to access it later
    const query = qs.stringify(
      {
        closeUrl,
        cnm: lastTouchCampaign || null,
        lastTouchOfferName,
        lastTouchOfferType,
        product: product || null,
        redirectUrl: router.asPath,
      },
      { skipNulls: true },
    );

    router.push({
      pathname: formUrl,
      query,
    });
  }

  return { unrestricted: !restricted };
};

export default useGate;
