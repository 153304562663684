import {
  colorSteelMedium,
  mediaPhoneOnly,
  mediaTabletLandscape,
  mediaTabletPortrait,
  spacing4,
  spacing8,
  spacing16,
  spacing24,
  spacing32,
  spacing48,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../Anchor";
import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";
import type { AnalysisGuideTopic, AnalysisGuideType } from "./types";
import { getTypeIcon } from "./utils";

interface Props {
  slug: string;
  summary?: string;
  title: string;
  topic: AnalysisGuideTopic;
  type: AnalysisGuideType;
  updated?: string;
}

const GuideCard = ({ slug, summary, title, topic, type, updated }: Props) => {
  return (
    <Anchor href={`/analysis-guides/${slug}`}>
      <Card
        css={css`
          display: grid;
          padding: ${spacing16} ${spacing24};
          text-decoration: none;
          &:hover {
            .guide-card-link {
              opacity: 1;
            }
          }

          @media (min-width: ${mediaPhoneOnly}) {
            padding: ${spacing24} ${spacing48} ${spacing32};
          }
        `}
        elevate={true}
        hoverable={true}
        outline={true}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: ${spacing4};
            white-space: nowrap;
          `}
        >
          <div
            css={css`
              column-gap: ${spacing48};
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <Text as={"span"} color={"blue"} size={"xsmall"} weight={"medium"}>
              {topic}
            </Text>
            <div
              css={css`
                color: ${colorSteelMedium};
                display: grid;
                grid-template-columns: 20px 1fr;
                column-gap: ${spacing8};
              `}
            >
              <Icon
                color={"inherit"}
                size={"100%"}
                source={getTypeIcon(type)}
              />
              <Text
                as={"span"}
                color={"inherit"}
                size={"xsmall"}
                weight={"medium"}
              >
                {type}
              </Text>
            </div>
          </div>
          <div>
            <Text
              as={"span"}
              color={"steelDark"}
              size={"xxsmall"}
              weight={"regular"}
            >
              {updated}
            </Text>
          </div>
        </div>

        <Heading
          as={"h2"}
          css={css`
            margin-bottom: ${spacing4};
          `}
          size={"large"}
        >
          {title}
        </Heading>
        <Text as={"p"} color={"midgray"} size={"small"}>
          {summary}
        </Text>

        <div
          css={css`
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: ${mediaTabletLandscape}) {
              display: none;
            }
          `}
        >
          <div
            className={"guide-card-link"}
            css={css`
              opacity: 0;
              transition: opacity 0.2s ease-in;

              @media (max-width: ${mediaTabletPortrait}) {
                opacity: 1;
              }
            `}
          >
            <Icon color={"blue"} size={"17px"} source={"nav-right"} />
          </div>
        </div>
      </Card>
    </Anchor>
  );
};

export default GuideCard;
