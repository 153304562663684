import type { Section } from "@10xdev/cms/definitions/blocks/section";
import { css } from "@emotion/react";
import { Fragment } from "react";

import type { FlexProps } from "../../../Flex";
import Flex from "../../../Flex";
import { getCard } from "../Cards/RenderCard";
import type { Card, CardType } from "../Cards/types";
import type { StyleProps } from "../types";
import { getContent, getStyles } from "../utils";
export interface Props {
  cards: {
    data: Card;
    type: CardType;
  }[];
  content: Section[];
  layout?: FlexProps;
  styles?: StyleProps;
}

const FlexGroup = ({ cards, content, layout, styles }: Props) => {
  const cssString = styles ? getStyles(styles) : "";
  return (
    <Flex
      {...layout}
      css={css`
        ${cssString}
        > * {
          align-self: flex-start;
        }
      `}
    >
      {content?.map((item, index) => {
        return (
          <Fragment key={`${item.type}-${index}`}>{getContent(item)}</Fragment>
        );
      })}
      {cards?.map((item, index) => {
        return (
          <Fragment key={`${item.type}-${index}`}>{getCard(item)}</Fragment>
        );
      })}
    </Flex>
  );
};

export default FlexGroup;
