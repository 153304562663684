import type { GetAllEventsQuery } from "@10xdev/types/__generated__/graphql-types";
import { colorSteelDarker, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import Sidebar from "./Sidebar";
import {
  getEventColor,
  getEventIcon,
  getTruncatedEventDescription,
} from "./utils";

interface Props {
  event: NonNullable<GetAllEventsQuery["events"]>[0];
}

/**
 * A list of event details, showing online status,
 * location, and times.
 */
const EventCardContent: FunctionComponent<Props> = ({ event }) => {
  const { category, name, teaser } = event;

  const color = category && getEventColor(category);
  const EventCategoryIcon = category && getEventIcon(category);

  return (
    <div
      css={css`
        border-left: 6px solid ${color};
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        min-height: 260px;
        overflow: hidden;
        padding: 24px 32px;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          display: none;
        }
      `}
    >
      <div
        css={css`
          margin-right: 24px;
          width: 18px;
        `}
      >
        {EventCategoryIcon ? <EventCategoryIcon /> : null}
      </div>

      <div
        css={css`
          margin-right: 48px;
          width: 440px;
        `}
      >
        {color && (
          <Text
            as={"h1"}
            color={"inherit"}
            css={css`
              color: ${color};
              margin-bottom: 16px;
            `}
            size={"medium"}
            weight={"semibold"}
          >
            {category?.label || ""}
          </Text>
        )}
        <Text
          as={"h2"}
          css={css`
            margin-bottom: 16px;
          `}
          size={"large"}
          weight={"semibold"}
        >
          {name}
        </Text>

        {teaser ? (
          <Text
            as={"p"}
            css={css`
              color: ${colorSteelDarker};
              margin-bottom: 16px;
            `}
            size={"small"}
          >
            {getTruncatedEventDescription(teaser)}
          </Text>
        ) : null}
      </div>

      <Sidebar event={event} />
    </div>
  );
};

export default EventCardContent;
