import {
  borderRadiusMedium,
  borderStandard,
  colorBlueMedium,
  colorRed,
  colorSteelDarkest,
  colorSteelLight,
  colorSteelLightest,
  colorSteelMedium,
  fontFamilyBase,
  fontSizeMedium,
  fontWeightRegular,
  sizeMedium,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FormikErrors } from "formik";
import type { ChangeEvent, FocusEvent, FunctionComponent } from "react";

import ErrorLabel from "../Form/ErrorLabel";

interface Props {
  className?: string;
  /** Activates or deactivates the input. */
  disabled?: boolean;

  /** An error message indicating failed validation. */
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];

  /**
   * A unique identifier that enables the input to
   * be associated with a label for accessibility.
   */
  id?: string;

  /** The maximum number of characters permitted in the input. */
  maxLength?: number;

  /** A string used to identify this input during form submission. */
  name?: string;

  /** A callback to be invoked when the input loses focus. */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;

  /** A callback to be invoked when the contents of the input change. */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;

  /** Placeholder text to display when the input is empty. */
  placeholder?: string;

  /** Prevents the parent form from being submitted if this input is empty. */
  required?: boolean;

  /** The sub-type of this text input. */
  type?: "text" | "email" | "tel";

  /** The current value of the input. */
  value?: string;
}

/**
 * A generic text input. Implements a subset of
 * `<input type="text" />`, for use with Formik.
 */
const TextInput: FunctionComponent<Props> = ({
  className = "",
  disabled,
  error,
  id,
  maxLength,
  name,
  onBlur,
  onChange,
  placeholder,
  type = "text",
  value,
}) => {
  return (
    <>
      <input
        className={className}
        css={css`
          border: ${borderStandard};
          border-color: ${error ? colorRed : null};
          border-radius: ${borderRadiusMedium};
          box-sizing: border-box;
          color: ${colorSteelDarkest};
          font-family: ${fontFamilyBase};
          font-size: ${fontSizeMedium};
          font-weight: ${fontWeightRegular};
          height: ${sizeXxlarge};
          padding: 0 ${sizeMedium};
          width: 100%;

          :disabled {
            background-color: ${colorSteelLightest};
            color: ${colorSteelMedium};
            pointer-events: none;
          }

          :focus {
            border-color: ${error ? colorRed : colorBlueMedium};
            outline: none;
          }

          ::placeholder {
            color: ${colorSteelLight};
            font-weight: ${fontWeightRegular};
          }

          :disabled::placeholder {
            color: ${colorSteelMedium};
          }
        `}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={type || "text"}
        value={value || ""}
      />

      {error ? <ErrorLabel error={error} htmlFor={id} /> : null}
    </>
  );
};

export default TextInput;
