import { css } from "@emotion/react";

import type { TextSize } from "@10xdev/types";
import Anchor from "../Anchor";
import type { SectionLink } from "../Blocks/Section/types";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  gap?: string;
  links: SectionLink[];
  size?: TextSize;
}

const Links = ({ gap, links, size }: Props) => {
  return (
    <>
      {links.map((link) => {
        return (
          <Anchor
            color={link.color}
            css={css`
              align-items: center;
              display: flex;
              ${gap ? gap : "gap: 0.75rem"};
            `}
            hoverColor={link.hoverColor}
            href={link.url}
            key={link.url}
          >
            <Text
              as={"div"}
              color={"inherit"}
              responsive={true}
              size={size || "medium"}
              weight={"semibold"}
            >
              {link.label}
            </Text>
            <Icon
              color={"inherit"}
              css={css`
                flex-shrink: 0;
              `}
              size={"11.5px"}
              source={link.icon}
              yPos={"1px"}
            />
          </Anchor>
        );
      })}
    </>
  );
};

export default Links;
