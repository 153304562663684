import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";

const MastheadHeading: FunctionComponent = () => {
  return (
    <div className={"Copy"}>
      <Heading
        as={"h1"}
        color={"white"}
        css={css`
          margin-bottom: 0.25rem;
        `}
        responsive={true}
        size={"xxlarge"}
      >
        {"10x Genomics Blog"}
      </Heading>
      <Heading
        as={"h2"}
        color={"gray"}
        css={css`
          margin-bottom: 0;
        `}
        responsive={true}
        size={"medium"}
        weight={"regular"}
      >
        {"Celebrating the mastery of biology"}
      </Heading>
    </div>
  );
};

export default MastheadHeading;
