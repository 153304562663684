import { css } from "@emotion/react";

import Icon from "../../Icon";
import Text from "../../Text";
import BaseCallout from "../UserGuides/Callout";

interface Props {
  children: string;
}

export default function Callout({ children }: Props) {
  return (
    <BaseCallout
      css={css`
        margin-bottom: 24px;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          margin-bottom: 0.5rem;
        `}
      >
        <Icon
          color={"midgray"}
          css={css`
            margin-right: 15px;
          `}
          size={"1rem"}
          source={"warning"}
        />
        <Text
          as={"div"}
          color={"steelDarkest"}
          size={"medium"}
          weight={"semibold"}
        >
          {"Important"}
        </Text>
      </div>

      <Text as={"div"} color={"steelDarkest"} size={"small"} weight={"regular"}>
        {children}
      </Text>
    </BaseCallout>
  );
}
