import {
  borderRadiusMedium,
  colorGrayLightest,
  colorSteelDarker,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import NavLink from "../../SoftwareDownload/DownloadCard/NavLink";
import type { Link } from "../../SoftwareDownload/DownloadCard/types";
import Text from "../../Text";

interface Props {
  highlightItems: Array<{
    description: string;
  }>;
  links?: Link[] | null;
  title: string | null;
}

const DownloadHighlights = ({ highlightItems, links, title }: Props) => {
  return (
    <div
      css={css`
        background: ${colorGrayLightest};
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        padding: 1.5rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          max-width: 100%;
        }
      `}
    >
      {title ? (
        <Text as={"h4"} size={"medium"} weight={"semibold"}>
          {title}
        </Text>
      ) : null}
      <ul
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          list-style: none;
          margin: 0;
          padding-left: 1rem;
        `}
      >
        {highlightItems.map(({ description }) => {
          return (
            <li
              css={css`
                list-style: disc;
                &::marker {
                  color: ${colorSteelDarker};
                  font-size: 18px;
                }
              `}
              key={description}
            >
              <Text as={"span"} size={"small"} weight={"regular"}>
                {description}
              </Text>
            </li>
          );
        })}
      </ul>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        {links &&
          links.map((link) => {
            return link.url && link.label ? (
              <NavLink key={link.label} link={link} />
            ) : null;
          })}
      </div>
    </div>
  );
};

export default DownloadHighlights;
