import { colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

/**
 * An optional wrapper for the tab list. Using this wrapper
 * will allow you to add additional items before or after the TabList.
 */
export const TabHeader = ({ children }: Props) => {
  return (
    <div
      css={css`
        align-items: center;
        border-bottom: 1px solid ${colorSteelLighter};
        display: flex;
        justify-content: space-between;
      `}
    >
      {children}
    </div>
  );
};
