import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { Fragment } from "react";

import Anchor from "../Anchor";
import Text from "../Text";

interface Props {
  products: {
    name: string | null;
    slug: string;
  }[];
  serviceTypes: string[];
}

const LinkList: FunctionComponent<Props> = ({
  products,
  serviceTypes,
}: Props) => {
  return (
    <div
      css={css`
        height: 100%;
      `}
    >
      {serviceTypes?.length > 0 &&
        serviceTypes.map((serviceType) => {
          return (
            <Text
              as={"span"}
              color={"steelDarker"}
              key={serviceType}
              size={"small"}
              weight={"medium"}
            >
              {`${serviceType}${products.length > 0 ? ", " : ""}`}
            </Text>
          );
        })}
      {products.map((product, index) => {
        return (
          <Fragment key={product.slug}>
            {index > 0 && (
              <Text
                as={"span"}
                color={"steelDarker"}
                size={"small"}
                weight={"semibold"}
              >
                {", "}
              </Text>
            )}
            <Anchor
              color={"blue"}
              hoverColor={colorBlueDark}
              href={`/products/${product.slug}`}
              target={"_blank"}
            >
              <Text
                as={"span"}
                color={"inherit"}
                size={"small"}
                weight={"medium"}
              >
                {product.name}
              </Text>
            </Anchor>
          </Fragment>
        );
      })}
    </div>
  );
};

export default LinkList;
