import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-core";

import ActionButton from "./Filter/ActionButton";

interface ConnectedClearAllSelectionsProps extends CurrentRefinementsProvided {
  onClearAll?: () => void;
}
const ConnectedClearAllSelections = ({
  items,
  onClearAll,
  refine,
}: ConnectedClearAllSelectionsProps) => {
  return items.length > 0 ? (
    <ActionButton
      onClick={() => {
        refine(items);
        onClearAll?.();
      }}
      text={"Clear all"}
    />
  ) : null;
};
export default connectCurrentRefinements(ConnectedClearAllSelections);
