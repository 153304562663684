import type { MDXRemoteSerializeResult } from "next-mdx-remote";
import { MDXRemote } from "next-mdx-remote";

import MDXComponents from "../MDX/MDXComponents";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  container?: ContainerProps;
  content: MDXRemoteSerializeResult;
}

const Mdx = ({ container, content }: Props) => {
  return (
    <Container {...container}>
      <MDXRemote {...content} components={MDXComponents} />
    </Container>
  );
};

export default Mdx;
