import type { TextSize } from "@10xdev/types";
import { colorSteelDarker, colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Text from "../../Text";
import StepLinks from "./StepLinks";

interface Props {
  optionalStep?: {
    description?: ReactNode;
    links?: {
      href: string;
      label: string;
    }[];
    title: string;
  };
  steps: Array<{
    description: ReactNode;
    links?: {
      href: string;
      label: string;
    }[];
    title: string;
  }>;
  title?: {
    size?: TextSize;
    text: string;
  };
}

const SoftwareWorkflow = ({ title, steps, optionalStep }: Props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        max-width: 800px;
      `}
    >
      {title ? (
        <Text as={"h3"} size={title.size || "large"} weight={"semibold"}>
          {title.text}
        </Text>
      ) : null}
      <ol
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {steps.map((step, index) => (
          <li
            css={css`
              border-bottom: 1px solid ${colorSteelLighter};
              display: flex;
              flex-direction: column;
              gap: 1rem;
              padding: 1.5rem 0;
            `}
            key={index}
          >
            <div
              css={css`
                display: flex;
                gap: 0.75rem;
                align-items: center;
              `}
            >
              <Text
                as={"div"}
                color={"white"}
                css={css`
                  background-color: ${colorSteelDarker};
                  border-radius: 50%;
                  width: 1.75rem;
                  height: 1.75rem;
                  text-align: center;
                `}
                size={"medium"}
                weight={"semibold"}
              >
                {index + 1}
              </Text>
              <Text as={"h4"} size={"medium"} weight={"semibold"}>
                {step.title}
              </Text>
            </div>
            <Text as={"p"} size={"small"} weight={"regular"}>
              {step.description}
            </Text>

            {step.links && step.links.length > 0 ? (
              <StepLinks stepLinks={step.links} />
            ) : null}
          </li>
        ))}
      </ol>
      {optionalStep ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding-top: 2.125rem;
          `}
        >
          <Text as={"h4"} size={"large"} weight={"semibold"}>
            {optionalStep.title}
          </Text>
          {optionalStep.description ? (
            <Text as={"p"} size={"small"} weight={"regular"}>
              {optionalStep.description}
            </Text>
          ) : null}
          {optionalStep.links && optionalStep.links.length > 0 ? (
            <StepLinks stepLinks={optionalStep.links} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default SoftwareWorkflow;
