import { layoutWidth720, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Image from "../Image";
import Paragraph from "../Paragraph";
import Text from "../Text";

interface Props {
  benefits: Array<any>;
}

const CareerBenefitsList: FunctionComponent<Props> = ({ benefits }) => {
  return (
    <div
      css={css`
        max-width: ${layoutWidth720};
        padding: 24px 0 0 0;
        width: 100%;
      `}
    >
      <ul
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 0;
          padding: 0;
          width: 100%;
        `}
      >
        {benefits.map((benefit) => {
          const { icon, title, description } = benefit;
          return (
            <li
              css={css`
                list-style: none;
                margin-bottom: 1rem;
                width: 320px;

                @media (max-width: ${mediaTabletLandscape}) {
                  width: 100%;
                }
              `}
              key={title}
            >
              <Image
                alt={title}
                css={css`
                  margin-bottom: 1rem;
                `}
                loading={"lazy"}
                src={icon}
              />
              <Text
                as={"h4"}
                css={css`
                  margin-bottom: 0.5rem;
                `}
                size={"medium"}
                weight={"semibold"}
              >
                {title}
              </Text>
              <Paragraph size={"medium"}>{description}</Paragraph>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CareerBenefitsList;
