import { css } from "@emotion/react";
import { startCase } from "lodash-es";
import type { FunctionComponent } from "react";
import { Fragment } from "react";

import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  path: Array<string | undefined>;
}

const Breadcrumb: FunctionComponent<Props> = ({ path }) => {
  return (
    <>
      {path.map((segment, index) => {
        return (
          <Fragment key={segment}>
            <Text
              as={"span"}
              color={"gray"}
              css={css`
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;
              `}
              size={"small"}
              weight={"medium"}
            >
              {startCase(segment)}
            </Text>
            {index < path.length - 1 ? (
              <Icon
                color={"gray"}
                css={css`
                  margin-right: 0.5rem;
                `}
                size={"9px"}
                source={"nav-right"}
              />
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};

export default Breadcrumb;
