import { kebabCase } from "lodash-es";

/**
 * When no ID prop is passed, the component can use
 * this function to generate an id from the title.
 */
export const getSectionIdFromTitle = (
  id: string | undefined,
  title: string,
): string => id || kebabCase(title);
