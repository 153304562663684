import { css } from "@emotion/react";

import Text from "../../Text";

interface Props {
  summary: string[];
}

const Summary = ({ summary }: Props) => {
  return (
    <ul
      css={css`
        list-style-type: disc;
        margin: 0;
        padding: 0 0 0 2rem;
      `}
    >
      {summary.map((item) => {
        return (
          <Text as={"li"} key={item} size={"small"}>
            {item}
          </Text>
        );
      })}
    </ul>
  );
};

export default Summary;
