import { mediaPhoneOnly, spacing16 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { fetch } from "cross-fetch";
import { useFormik } from "formik";
import { useState } from "react";

import Button from "../../Button";
import Text from "../../Text";
import TextInput from "../../TextInput";

interface SlideSerialDownloadProps {
  /** optionally specify base url for download.  */
  baseUrl?: string;
  className?: string;
}

/**
 * Form component that allows users to download a slide's
 * gpr file by entering a slide serial number.
 */
const SlideSerialDownload = ({
  baseUrl = "/api/slide-serial-download",
  className,
}: SlideSerialDownloadProps) => {
  const isValidHDSerialNumber = (serialNumber: string) =>
    /^[hH]1-[a-zA-Z0-9]{7}$/.test(serialNumber);
  const isValidSDSerialNumber = (serialNumber: string) =>
    /^[vV][a-zA-Z0-9]{5}-[0-9]{3}$/.test(serialNumber);
  const [isDownloading, setIsDownloading] = useState(false);

  const { handleBlur, handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: { serialNumber: "" },
    onSubmit: async (values, { setErrors }) => {
      try {
        setIsDownloading(true);
        const serialNumber = values.serialNumber.toUpperCase();
        const isVisiumHDSlide = serialNumber.startsWith("H1");
        const response = await fetch(`${baseUrl}/${serialNumber}`);
        if (response.status !== 200) {
          setErrors({
            serialNumber: "Invalid serial number.",
          });
          return;
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${serialNumber}${isVisiumHDSlide ? ".vlf" : ".gpr"}`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);

        // Revoke the object URL to avoid memory leaks
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch {
        setErrors({
          serialNumber: "Something went wrong.",
        });
      } finally {
        setIsDownloading(false);
      }
    },
    validate: (values) => {
      const errors: { serialNumber?: string } = {};
      if (!values.serialNumber) {
        errors.serialNumber = "Serial number is required.";
      } else if (
        !isValidSDSerialNumber(values.serialNumber) &&
        !isValidHDSerialNumber(values.serialNumber)
      ) {
        errors.serialNumber = "Must be in VXXXXX-123 or H1-XXXXXXX format.";
      }
      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <form
      className={className}
      css={css`
        display: flex;
        gap: 0.5rem;
        margin-bottom: ${spacing16};

        @media (max-width: ${mediaPhoneOnly}) {
          flex-direction: column;
        }
      `}
      onSubmit={handleSubmit}
    >
      <div
        css={css`
          flex: 1;
          max-width: 272px;
        `}
      >
        <TextInput
          css={css`
            width: 100%;
          `}
          error={errors.serialNumber}
          id={"serialNumber"}
          name={"serialNumber"}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={"Enter a serial number"}
          type={"text"}
          value={values.serialNumber || ""}
        />
      </div>
      <Button
        background={"white"}
        border={true}
        css={css`
          flex: 0 0 auto;
          width: 120px;
          max-width: none;
        `}
        disabled={isDownloading}
        type={"submit"}
      >
        <Text
          as={"span"}
          color={"blue"}
          css={css`
            pointer-events: none;
          `}
          size={"small"}
          weight={"medium"}
        >
          {isDownloading ? "Please wait.." : "Download"}
        </Text>
      </Button>
    </form>
  );
};

export default SlideSerialDownload;
