import { borderRadiusMedium, mediaTabletPortrait } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import type { FunctionComponent, ReactNode } from "react";
import { createContext, useState } from "react";

import { handleClick } from "./utils";

interface Props {
  as?: keyof JSX.IntrinsicElements;
  background?: string;
  children?: ReactNode;
  direction?: string;
  elevate?: boolean;
  height?: string;
  hoverable?: boolean;
  href?: string;
  outline?: boolean;
  padding?: string;
}

export const ActiveContext = createContext(false);

const hoverCss = css`
  @media (min-width: ${mediaTabletPortrait}) {
    &:hover {
      border-color: rgba(68, 89, 121, 0.06);
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      position: relative;
      z-index: 1;
    }
  }
`;

const Tile: FunctionComponent<Props> = ({
  as = "li",
  background = "white",
  children,
  direction = "column",
  elevate = false,
  href,
  hoverable = false,
  height,
  outline = true,
  padding = "0px",
}) => {
  const [isActive, setIsActive] = useState(false);

  const tileCss = css`
    background: ${background};
    border: solid 1px;
    border-color: ${outline ? "rgba(68, 89, 121, 0.06)" : "transparent"};
    border-radius: ${borderRadiusMedium};
    box-shadow: ${elevate ? "0px 2px 5px rgba(0, 0, 0, 0.06)" : "none"};
    box-sizing: border-box;
    display: flex;
    flex-direction: ${direction};
    min-height: ${height || "auto"};
    position: relative;
    transition: all 0.25s ease;
  `;

  const Element = styled(as)([
    tileCss,
    hoverable && isActive ? hoverCss : undefined,
  ]);

  const router = useRouter();

  const onClick = href ? () => handleClick(router, href) : undefined;
  return (
    <Element
      onBlur={() => setIsActive(false)}
      onFocus={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onMouseOver={() => setIsActive(true)}
    >
      <button
        css={css`
          background: none;
          border: none;
          cursor: ${href ? "pointer" : "auto"};
          display: flex;
          flex: 1 1 auto;
          flex-direction: ${direction};
          margin: 0;
          outline: none;
          padding: ${padding || "0"};
          text-align: left;
        `}
        onClick={onClick}
      >
        <ActiveContext.Provider value={isActive}>
          {children}
        </ActiveContext.Provider>
      </button>
    </Element>
  );
};

export default Tile;
