import type { SearchState } from "react-instantsearch-core";
import { Configure, Index } from "react-instantsearch-dom";

import Results from "./Connectors/Results";
import type { IndexWithHits } from "./constants";
import PageLink from "./PageLink";

interface Props {
  active: { id: string };
  expanded: boolean;
  index: IndexWithHits;
  onHits: () => void;
  searchState: SearchState;
}

function IndexHits({ expanded, index, onHits, searchState }: Props) {
  const { filters, hitComponent, hitsPerPage, indexName, pageLink, title } =
    index;

  const hitsCount = hitsPerPage * (expanded ? 2 : 1);

  return (
    <Index indexName={indexName}>
      <Configure filters={filters} hitsPerPage={hitsCount} />
      <Results
        component={hitComponent}
        expanded={expanded}
        hitsPerPage={hitsCount}
        onHits={onHits}
        title={title}
      >
        {pageLink ? (
          <PageLink link={pageLink} searchState={searchState} />
        ) : null}
      </Results>
    </Index>
  );
}

export default IndexHits;
