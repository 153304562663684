import {
  colorBlueMedium,
  colorSteelDark,
  colorSteelDarker,
  colorSteelDarkest,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { Tab as HeadlessTab } from "@headlessui/react";
import { Fragment, type ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const tabCss = css`
  &.Active {
    border-bottom: 6px solid ${colorBlueMedium};
    color: ${colorSteelDarkest};
  }

  :not(.Active):hover {
    color: ${colorSteelDarker};
  }

  align-items: center;
  background: none;
  border-bottom: none;
  border: none;
  color: ${colorSteelDark};
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 38px;
  margin: 0;
  outline: none;
  padding-bottom: 8px;
  padding: 0;
  white-space: nowrap;
`;

export const Tab = ({ children }: Props) => {
  return (
    <HeadlessTab as={Fragment}>
      {({ selected }) => (
        <div className={selected ? "Active" : ""} css={tabCss}>
          {children}
        </div>
      )}
    </HeadlessTab>
  );
};
