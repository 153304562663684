import {
  borderRadiusMedium,
  borderStandard,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import PlayButton from "../PlayButton";
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from "./constants";
import { getThumbnailUrlWithSizes } from "./utils";

const VideoThumbnail = ({
  active,
  hideBorder = false,
  thumbnailUrl,
}: {
  active: boolean;
  borderRadius?: string;
  hideBorder?: boolean;
  thumbnailUrl: string;
}) => {
  const thumbnailResized = getThumbnailUrlWithSizes(thumbnailUrl);
  return (
    <div
      css={css`
        box-sizing: border-box;
        display: flex;
      `}
      role={"button"}
      tabIndex={-1}
    >
      <div
        css={css`
          align-items: center;
          background-image: url(${thumbnailResized});
          background-repeat: no-repeat;
          background-size: contain;
          border: ${hideBorder ? "none" : borderStandard};
          border-radius: ${borderRadiusMedium};
          box-shadow: ${active ? "0px 6px 12px 0px #1530571F" : "none"};
          display: flex;
          height: ${THUMBNAIL_HEIGHT}px;
          justify-content: center;
          overflow: hidden;
          position: relative;
          min-width: ${THUMBNAIL_WIDTH}px;

          @media (max-width: ${mediaTabletLandscape}) {
            border: none;
            border-radius: 0;
            box-shadow: none;

            ::before {
              background: linear-gradient(
                0deg,
                rgba(21, 48, 87, 0.06),
                rgba(21, 48, 87, 0.06)
              );
              content: "";
              height: 100%;
              left: 0;
              opacity: 1;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }
        `}
      >
        <PlayButton
          active={active}
          iconSize={14}
          iconXPos={"1.5px"}
          iconYPos={"0.5px"}
          size={32}
        />
      </div>
    </div>
  );
};

export default VideoThumbnail;
