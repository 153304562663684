import type { GetAllEventsQuery } from "@10xdev/types/__generated__/graphql-types";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import EventCardContent from "./EventCardContent";
import EventCardContentMobile from "./EventCardContentMobile";

interface Props {
  event: NonNullable<GetAllEventsQuery["events"]>[0];
}

/**
 * A card that displays a summary view of an event.
 */
const EventCard: FunctionComponent<Props> = ({ event }) => {
  const card = (
    <Card
      as={"article"}
      css={css`
        display: block;
        margin: 0;
      `}
      elevate={true}
      hoverable={true}
      outline={true}
    >
      <EventCardContent event={event} />
      <EventCardContentMobile event={event} />
    </Card>
  );

  return event.registrationUrl ? (
    <Anchor
      href={event.registrationUrl}
      rel={"noopener, noreferrer"}
      target={"_blank"}
    >
      {card}
    </Anchor>
  ) : (
    card
  );
};

export default EventCard;
