import { css } from "@emotion/react";

import Grid from "../Grid";
import Text from "../Text";

interface Props {
  label?: string;
  title?: string;
  arcadeId: string;
}

const Arcade = ({ label, title, arcadeId }: Props) => {
  const url = `https://demo.arcade.software/${arcadeId}?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true`;
  return (
    <Grid
      css={css`
        margin-bottom: 2.5rem;
      `}
      gap={"1rem"}
    >
      <div>
        {label && (
          <Text
            as={"div"}
            css={css`
              margin-bottom: 0.25rem;
            `}
            color={"steelDark"}
            size={"small"}
            weight={"semibold"}
          >
            {label}
          </Text>
        )}
        {title && (
          <Text as={"div"} size={"medium"} weight={"semibold"}>
            {title}
          </Text>
        )}
      </div>
      <div
        css={css`
          aspect-ratio: 1.777;
          display: flex;
          justify-content: center;
        `}
      >
        <iframe
          src={url}
          title={title}
          loading={"lazy"}
          allowFullScreen={true}
          allow={"clipboard-write"}
          css={css`
            width: 100%;
            height: 100%;
            border: none;
          `}
        />
      </div>
    </Grid>
  );
};

export default Arcade;
