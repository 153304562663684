import type { ComponentProps } from "react";
import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-core";

import CollapsibleMenu from "./Filter/CollapsibleMenu";

interface ConnectedCollapsibleMenuProps
  extends CurrentRefinementsProvided,
    Omit<ComponentProps<typeof CollapsibleMenu>, "showIndicator"> {
  attribute: string;
  children: React.ReactNode;
}

const ConnectedCollapsibleMenu = ({
  attribute,
  children,
  items,

  refine,

  query,
  ...props
}: ConnectedCollapsibleMenuProps) => {
  const selected = items.find((item) => item.attribute === attribute);
  return (
    <CollapsibleMenu {...props} showIndicator={!!selected?.value}>
      {children}
    </CollapsibleMenu>
  );
};

export default connectCurrentRefinements(ConnectedCollapsibleMenu);
