import { format } from "date-fns";

export const formatDateAndCategory = (
  timestamp: string | undefined,
  researchAreas: string[] | undefined | null,
): string => {
  const date = timestamp ? format(new Date(timestamp), "MMM d, yyyy") : "";
  const researchAreasCopy =
    researchAreas && researchAreas.length > 0
      ? researchAreas
          .map((researchArea) => {
            return `/ ${researchArea} `;
          })
          .join("")
      : "";

  return `${date} ${researchAreasCopy}`;
};

export const transformImageSize = (
  image: string | null | undefined,
  size: string,
): string => {
  return image?.split("upload/")?.join(`upload/${size},`) || "";
};
