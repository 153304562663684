import { formatDuration } from "date-fns";

import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from "./constants";

/**
 * A thin wrapper around date-fns formatDuration
 * for formatting video durations represented in
 * seconds as hours and minutes.
 */
export const getFormattedDuration = (duration: number): string => {
  // https://date-fns.org/v2.15.0/docs/formatDuration
  return formatDuration(
    {
      hours: Math.floor(duration / 3600),
      minutes: Math.floor((duration % 3600) / 60),
    },
    {
      format: ["hours", "minutes"],
    },
  )
    .replace("hour", "hr")
    .replace("minute", "min");
};

/**
 * Returns the passed Wistia thumbnail URL with
 * resizing parameters appended.
 *
 * We double-resolution the image because it looks
 * _terrible_ at single resolution.
 */
export const getThumbnailUrlWithSizes = (url: string): string => {
  const urlObject = new URL(url);

  urlObject.searchParams.set(
    "image_crop_resized",
    `${THUMBNAIL_WIDTH * 2}x${THUMBNAIL_HEIGHT * 2}`,
  );

  return urlObject.toString();
};
