const { stringify } = require("qs");

/**
 * Algolia passes dead params that clutter up the URL.
 * Do ugly stripping of them to keep the URL pretty.
 */
export const getHref = (pathname: string, query: any = {}) => {
  const { refinementList, toggle } = query;

  const strippedQuery = {
    ...query,
  };

  if (refinementList) {
    Object.keys(refinementList).forEach((key) => {
      const value = refinementList[key];
      if (!value) {
        delete strippedQuery.refinementList[key];
      }
    });
  }

  if (toggle && !toggle.selected) {
    delete strippedQuery.toggle;
  }

  const stringifiedQuery = stringify(strippedQuery);
  const querySymbol = stringifiedQuery ? "?" : "";
  return `${pathname}${querySymbol}${stringifiedQuery}`;
};
