import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { StatsProvided } from "react-instantsearch-core";
import { connectStats } from "react-instantsearch-dom";

import Text from "../../Text";

interface Props extends StatsProvided {
  currentPage: number;
  hitsPerPage: number;
}

const Stats: FunctionComponent<Props> = ({
  currentPage,
  hitsPerPage,
  nbHits,
}) => {
  const pageCount = Math.ceil(nbHits / hitsPerPage);
  const getCount = () => {
    if (currentPage === pageCount) {
      return `${nbHits} of ${nbHits} results`;
    } else if (nbHits) {
      return `${currentPage * hitsPerPage} of ${nbHits} results`;
    } else {
      return `0 Results`;
    }
  };

  return (
    <Text
      as={"p"}
      color={"midgray"}
      css={css`
        margin-right: 1rem;
      `}
      responsive={true}
      size={"small"}
    >
      {getCount()}
    </Text>
  );
};

export default connectStats(Stats);
