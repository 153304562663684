import { css } from "@emotion/react";
import { memo, useCallback, useMemo, useState } from "react";
import type { RefinementListProvided } from "react-instantsearch-core";
import { connectRefinementList } from "react-instantsearch-core";

import Icon from "../Icon";
import ConnectedClearSelection from "../AlgoliaConnected/ConnectedClearSelection";
import ConnectedCollapsibleMenu from "./ConnectedCollapsibleMenu";
import { FILTER_MENU_ITEMS_DISPLAY_LIMIT } from "./constants";
import ActionButton from "./Filter/ActionButton";
import MultiSelectMenu from "./Filter/MultiSelectMenu";

interface ConnectedMultiSelectMenuProps extends RefinementListProvided {
  expanded?: boolean;
  id: string;
  label: string;
  onClearSelection?: () => void;
  onExpand?: (expanded: boolean) => void;
  onSelect?: (selected: { id: string; value: (string | number)[] }) => void;
}
const ConnectedMultiSelectMenu = ({
  currentRefinement,
  expanded,
  id,
  items,
  label,
  onClearSelection,
  onExpand,
  onSelect,
  refine,
}: ConnectedMultiSelectMenuProps) => {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const mappedItems = useMemo(() => {
    const itemsToDisplay = isShowingMore
      ? items
      : items.slice(0, FILTER_MENU_ITEMS_DISPLAY_LIMIT);
    return itemsToDisplay.map((item) => {
      return {
        checked: item.isRefined,
        count: item.count,
        label: item.label,
        value: item.label,
      };
    });
  }, [isShowingMore, items]);

  const handleSelect = useCallback(
    ({
      checkedValue,
      value,
    }: {
      checkedValue: string | number;
      value: (string | number)[];
    }) => {
      onSelect?.({ id, value });
      const itemValue =
        items.find((item) => item.label === checkedValue)?.value || [];
      refine(itemValue);
    },
    [id, items, onSelect, refine],
  );

  if (items.length === 0) {
    return null;
  }
  return (
    <ConnectedCollapsibleMenu
      attribute={id}
      expanded={expanded}
      key={id}
      label={label}
      onClick={onExpand}
    >
      <MultiSelectMenu
        id={id}
        items={mappedItems}
        onSelect={handleSelect}
        selectedValues={currentRefinement}
        showCount={true}
      />
      {items.length > FILTER_MENU_ITEMS_DISPLAY_LIMIT ? (
        <ActionButton
          css={css`
            text-align: left;
          `}
          onClick={() => setIsShowingMore((prev) => !prev)}
          text={isShowingMore ? "Show less" : "Show more"}
        >
          {
            <Icon
              color={"blue"}
              css={css`
                margin-left: 8px;
              `}
              size={"8px"}
              source={isShowingMore ? "nav-up" : "nav-down"}
            />
          }
        </ActionButton>
      ) : null}
      <ConnectedClearSelection
        attribute={id}
        onClearSelection={onClearSelection}
      />
    </ConnectedCollapsibleMenu>
  );
};

const arePropsEqual = (
  prevProps: ConnectedMultiSelectMenuProps,
  nextProps: ConnectedMultiSelectMenuProps,
) => {
  return (
    prevProps.expanded === nextProps.expanded &&
    prevProps.id === nextProps.id &&
    prevProps.label === nextProps.label &&
    JSON.stringify(prevProps.currentRefinement) ===
      JSON.stringify(nextProps.currentRefinement) &&
    prevProps.isFromSearch === nextProps.isFromSearch &&
    JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items)
  );
};

const memoizedConnectedMultiSelectMenu = memo(
  ConnectedMultiSelectMenu,
  arePropsEqual,
);

export default connectRefinementList(memoizedConnectedMultiSelectMenu);
