import { css } from "@emotion/react";

import Flex from "../../Flex";
import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  container?: ContainerProps;
  videos: {
    description?: string;
    src: string;
    title?: string;
  }[];
}

const Videos = ({ container, videos }: Props) => {
  const COLUMN_GAP = "4rem";
  return (
    <Container {...container}>
      {videos.map((video) => {
        return (
          <Flex
            flexDirection={{ base: "column", tabletLandscape: "row" }}
            gap={COLUMN_GAP}
            key={video.src}
          >
            {video.title || video.description ? (
              <div
                css={css`
                  flex: 1 0 29%;
                `}
              >
                {video.title ? (
                  <Text
                    as={"h3"}
                    css={css`
                      margin-bottom: 1.5rem;
                    `}
                    size={"xlarge"}
                    weight={"semibold"}
                  >
                    {video.title}
                  </Text>
                ) : null}

                {video.description ? (
                  <Text as={"p"} color={"midgray"} size={"medium"}>
                    {video.description}
                  </Text>
                ) : null}
              </div>
            ) : null}
            <div
              css={css`
                flex: 1 0 calc(80% - ${COLUMN_GAP};
              `}
            >
              <video
                autoPlay
                css={css`
                  height: auto;
                  width: 100%;
                `}
                loop
                muted
                playsInline
              >
                <source src={video.src} type={"video/mp4"} />
              </video>
            </div>
          </Flex>
        );
      })}
    </Container>
  );
};

export default Videos;
