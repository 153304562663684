import { css } from "@emotion/react";

import Anchor from "../Anchor";
import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  description: string;
  product: {
    name: string | null;
    slug: string;
  };
  url: string;
}

const ProductCardLarge = ({ description, product, url }: Props) => {
  return (
    <li
      css={css`
        list-style: none;
      `}
    >
      <Anchor
        css={css`
          display: block;
          margin: 0;
          margin-left: auto;
          margin-right: auto;
        `}
        href={url}
      >
        <Card
          css={css`
            align-items: center;
            min-height: 360px;
            max-width: 100%;
            padding: 2rem;
            text-decoration: none;
          `}
          elevate={true}
          hoverable={true}
          outline={true}
        >
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: flex-start;
            `}
          >
            <Heading
              as={"h4"}
              css={css`
                margin-bottom: 1rem;
              `}
              size={"large"}
            >
              {product.name}
            </Heading>
            <Text
              as={"p"}
              color={"midgray"}
              css={css`
                margin-bottom: 1rem;
              `}
              size={"small"}
            >
              {description}
            </Text>
            {url ? (
              <div
                css={css`
                  margin-top: auto;
                `}
              >
                <Text
                  as={"div"}
                  color={"blue"}
                  size={"small"}
                  weight={"medium"}
                >
                  {"Learn more"}
                  <Icon
                    size={"11px"}
                    source={"nav-right"}
                    xPos={"16px"}
                    yPos={"1px"}
                  />
                </Text>
              </div>
            ) : null}
          </div>
        </Card>
      </Anchor>
    </li>
  );
};

export default ProductCardLarge;
