import {
  colorBlueDark,
  colorSteelDarkest,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  className?: string;
  icon?: string | null;
  link: string;
  linkTarget?: "_blank" | "_self";
  subtext?: string | null;
  title: string;
}

const WorkflowCard: FunctionComponent<Props> = ({
  className,
  link,
  linkTarget = "_self",
  icon,
  subtext,
  title,
}) => {
  const anchorCss = css`
    align-items: center;
    border: 1px solid rgba(68, 89, 121, 0.09);
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    color: ${colorSteelDarkest};
    display: flex;
    gap: 3rem;
    max-width: 1080px;
    padding: ${icon ? "1.5rem 3rem" : "1.5rem 2.5rem"};
    width: 100%;

    :hover {
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
      color: ${colorBlueDark};
    }

    @media (max-width: ${mediaPhoneOnly}) {
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      padding: 24px;
    }
  `;

  return (
    <Anchor
      className={className}
      css={anchorCss}
      href={link}
      target={linkTarget}
    >
      {icon ? (
        <div
          css={css`
            background-image: url(${icon});
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            flex-grow: 1;
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            width: 50px;

            @media (max-width: ${mediaPhoneOnly}) {
              background-position: left;
            }
          `}
          role={"presentation"}
        />
      ) : null}
      <div>
        <Text
          as={"div"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.25rem;
          `}
          size={"medium"}
          weight={"semibold"}
        >
          {title}
        </Text>
        {subtext ? (
          <Text
            as={"p"}
            color={"steelDarker"}
            size={"small"}
            weight={"regular"}
          >
            {subtext}
          </Text>
        ) : null}
      </div>
      <Icon
        color={"blue"}
        css={css`
          flex-shrink: 0;
          margin-left: auto;

          @media (max-width: ${mediaPhoneOnly}) {
            display: none;
          }
        `}
        size={"18px"}
        source={"nav-right"}
      />
    </Anchor>
  );
};

export default WorkflowCard;
