import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Icon from "../../../Icon";
import type { IconSource } from "../../../Icon/types";
import Image from "../../../Image";
import Text from "../../../Text";

export interface Props {
  description?: string;
  image: {
    alt: string;
    src: string;
    srcSmall?: string;
  };
  list: {
    content: string[];
    marker: { gap: string; src: IconSource };
  };
  title: string;
}

const CardE = ({ description, image, list, title }: Props) => {
  return (
    <Grid gap={".5rem"}>
      <Grid gap={"1rem"}>
        <Image alt={image.alt} src={image.src} />
        <Text as={"h4"} size={"xlarge"} weight={"semibold"}>
          {title}
        </Text>
      </Grid>

      <Grid gap={"1rem"}>
        <Text as={"p"} size={"medium"} weight={"semibold"}>
          {description}
        </Text>
        <Grid>
          <ul
            css={css`
              display: grid;
              gap: 1rem;
              list-style: none;
              margin: 0;
              padding: 0;
            `}
          >
            {list.content.map((item) => {
              return (
                <Grid
                  gap={list.marker.gap}
                  gridTemplateColumns={"20px 1fr"}
                  key={item}
                >
                  <Icon size={"20px"} source={list.marker.src} yPos={"3px"} />
                  <Text as={"li"} color={"midgray"} size={"small"}>
                    {item}
                  </Text>
                </Grid>
              );
            })}
          </ul>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardE;
