import {
  boxShadowLight,
  colorBlueLightest,
  colorSteelLighter,
  colorSteelLightest,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

interface Props {
  background?: "gray" | "white";
  children?: any;
  elevate?: boolean;
}

const backgrounds = {
  ["gray"]: colorBlueLightest,
  ["white"]: colorWhite,
};

const ListCard: FunctionComponent<Props> = ({
  background = "white",
  children,
  elevate = true,
}) => {
  return (
    <ul
      css={css`
        background: ${backgrounds[background]};
        border: ${elevate ? `solid 1px ${colorSteelLightest}` : "none"};
        border-radius: 6px;
        box-shadow: ${elevate ? boxShadowLight : "none"};
        list-style: none;
        margin: 0;
        padding: 0;

        > li:not(:last-of-type) {
          border-bottom: solid 1px ${colorSteelLighter};
        }
      `}
    >
      {children}
    </ul>
  );
};

export default ListCard;
