import { mediaDesktop } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

interface Props {
  className?: string;
  filename?: string;
  src?: string;
}

const DEFAULT_SRC =
  "https://cdn.10xgenomics.com/image/upload/v1651769650/support/documents/documentation_default.png";

export default function DocumentThumbnail({
  className,
  filename = "document",
  src = DEFAULT_SRC,
}: Props) {
  return (
    <img
      alt={filename}
      className={className}
      css={css`
        @media (max-width: ${mediaDesktop}) {
          display: none;
        }

        height: auto;
        width: 64px;
      `}
      onError={({ currentTarget }) => {
        currentTarget.src = DEFAULT_SRC;
      }}
      src={src}
    />
  );
}
