import { borderRadiusMedium, borderStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Image from "../../../Image";
import Link from "../../../ProductPage/Link";
import Text from "../../../Text";
import type { SectionImage } from "../types";
export interface Props {
  description: string;
  image: SectionImage;
  sections: {
    list: string[];
    title: string;
    links: {
      label: string;
      url: string;
    }[];
  }[];
  title: string;
}

const CardC = ({ image, title, description, sections }: Props) => {
  return (
    <Grid
      css={css`
        padding: 2rem;
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
        flex: 1 1 0;
      `}
      gap={"1rem"}
      gridTemplateRows={{
        base: "auto",
        tabletLandscape: "240px 120px 148px 120px",
      }}
    >
      <Grid
        css={css`
          align-self: start;
          min-height: 240px;
        `}
      >
        <Grid gap={"0.5rem"}>
          {image && <Image alt={image.alt} src={image.src} />}
          <Text
            as={"h4"}
            css={css`
              white-space: pre;
            `}
            size={"xlarge"}
            weight={"semibold"}
          >
            {title}
          </Text>
        </Grid>
        <Text as={"p"} color={"midgray"} size={"small"}>
          {description}
        </Text>
      </Grid>

      {sections?.map((section) => {
        return (
          <div
            css={css`
              min-height: 98px;
            `}
            key={section.title}
          >
            <Text as={"h5"} size={"medium"} weight={"semibold"}>
              {section.title}
            </Text>
            <ul
              css={css`
                list-style: none;
                padding: 0;
                margin-block-start: 0.25em;
              `}
            >
              {section.list?.map((item) => {
                return (
                  <li key={item}>
                    <Text as={"p"} color={"midgray"} size={"small"}>
                      {item}
                    </Text>
                  </li>
                );
              })}
              {section.links?.map((item) => {
                return (
                  <li key={item.label}>
                    <Link
                      icon={"nav-right"}
                      label={item.label}
                      url={item.url}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </Grid>
  );
};

export default CardC;
