import type { FunctionComponent } from "react";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-core";

import Select from "./Select";

interface Props extends MenuProvided {
  className?: string;
  placeholder: string;
}

const CustomMenuSelect: FunctionComponent<Props> = ({
  className,
  items,
  refine,
  placeholder,
  currentRefinement,
}) => {
  const options = [
    { label: placeholder, value: "" },
    ...items.map((item) => {
      return { label: item.label, value: item.value };
    }),
  ];
  const currentValue = currentRefinement
    ? { label: currentRefinement, value: currentRefinement }
    : { label: placeholder, value: "" };

  return (
    <div className={className}>
      <Select
        onChange={({ value }: { label: string; value: string }) => {
          refine(value);
        }}
        options={options}
        placeholder={placeholder}
        value={currentValue}
      />
    </div>
  );
};

export default connectMenu<Props>(CustomMenuSelect);
