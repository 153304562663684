export const ANALYSIS_GUIDES_FACETS = [
  {
    attribute: "topics",
    label: "Topic",
  },
  {
    attribute: "products.name",
    label: "Product",
  },
  {
    attribute: "type",
    label: "Content Type",
  },
];
