import {
  borderRadiusMedium,
  borderStandard,
  boxShadowStandard,
  colorSteelLightest,
} from "@10xdev/design-tokens";
import { css, keyframes } from "@emotion/react";
import type { CSSProperties, FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";

import Icon from "../Icon";
import Text from "../Text";
import WistiaPopover from "../WistiaPopover";

interface Props {
  /**
   * A flag that indicates whether the video
   * should attempt to play without waiting
   * for user input.
   */
  autoplay?: boolean;

  /** A React style object. */
  style?: CSSProperties;

  /** A Wistia video ID. */
  wistiaID: string;
}

const HEIGHT_PERCENT = 0.5625;
const PLAY_ICON_SIZE = "26px";

const getWistiaURL = (
  wistiaID: string,
  options: Record<string, string | number>,
) => {
  const mediaURL = `https://support.wistia.com/medias/${wistiaID}`;
  const mediaOptions = Object.keys(options)
    .map((key) => `${key}=${options[key]}`)
    .join("&");
  return (
    "https://fast.wistia.net/oembed?url=" + [mediaURL, mediaOptions].join("?")
  );
};

const onMouseOverAnimation = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const onMouseOutAnimation = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

/**
 * Button showing a Wistia video thumbnail. When pressed,
 * it opens the video in the WistiaPopover component.
 */
const WistiaVideo: FunctionComponent<Props> = ({
  autoplay,
  style,
  wistiaID,
}) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  // Measure the component
  const ref = useRef<HTMLButtonElement>(null);
  const setDimensions = () => {
    const currentWidth = ref?.current?.getBoundingClientRect().width;

    if (typeof currentWidth === "undefined") {
      return;
    }

    const currentHeight = currentWidth * HEIGHT_PERCENT;
    setHeight(currentHeight);
    setWidth(currentWidth);
  };

  // Set dimensions on React updates
  useEffect(() => setDimensions());

  // Set dimensions on browser resize also
  useEffect(() => {
    window.addEventListener("resize", setDimensions);
    return function cleanup() {
      window.removeEventListener("resize", setDimensions);
    };
  });

  // Hit Wistia API to get thumbnail URL
  const wistiaURL = getWistiaURL(wistiaID, {
    height,
    width,
  });
  const { data, error } = useSWR(wistiaURL, (url) => {
    return fetch(url).then((response) => response.json());
  });

  // Modal video player
  const [showPopover, setShowPopover] = useState(false);
  const handleClick = () => setShowPopover(true);
  const handleClose = () => setShowPopover(false);

  // Play button should shrink so as not to overwhelm smaller videos
  const playButtonSize = width <= 480 ? "78px" : "96px";

  useEffect(() => {
    if (autoplay) {
      setShowPopover(true);
    }
  }, [autoplay]);

  return (
    <button
      aria-label={"play video"}
      css={css`
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: ${error ? colorSteelLightest : "none"},
          url(${data ? (data as any).thumbnail_url : ""});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border: none;
        cursor: pointer;
        display: block;
        margin: 0;
        height: ${height}px;
        padding: 0;
        width: 100%;
      `}
      onClick={handleClick}
      ref={ref}
      style={style}
      type={"button"}
    >
      <div
        css={css`
          align-items: center;
          animation: ${onMouseOutAnimation} 1s;
          animation-fill-mode: forwards;
          border-radius: ${borderRadiusMedium};
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;

          :hover {
            animation: ${onMouseOverAnimation} 0.5s;
            background-color: rgba(0, 0, 0, 0);
          }
        `}
      >
        {error ? (
          <div>
            <Icon color={"base"} size={PLAY_ICON_SIZE} source={"warning"} />
            <Text as={"div"} size={"medium"} weight={"medium"}>
              {"Video not found"}
            </Text>
          </div>
        ) : (
          <div
            css={css`
              align-items: center;
              background-color: rgba(255, 255, 255, 0.9);
              border: ${borderStandard};
              border-radius: 100%;
              box-shadow: ${boxShadowStandard};
              display: flex;
              height: 100%;
              justify-content: center;
              margin: 0;
              max-height: ${playButtonSize};
              max-width: ${playButtonSize};
              padding: 0;
              pointer-events: ${error ? "none" : "all"};
              width: 100%;
            `}
          >
            <Icon
              color={"base"}
              size={PLAY_ICON_SIZE}
              source={"play"}
              xPos={"2px"}
              yPos={"2px"}
            />
          </div>
        )}
      </div>

      {showPopover ? (
        <WistiaPopover id={wistiaID} onClose={handleClose} />
      ) : null}
    </button>
  );
};

export default WistiaVideo;
