import {
  colorSteelLighter,
  colorSteelLightest,
  colorWhite,
  spacing12,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import React, { memo, useMemo, useRef, useState } from "react";
import type { ScrollState } from "react-scrollbars-custom/dist/types/types";
import Sticky from "react-stickynode";

import Text from "../../Text";
import Scrollbar from "./Scrollbar";
import { isScrollState } from "./utils";

export type ColumnType<T> = {
  [K in keyof T]: {
    align?: "left" | "center" | "right";
    displayCss?: string;
    key: K;
    render?: (value: T[K], record: T) => React.ReactNode;
    renderTitle: ReactNode | string;
    width?: string;
  };
}[keyof T];

export interface TableProps {
  className?: string;
  /** An array of `ColumnType<T>` objects, representing the columns of the table. */
  columns: ColumnType<any>[];
  /** An array of data objects that will be used to populate the table. */
  dataSource: Record<string, any>[];
}

const rowStyles = css`
  box-sizing: border-box;
  border-bottom: 1px solid ${colorSteelLighter};
`;

const cellStyles = css`
  background-color: ${colorWhite};
  box-sizing: border-box;
  word-break: break-word;
  font-family: inherit;
  padding: 12px;
  width: 150px;
`;

const headerStyles = css`
  background-color: ${colorSteelLightest};
  padding: 20px ${spacing12};
  position: sticky;
  text-align: left;
  top: 0;
`;

const scrollBoxShadowStyles = css`
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
`;

const firstColumnStyles = css`
  left: 0;
  position: sticky;
`;

const firstColumnHeaderStyles = css`
  left: 0;
  z-index: 1;
`;

const DEFAULT_COLUMN_WIDTH = "150px";

const ColumnHeader = React.memo(function ColumnHeader({
  className,
  width,
  renderTitle,
  key,
}: {
  className?: string;
  key: string;
  renderTitle: ReactNode | string;
  width: string;
}) {
  const title =
    typeof renderTitle === "string" ? (
      <Text as={"span"} size={"xsmall"} weight={"semibold"}>
        {renderTitle}
      </Text>
    ) : (
      renderTitle
    );
  return (
    <th
      className={className}
      css={[
        cellStyles,
        headerStyles,
        css`
          width: ${width};
        `,
      ]}
      key={key}
    >
      {title}
    </th>
  );
});

type CellProps<T extends object> = {
  className?: string;
  column: any;
  record: T;
};

const Cell = React.memo(function Cell<T extends object>({
  className,
  column,
  record,
}: CellProps<T>) {
  const { align = "left", key, render, width } = column as ColumnType<T>;
  const value = record[key];
  const renderedValue = useMemo(() => {
    if (render) {
      return render(value, record);
    } else {
      return value as ReactNode;
    }
  }, [value, record, render]);

  return (
    <td
      className={className}
      css={[
        cellStyles,
        css`
          text-align: ${align};
          width: ${width};
        `,
      ]}
    >
      {typeof renderedValue === "string" ||
      typeof renderedValue === "number" ? (
        <Text as={"span"} size={"xsmall"} weight={"regular"}>
          {renderedValue}
        </Text>
      ) : (
        renderedValue
      )}
    </td>
  );
});

const BoxShadow = React.memo(function BoxShadow({
  as,
  className,
  isScrolling = false,
  rowSpan,
  left = "0",
}: {
  as: "th" | "td";
  className?: string;
  isScrolling?: boolean;
  left: string;
  rowSpan?: number;
}) {
  const Element = `${as}` as keyof JSX.IntrinsicElements;
  return (
    <Element
      className={className}
      css={css`
        left: ${left};
        padding: 0;
        position: sticky;
        width: 0;
        &:after {
          ${scrollBoxShadowStyles}
          ${isScrolling
            ? "box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%);"
            : ""}
        }
      `}
      rowSpan={rowSpan}
    />
  );
});

const Table = ({ className, columns = [], dataSource }: TableProps) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleTableScroll = (
    event: React.UIEvent<HTMLDivElement, UIEvent> | ScrollState,
  ) => {
    if (isScrollState(event)) {
      if (containerRef.current) {
        containerRef.current.scrollLeft = event.scrollLeft;
      }
      if (event.scrollLeft > 0 && !isScrolling) {
        setIsScrolling(true);
      }

      if (event.scrollLeft === 0) {
        setIsScrolling(false);
      }
    }
  };

  return (
    <>
      <Sticky
        css={css`
          position: relative;
          z-index: 1;
        `}
      >
        <div
          css={css`
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
          `}
          ref={containerRef}
        >
          <table
            className={className}
            css={css`
              box-sizing: border-box;
              border-collapse: collapse;
              font-family: inherit;
              table-layout: fixed;
              width: 100%;
            `}
          >
            <thead>
              <tr css={rowStyles}>
                {columns.map(
                  (
                    {
                      renderTitle,
                      key,
                      width = DEFAULT_COLUMN_WIDTH,
                      displayCss,
                    },
                    columnIndex,
                  ) => {
                    return (
                      <React.Fragment key={`${columnIndex} ${key}`}>
                        <ColumnHeader
                          css={[
                            /** make first column header sticky */
                            columnIndex === 0
                              ? firstColumnHeaderStyles
                              : undefined,
                            /** remove right border when scrolling */
                            columnIndex === 0 && isScrolling
                              ? css`
                                  :after {
                                    background-color: transparent;
                                  }
                                `
                              : undefined,
                            displayCss,
                          ]}
                          key={key}
                          renderTitle={renderTitle}
                          width={width}
                        />
                        {columnIndex === 0 && (
                          <BoxShadow
                            as={"th"}
                            css={css`
                              top: 0;
                              z-index: 1;
                            `}
                            isScrolling={isScrolling}
                            left={width}
                          />
                        )}
                      </React.Fragment>
                    );
                  },
                )}
              </tr>
            </thead>
          </table>
        </div>
      </Sticky>
      <Scrollbar
        data-testid={"web-frontend-algolia-table"}
        disableTracksWidthCompensation={true}
        noScrollY={true}
        onScroll={handleTableScroll}
        permanentTracks={false}
        removeTracksWhenNotUsed={true}
        style={{
          boxSizing: "border-box",
          maxHeight: "100%",
          maxWidth: "100%",
        }}
        translateContentSizeYToHolder={true}
      >
        <table
          className={className}
          css={css`
            box-sizing: border-box;
            border-collapse: collapse;
            font-family: inherit;
            table-layout: fixed;
            width: 100%;
          `}
        >
          <tbody>
            {dataSource.map((record, index) => (
              <tr css={rowStyles} key={index}>
                {columns.map((column, columnIndex) => {
                  const { key, width = DEFAULT_COLUMN_WIDTH } = column;
                  return (
                    <React.Fragment key={`${columnIndex} ${key}`}>
                      <Cell
                        column={column}
                        /** make first column sticky */
                        css={[
                          columnIndex === 0 ? firstColumnStyles : undefined,
                          column.displayCss,
                        ]}
                        record={record}
                      />
                      {columnIndex === 0 && index === 0 && (
                        <BoxShadow
                          as={"td"}
                          isScrolling={isScrolling}
                          left={width}
                          rowSpan={dataSource.length}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Scrollbar>
    </>
  );
};

export default memo(Table);
