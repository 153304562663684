import { css } from "@emotion/react";

import WistiaVideo from "../../WistiaVideo";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  container?: ContainerProps;
  src?: string;
  wistiaID?: string;
}

const Video = ({ container, src, wistiaID }: Props) => {
  return (
    <Container {...container}>
      {wistiaID ? (
        <WistiaVideo wistiaID={wistiaID} />
      ) : (
        <video
          autoPlay
          css={css`
            height: auto;
            width: 100%;
          `}
          loop
          muted
          playsInline
        >
          <source src={src} type={"video/mp4"} />
        </video>
      )}
    </Container>
  );
};

export default Video;
