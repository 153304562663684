import { css } from "@emotion/react";

import DocumentCard from "../DocumentCard";
import type { Document } from "./types";

interface Props {
  documents: Document[];
  generateHref: (document: Document) => string;
}

export default function DocumentsList({ documents, generateHref }: Props) {
  return (
    <ul
      css={css`
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
      `}
    >
      {documents.map((document) => {
        const firstAttachment = document.attachments[0];

        return (
          <li key={document.objectID}>
            <DocumentCard
              cgNumber={document.attachments[0]?.cgNumber}
              documentType={document.docType}
              image={
                firstAttachment
                  ? {
                      alt: document.title,
                      src: firstAttachment.thumbnailHref,
                    }
                  : null
              }
              link={generateHref(document)}
              subtext={document.description}
              title={document.title}
            />
          </li>
        );
      })}
    </ul>
  );
}
