import { borderStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  links: Array<{ description?: string; label: string; url: string }>;
  title: string;
}

const DownloadLinks = ({ links, title }: Props) => {
  return (
    <section css={css``}>
      <Text
        as={"h5"}
        css={css`
          line-height: 1;
          margin-bottom: 0.5rem;
        `}
        size={"large"}
        weight={"semibold"}
      >
        {title}
      </Text>
      <ul
        css={css`
          display: grid;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {links.map(({ description, label, url }) => {
          return (
            <li
              css={css`
                border-bottom: ${borderStandard};
                padding: 0.5rem 0;
              `}
              key={label}
            >
              <Anchor href={url}>
                <Icon
                  css={css`
                    margin: 0 0.5rem;
                  `}
                  size={"12px"}
                  source={"download"}
                />
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"medium"}
                  weight={"medium"}
                >
                  {label}
                </Text>
              </Anchor>
              {description ? (
                <Text
                  as={"p"}
                  css={css`
                    padding: 0.5rem 0;
                  `}
                  size={"medium"}
                >
                  {description}
                </Text>
              ) : null}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default DownloadLinks;
