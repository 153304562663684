import type { LinkItem } from "@10xdev/cms/types";
import {
  borderRadiusMedium,
  colorBlueDark,
  colorGrayLightest,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import ConditionalLink from "../ConditionalLink";
import Heading from "../Heading";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";

interface Props {
  description: string;
  elevate?: boolean;
  image?: {
    alt?: string;
    url: string;
  };
  linkList?: Array<LinkItem>;
  list?: Array<string>;
  title: string;
}

const OverviewCard: FunctionComponent<Props> = ({
  description,
  image,
  linkList,
  list,
  title,
}) => {
  return (
    <Card
      as={"li"}
      css={css`
        background: none;
        margin: 0;
        min-height: 480px;
        padding: 0;
      `}
    >
      {image?.url ? (
        <div
          css={css`
            background-color: ${colorGrayLightest};
            border-radius: ${borderRadiusMedium};
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;
            min-height: 1px;
            overflow: hidden;
          `}
        >
          <ConditionalLink href={linkList?.[0].url}>
            <Image alt={image.alt} src={image.url} />
          </ConditionalLink>
        </div>
      ) : null}

      <Heading
        as={"h4"}
        css={css`
          margin-bottom: 1.5rem;
        `}
        responsive={true}
        size={"large"}
      >
        {title}
      </Heading>

      {description ? (
        <Text
          as={"div"}
          color={"midgray"}
          css={css`
            margin-bottom: ${list?.length ? "1rem" : "2rem"};
          `}
          responsive={true}
          size={"medium"}
        >
          {description}
        </Text>
      ) : null}

      {list?.length ? (
        <ul
          css={css`
            margin: 0 0 1.5rem;
            padding: 0 0 0 1.25rem;
          `}
        >
          {list.map((item) => {
            return (
              <Text
                as={"li"}
                color={"midgray"}
                css={css`
                  list-style-type: disc;
                  margin-bottom: 0.5rem;
                `}
                key={item}
                responsive={true}
                size={"medium"}
              >
                {item}
              </Text>
            );
          })}
        </ul>
      ) : null}

      {linkList?.length ? (
        <ul
          css={css`
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;
            row-gap: 0.75rem;
          `}
        >
          {linkList.map((link) => {
            const { label, type, url } = link;
            return (
              <li key={url}>
                <Text
                  as={"div"}
                  color={"blue"}
                  size={"small"}
                  weight={"semibold"}
                >
                  {url ? (
                    <Anchor
                      aria-label={`${label} about ${title}`}
                      color={"inherit"}
                      hoverColor={colorBlueDark}
                      href={url}
                    >
                      {label}
                      {type ? (
                        <Icon
                          size={"12px"}
                          source={type}
                          xPos={"12px"}
                          yPos={"1.5px"}
                        />
                      ) : null}
                    </Anchor>
                  ) : null}
                </Text>
              </li>
            );
          })}
        </ul>
      ) : null}
    </Card>
  );
};

export default OverviewCard;
