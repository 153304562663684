import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  className?: string;
  linkText: string | null;
  linkUrl: string;
}

const Link = ({ className, linkUrl, linkText }: Props) => {
  return (
    <Anchor
      className={className}
      css={css`
        align-items: center;
        display: flex;
        gap: 0.6rem;
      `}
      href={linkUrl}
      key={uuidv4()}
    >
      <Text
        as={"div"}
        color={"inherit"}
        responsive={true}
        size={"small"}
        weight={"medium"}
      >
        {linkText || "Learn more"}
      </Text>
      <Icon
        color={"inherit"}
        size={"10px"}
        source={"nav-right"}
        yPos={"1.5px"}
      />
    </Anchor>
  );
};

export default Link;
