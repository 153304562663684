import {
  borderRadiusMedium,
  colorBlueLighter,
  colorBlueMedium,
  colorSteelDarkest,
  colorSteelLighter,
  colorWhite,
  fontFamilyBase,
  fontSizeSmall,
} from "@10xdev/design-tokens";
import type { FunctionComponent } from "react";
import ReactSelect, { components } from "react-select";

import Icon from "../Icon";

interface Props {
  isDisabled?: boolean;
  name?: string;
  onChange: (item: any) => void;
  options: Array<{ label: string; value: string }>;
  placeholder: string;
  value?: {
    label: string;
    value: string;
  };
}

const DropdownIndicator: FunctionComponent = (props: any) => {
  const { selectProps } = props;
  const flipStyles = selectProps.menuIsOpen
    ? { transform: "rotateX(180deg)" }
    : {};
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        color={"midgray"}
        css={{ marginRight: ".5rem", ...flipStyles }}
        size={".75rem"}
        source={"dropdown"}
      />
    </components.DropdownIndicator>
  );
};

const Select: FunctionComponent<Props> = ({
  isDisabled,
  name,
  onChange,
  options,
  placeholder,
  value,
}) => {
  return (
    <ReactSelect
      components={{ DropdownIndicator }}
      isDisabled={isDisabled}
      isSearchable={false}
      maxMenuHeight={1000}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={{
        container: (provided) => ({
          ...provided,
          fontFamily: fontFamilyBase,
          fontSize: fontSizeSmall,
          width: "100%",
        }),
        control: (provided, state) => ({
          ...provided,
          "&:hover": {
            borderColor: colorBlueMedium,
          },
          borderColor: state.isFocused ? colorBlueMedium : colorSteelLighter,
          borderRadius: borderRadiusMedium,
          boxShadow: "none",
          color: colorSteelDarkest,
          height: "3rem",
          paddingLeft: ".5rem",
        }),

        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        menu: (provided) => ({
          ...provided,
          color: colorSteelDarkest,
          padding: ".5rem 0",
          zIndex: 10000,
        }),
        menuPortal: (provided) => ({
          ...provided,
          color: colorSteelDarkest,
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? colorBlueLighter : colorWhite,
          color: colorSteelDarkest,
          padding: ".75rem 1rem",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: colorSteelDarkest,
          margin: 0,
          overflow: "hidden",
          paddingRight: 12,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: colorSteelDarkest,
        }),
      }}
      value={value}
    />
  );
};

export default Select;
