import { spacing16 } from "@10xdev/design-tokens";
import type { ObjectWithObjectID } from "@algolia/client-search";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import type { Hit, HitsProvided } from "react-instantsearch-core";
import { connectHits } from "react-instantsearch-dom";

import { useHitsFirstReceived } from "../../Search/hooks";

// Can't pass more sophisticated generics due to the HOC structure of algolia components
export type HitRenderer = (args: { hit: Hit<any> }) => ReactNode;

export type HitsTransformer = (hits: Hit<any>[]) => ObjectWithObjectID[];

interface OurProps {
  hitRenderer: HitRenderer;
  onHits: () => void;
}

type Props = OurProps & HitsProvided<Hit>;

/**
 * A list of search hits that requires a custom renderer.
 */
function Hits({ hits = [], hitRenderer, onHits }: Props) {
  /**
   * Notify when there are hits. This is to prevent
   * annoying layout reflow throughout the page.
   */
  useHitsFirstReceived(hits, onHits);

  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: ${spacing16};
      `}
    >
      {hits.map((hit) => {
        return <li key={hit.objectID}>{hitRenderer({ hit })}</li>;
      })}
    </ul>
  );
}

export default connectHits<Props, Hit>(Hits);
