import {
  borderRadiusMedium,
  borderStandard,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Image from "../../../Image";
import Links from "../../../Links";
import Text from "../../../Text";
import WistiaVideo from "../../../WistiaVideo";
import type { SectionImage, SectionLink } from "../types";

export interface Props {
  image?: SectionImage;
  links?: SectionLink[];
  title?: string;
  video?: string;
  width?: string;
}

const CardD = ({ image, links, title, video, width = "360px" }: Props) => {
  return (
    <Grid
      css={css`
        border-radius: ${borderRadiusMedium};
        border: ${borderStandard};
        box-sizing: border-box;
        flex-shrink: 0;
        padding: 1.5rem;
        width: 100%;
        @media (min-width: ${mediaTabletPortrait}) {
          width: ${width};
        }
      `}
      gap={"1.25rem"}
    >
      {image ? <Image alt={image.alt} src={image.src} /> : null}
      {video ? <WistiaVideo wistiaID={video} /> : null}
      {title ? (
        <Text as={"h4"} size={"medium"} weight={"semibold"}>
          {title}
        </Text>
      ) : null}
      {links?.length ? <Links links={links} /> : null}
    </Grid>
  );
};

export default CardD;
