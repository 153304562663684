import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import ConditionalLink from "../../ConditionalLink";
import Image from "../../Image/Image";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

export interface Props {
  alt: string;
  container?: ContainerProps;
  link?: string;
  src: string;
  srcSmall?: string;
}

const ImageBlock = ({ alt, container, link, src, srcSmall }: Props) => {
  return (
    <Container {...container}>
      <ConditionalLink href={link}>
        {srcSmall ? (
          <Image
            alt={alt}
            css={css`
              display: block;
              @media (min-width: ${mediaPhoneOnly}) {
                display: none;
              }
            `}
            src={srcSmall}
          />
        ) : null}
        {src ? (
          <Image
            alt={alt}
            css={css`
              display: none;
              ${!srcSmall ? "display: block" : null};
              @media (min-width: ${mediaPhoneOnly}) {
                display: block;
              }
            `}
            src={src}
          />
        ) : null}
      </ConditionalLink>
    </Container>
  );
};

export default ImageBlock;
