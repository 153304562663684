import {
  borderRadiusMedium,
  colorWhite,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ElementType, ReactNode } from "react";

interface Props {
  as?: ElementType;
  children: ReactNode;
  className?: string;
  elevate?: boolean;
  hoverable?: boolean;
  outline?: boolean;
}

const Card = ({
  as: Element = "div",
  children,
  className,
  elevate = false,
  hoverable = false,
  outline = false,
}: Props) => {
  return (
    <Element
      className={className}
      css={css`
        background: ${colorWhite};
        border: solid 1px;
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        margin: 0;
        min-height: auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: all 0.25s ease;
        box-shadow: ${elevate ? "0px 2px 5px rgba(0, 0, 0, 0.06)" : "none"};
        border-color: ${outline ? "rgba(68, 89, 121, 0.06)" : "transparent"};
        ${hoverable
          ? `
              @media (min-width: ${mediaTabletPortrait}) {
                  &:hover {
                      border-color: rgba(68, 89, 121, 0.06);
                      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
                      position: relative;
                  }
              }
          `
          : undefined}
      `}
    >
      {children}
    </Element>
  );
};

export default Card;
