import {
  borderStandard,
  colorBlueMedium,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import { INDICES } from "./constants";

type Active = { id: string };

interface Props {
  active: Active;
  onClick: (curr: { id: string }) => void;
}

const ListCss = css`
  border-bottom: ${borderStandard};
  column-gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: ${mediaTabletLandscape}) {
    width: max-content;
  }
`;

const filters: { id: string; title: string }[] = [
  { id: "all", title: "All" },
  ...INDICES.map((index) => ({
    id: index.id,
    title: index.title,
  })),
];

const Filter: FunctionComponent<Props> = ({ active, onClick }) => {
  const handleClick = (current: Active) => {
    onClick({ id: current.id });
  };

  return (
    <ul css={ListCss}>
      {filters.map((filter) => {
        return (
          <li key={filter.id}>
            <button
              css={css`
                background: none;
                border: none;
                border-bottom: ${active.id === filter.id
                  ? "solid 4px"
                  : "none"};
                border-color: ${colorBlueMedium};
                cursor: pointer;
                padding: 12px 0;
              `}
              onClick={() => handleClick({ id: filter.id })}
            >
              <Text
                as={"p"}
                color={active.id === filter.id ? "base" : "midgray"}
                size={"medium"}
                weight={active.id === filter.id ? "semibold" : "regular"}
              >
                {filter.title}
              </Text>
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default Filter;
