import BaseCarousel from "../../Carousel/Carousel";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";
import type { Props as StackProps } from "./Layout/Stack";
import Stack from "./Layout/Stack";

interface Props {
  aspectRatio: number;
  container?: ContainerProps;
  gap?: number;
  itemHeight: number;
  items: StackProps[];
  itemsPerScroll?: number;
}

const Carousel = ({ container, items, ...carouselProps }: Props) => {
  return (
    <Container {...container}>
      <BaseCarousel {...carouselProps}>
        {items.map((item, index) => {
          return (
            <Stack
              container={item.container}
              content={item.content}
              key={index}
            />
          );
        })}
      </BaseCarousel>
    </Container>
  );
};

export default Carousel;
