import { mediaTabletLandscape, mediaDesktop } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { Children, useState } from "react";

interface Props {
  aspectRatio: number;
  children: ReactNode;
  gap?: number;
  itemHeight: number;
  itemsPerScroll?: number;
}

const useCarousel = ({
  aspectRatio = 1.1,
  children,
  gap = 16,
  itemsPerScroll = 3,
  itemHeight = 312,
}: Props) => {
  const itemWidth = Math.round(itemHeight * aspectRatio);
  const viewWidth = (itemWidth + gap) * itemsPerScroll;
  const carouselWidth = (itemWidth + gap) * Children.toArray(children).length;

  const [offSet, setOffSet] = useState(0);

  const handlePreviousClick = () => {
    setOffSet(offSet + viewWidth);
  };

  const handleNextClick = () => {
    setOffSet(offSet - viewWidth);
  };

  const carouselCss = css`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-bottom: 2rem;
    width: 100%;
    gap: ${gap}px;

    @media (min-width: ${mediaTabletLandscape}) {
      margin-bottom: 1.5rem;
      box-sizing: border-box;
      padding-bottom: 0;
      transform: translateX(${offSet}px);
      transition: transform 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
    }
    @media (min-width: ${mediaDesktop}) {
      overflow: visible;
    }
  `;

  const buttonContainerCss = css`
    display: none;

    @media (min-width: ${mediaTabletLandscape}) {
      display: flex;
      justify-content: flex-end;
      max-width: 1200px;
    }
  `;

  return {
    buttonContainerCss,
    carouselCss,
    carouselWidth,
    handleNextClick,
    handlePreviousClick,
    itemWidth,
    offSet,
    viewWidth,
  };
};

export default useCarousel;
