import {
  colorSteelDarker,
  fontFamilyBase,
  fontSizeXsmall,
  mediaTabletPortrait,
  sizeXlarge,
  spacing16,
  spacing32,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { CSSProperties } from "react";

import Image from "../Image";

interface FiguresProps {
  images: {
    caption?: string;
    src: string;
  }[];
}

const Figures = ({ images }: FiguresProps) => {
  let maxImageHeight: CSSProperties["maxHeight"];
  switch (images.length) {
    case 1:
      maxImageHeight = "450px";
      break;
    case 2:
      maxImageHeight = "360px";
      break;
    case 3:
      maxImageHeight = "240px";
      break;
    default:
      maxImageHeight = "450px";
      break;
  }
  return (
    <div
      css={css`
        align-items: stretch;
        box-sizing: border-box;
        display: grid;
        gap: ${spacing16};
        grid-template-columns: 1fr;
        justify-items: stretch;
        margin-bottom: ${sizeXlarge};

        @media (min-width: ${mediaTabletPortrait}) {
          gap: ${spacing32};
          grid-template-columns: repeat(${images.length}, 1fr);
        }
      `}
    >
      {images.map(({ caption, src }) => (
        <figure
          css={css`
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: ${spacing16};
            justify-content: center;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            max-width: 100%;
            padding: 0;
          `}
          key={src}
        >
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: center;
              max-height: ${maxImageHeight};
              overflow: hidden;
              width: 100%;
            `}
          >
            <Image
              alt={caption}
              css={css`
                height: 100%;
                object-fit: contain;
                width: 100%;
              `}
              src={src}
            />
          </div>

          {caption ? (
            <figcaption
              css={css`
                color: ${colorSteelDarker};
                flex: 0 0 auto;
                font-family: ${fontFamilyBase};
                font-size: ${fontSizeXsmall};
                max-width: 100%;
                text-align: center;
              `}
            >
              {caption}
            </figcaption>
          ) : null}
        </figure>
      ))}
    </div>
  );
};

export default Figures;
