import {
  borderRadiusMedium,
  colorBlueLighter,
  colorBlueMedium,
  fontFamilyRegular,
  fontSizeXsmall,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  children: ReactNode;
}

export const Callout: FunctionComponent<Props> = ({ children }) => {
  return (
    <div
      css={css`
        background: ${colorBlueLighter};
        box-sizing: border-box;
        border-radius: ${borderRadiusMedium};
        color: ${colorBlueMedium};
        font-family: ${fontFamilyRegular};
        font-size: ${fontSizeXsmall};
        font-weight: normal;
        margin: 0 0 1rem 0;
        overflow: auto;
        padding: ${sizeXlarge};
      `}
    >
      <Text
        as={"span"}
        css={css`
          color: ${colorBlueMedium};
        `}
        size={"medium"}
      >
        {children}
      </Text>
    </div>
  );
};

export default Callout;
