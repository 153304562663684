export const getSubtitle = ({
  altLocationLabel,
  city,
  country,
}: {
  altLocationLabel: string | null;
  city: string | null;
  country: string | null;
}): string => {
  if (altLocationLabel) {
    return altLocationLabel;
  }

  if (city && !country) {
    return city;
  }

  if (!city && country) {
    return country;
  }

  if (city && country) {
    return `${city}, ${country}`;
  }

  return "";
};
