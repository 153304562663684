import { useState } from "react";

import CompareTable from "./CompareTable";
import { ASSAY_CATEGORIES, ASSAY_GROUPS } from "./constants";
import SegmentedControl from "./SegmentedControl";
import type { AssayGroup, Assays } from "./types";
import { getAssay } from "./utils";

interface Props {
  assays: Assays;
  segmentControl?: boolean;
}

const ProductCompare = ({ assays, segmentControl = true }: Props) => {
  const [activeSegment, setActiveSegment] = useState<AssayGroup>(
    "single-cell-gene-expression",
  );
  const [comparisons, setComparisons] = useState(
    ASSAY_GROUPS[activeSegment].map((slug) => getAssay(assays, slug)),
  );

  return (
    <>
      {segmentControl ? (
        <SegmentedControl
          active={activeSegment}
          assays={assays}
          segments={ASSAY_CATEGORIES}
          setActive={setActiveSegment}
          setComparisons={setComparisons}
        />
      ) : null}
      <CompareTable
        assays={assays}
        comparisons={comparisons}
        setActive={setActiveSegment}
        setComparisons={setComparisons}
      />
    </>
  );
};

export default ProductCompare;
