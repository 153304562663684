import { ASSAY_GROUPS } from "./constants";
import type { AssayGroup, Assays, AssaySlug } from "./types";

export const getAssay = (assays: Assays, slug: AssaySlug) =>
  assays.find((item) => item.slug === slug);

export const getAssayGroup = (activeSegment: AssayGroup, assays: Assays) =>
  ASSAY_GROUPS[activeSegment].map((slug: AssaySlug) => getAssay(assays, slug));

export const getPlatformTag = (id: string) => {
  const name = id.split("-")[0];
  const platformColors: Record<string, string> = {
    // TODO: Add chromium and visium colors to design tokens
    ["chromium"]: "#194D99",
    ["visium"]: "#2B977D",
    ["xenium"]: "#7060AA", // cnv product color
  };
  return {
    color: platformColors[name],
    name,
  };
};
