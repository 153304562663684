import { useRouter } from "next/router";
import type { ParsedUrlQuery } from "querystring";
import qs from "querystring";
import { useEffect, useState } from "react";

const SESSION_STORAGE_KEY = "searchParams";

const useStorePreviousSearchParams = () => {
  const router = useRouter();

  useEffect(() => {
    const setSearchParamsInStorage = () => {
      const stringifiedQuery = qs.stringify(router.query);
      sessionStorage.setItem(SESSION_STORAGE_KEY, stringifiedQuery);
    };

    router.events.on("routeChangeStart", setSearchParamsInStorage);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", setSearchParamsInStorage);
    };
  }, [router]);
};

const usePreviousSearchParams = ({
  clearParams,
}: { clearParams?: boolean } = {}) => {
  const [searchParamsState, setSearchParamsState] = useState<ParsedUrlQuery>();

  useEffect(() => {
    const storedParams = sessionStorage.getItem(SESSION_STORAGE_KEY);
    let parsedParams;

    if (storedParams) {
      parsedParams = qs.parse(storedParams);
    }

    // Only delete transitory params if the user
    // is not being directed to a form.
    if (clearParams) {
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
    }

    setSearchParamsState(parsedParams);
  }, [clearParams]);

  return searchParamsState;
};

export { useStorePreviousSearchParams, usePreviousSearchParams };
