import searchClient from "@10xdev/algolia-utils/searchOnlyClient";
import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";
import { useState } from "react";
import type { SearchState } from "react-instantsearch-core";
import { InstantSearch } from "react-instantsearch-dom";

import { useUrlSync } from "../Search/hooks";
import MenuSelects from "./MenuSelects";

type HitsRenderer = (args: {
  onHits: () => void;
  searchState: SearchState;
}) => ReactNode;

type Props = WithHitsRenderer & BaseProps;

interface WithHitsRenderer {
  hitsRenderer: HitsRenderer;
}

interface BaseProps {
  children?: ReactNode;
  /**
   * A function that will be passed to the clear filters
   * button as `transformItems`:
   * https://www.algolia.com/doc/api-reference/widgets/clear-refinements/react/#widget-param-transformitems
   *
   * Use this to filter attributes that should not be
   * cleared from refinements when the user hits the
   * "Clear all" refinements button.
   */

  includeStats?: boolean;

  indexName: string;

  isInfinite?: boolean;
}

/**
 * A full Algolia search experience, showing a query
 * input, a list of results, and filters in a sidebar.
 * For example usage, see EventSearch and VideoSearch.
 */
const CustomSearch: FunctionComponent<Props> = ({
  children,
  hitsRenderer,
  indexName,
}) => {
  const { handleSearchStateChange, searchState } = useUrlSync();

  // Prevents sudden layout reflow on startup
  const [isVisible, setIsVisible] = useState(false);
  const handleHits = () => setIsVisible(true);

  const searchCss = css`
    display: ${isVisible ? "flex" : "none"};
    column-gap: 5%;
    grid-template-columns: 240px minmax(660px, 70%);
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    @media (max-width: ${mediaTabletLandscape}) {
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }
  `;

  return (
    <InstantSearch
      indexName={indexName}
      onSearchStateChange={handleSearchStateChange}
      searchClient={searchClient()}
      searchState={searchState}
    >
      {children}
      <div css={searchCss}>
        <div
          css={css`
            width: 100%;
            padding-top: 2rem;
          `}
        >
          <>
            <MenuSelects />
            {hitsRenderer!({ onHits: handleHits, searchState })}
          </>
        </div>
      </div>
    </InstantSearch>
  );
};

export default CustomSearch;
