import { css } from "@emotion/react";

interface Props {
  src?: string;
}

const LoopingVideo = ({ src }: Props) => {
  return (
    <video
      autoPlay
      css={css`
        height: auto;
        width: 100%;
      `}
      loop
      muted
      playsInline
    >
      <source src={src} type={"video/mp4"} />
    </video>
  );
};

export default LoopingVideo;
