import {
  colorBlueDark,
  colorBlueMedium,
  mediaDesktop,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  stepLinks: {
    href: string;
    label: string;
  }[];
}

const StepLinks = ({ stepLinks }: Props) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 1rem;
        width: 100%;

        @media (max-width: ${mediaDesktop}) {
          grid-template-columns: 1fr;
          gap: 1rem;
        }
      `}
    >
      {stepLinks.map(({ href, label }) => {
        return (
          <Anchor
            css={css`
              align-items: center;
              border: 1px solid rgba(68, 89, 121, 0.06);
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
              border-radius: 6px;
              color: ${colorBlueMedium};
              display: flex;
              justify-content: space-between;
              height: 3rem;
              padding: 0 1.5rem;
              width: 100%;
              box-sizing: border-box;

              &:hover {
                color: ${colorBlueDark};
                box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
              }

              @media (max-width: ${mediaTabletLandscape}) {
                max-width: 384px;
                width: 100%;
              }
            `}
            href={href}
            key={label}
          >
            <Text
              as={"span"}
              color={"inherit"}
              size={"small"}
              weight={"medium"}
            >
              {label}
            </Text>
            <Icon color={"inherit"} size={"12px"} source={"nav-right"} />
          </Anchor>
        );
      })}
    </div>
  );
};

export default StepLinks;
