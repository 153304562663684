import type { EventCategory } from "@10xdev/cms/model/event-category";
import {
  colorBlueMedium,
  colorNeonGreenDark,
  colorOrange,
  colorProductCnv,
} from "@10xdev/design-tokens";
import { format, getDate, getHours, getMonth } from "date-fns";
import { isValid } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";

import Conference from "./assets/Conference.svg";
import UserGroupMeeting from "./assets/UserGroupMeeting.svg";
import Webinar from "./assets/Webinar.svg";
import Workshop from "./assets/Workshop.svg";

/**
 * Returns a color for an event type.
 * Seminar and Webinar are treated the same.
 */
const eventColors: Record<string, string> = {
  conference: colorNeonGreenDark,
  seminar: colorBlueMedium,
  "user-group-meeting-or-symposium": colorOrange,
  webinar: colorBlueMedium,
  workshop: colorProductCnv,
};
export const getEventColor = (category?: EventCategory) => {
  return (category && eventColors[category.slug]) || colorBlueMedium;
};

/**
 * Returns a formatted date, or range of dates, for an event.
 */
export const getEventDate = (start: Date | string, end: Date | string) => {
  // Single-day event?
  const startDay = getDate(new Date(start));
  const endDay = getDate(new Date(end));
  if (startDay === endDay) {
    return format(new Date(end), "MMMM d, yyyy");
  }

  // Multi-day event
  const startMonth = getMonth(new Date(start));
  const endMonth = getMonth(new Date(end));
  if (startMonth === endMonth) {
    // Multi-day event in the same month
    return (
      format(new Date(start), "MMMM d") +
      " - " +
      format(new Date(end), "d, yyyy")
    );
  } else {
    // Multi-day event that touches more than one month
    return (
      format(new Date(start), "MMMM d") +
      " - " +
      format(new Date(end), "MMMM d, yyyy")
    );
  }
};

/**
 * Returns the SVG icon for an event category.
 * Seminar and Webinar are treated the same.
 */
const icons: Record<string, any> = {
  conference: Conference,
  seminar: Webinar,
  ["user-group-meeting-or-symposium"]: UserGroupMeeting,
  webinar: Webinar,
  workshop: Workshop,
};
export const getEventIcon = (category?: EventCategory) => {
  return (category && icons[category.slug]) || null;
};

/**
 * Returns a formatted location string for an event.
 */
export const getEventLocation = ({
  city,
  country,
}: {
  city?: string | null;
  country?: string | null;
}) => {
  return [city, country].filter((item) => item).join(", ");
};

// Bit of a kludge to get timezone abbreviations that aren't in the
// library
const CUSTOM_TIME_ZONE_ABBREVIATIONS: Record<string, string> = {
  "+08": "SGT",
};

export function getTimeZoneAbbreviation(
  timeZoneRegion: string,
  targetDateTime: Date,
): string {
  const timeZoneFormatted = formatToTimeZone(targetDateTime, "z", {
    timeZone: timeZoneRegion,
  });

  return CUSTOM_TIME_ZONE_ABBREVIATIONS[timeZoneFormatted] || timeZoneFormatted;
}

/**
 * Get formatted event start and end times.
 *
 * If the event has a start time of midnight,
 * we assume it is an all-day event and the
 * time label will be "All-day event".
 *
 * If the event has a start time at some other
 * time of day, we try to return a formatted
 * range of hours.
 *
 * If the start and end timestamps span
 * multiple dates, we return "Multi-day event".
 *
 * The function returns "All-day event"
 * for invalid date input.
 *
 * Abbreviated timezones are included where passed.
 */
export const getEventTimes = (
  start: string,
  end: string,
  timeZone: string = "",
): string => {
  // Use to append abbreviated timezone, if any exists
  const appendTimeZone = (input: string) => {
    if (timeZone) {
      const timeZoneFormatted = getTimeZoneAbbreviation(timeZone, new Date());

      return `${input}, ${timeZoneFormatted}`;
    }
    return input;
  };

  // This event starts at midnight?
  // Assume it's an all-day thing.
  // Likewise if it looks like
  // date strings are non-viable.
  if (
    !isValid(new Date(start)) ||
    !isValid(new Date(end)) ||
    getHours(new Date(start)) === 0
  ) {
    return appendTimeZone("All-day event");
  }

  // This event spans multiple days?
  // Return a string specifying as much.
  // We don't specify hour ranges if
  // the event is multi-day, because
  // the event may not run for the
  // same range of hours each day.
  if (getDate(new Date(start)) !== getDate(new Date(end))) {
    return appendTimeZone("Multi-day event");
  }

  // Timestamps that specify hours are going to come in
  // with "z" or "Z" appended, which makes them very
  // difficult to interpret in the correct time zone.
  // Stripping the timezone flag before passing to
  // date-fns means dates can be interpreted correctly.
  const stripZ = (timestamp: string) =>
    timestamp.toLowerCase().endsWith("z") ? timestamp.slice(0, -1) : timestamp;

  // This event spans a range of hours on a single day?
  // Format the time range.
  const startTime = new Date(stripZ(start));
  const endTime = new Date(stripZ(end));
  const startFormatted = format(startTime, "h:mma");
  const endFormatted = format(endTime, "h:mma");
  const formattedRange = `${startFormatted} - ${endFormatted}`.toLowerCase();
  return appendTimeZone(formattedRange);
};

/**
 * Returns a string clamped to an explicit number of chars
 */
export const getTruncatedEventDescription = (
  text: string,
  maxChars: number = 300,
): string => {
  if (text && text.length > maxChars) {
    return text.slice(0, maxChars - 3) + "...";
  }
  return text;
};
