import Text from "../../Text";
import DocumentsList from "./DocumentsList";
import type { Document } from "./types";

interface Props {
  generateHref: (document: Document) => string;
  groupedDocuments: { docs: Document[]; groupName: string }[];
  ungroupedDocuments: Document[];
}

export default function GroupedDocuments({
  groupedDocuments,
  ungroupedDocuments,
  generateHref,
}: Props) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      {ungroupedDocuments.length > 0 && (
        <DocumentsList
          documents={ungroupedDocuments}
          generateHref={generateHref}
        />
      )}

      {groupedDocuments.map(({ groupName, docs }) => {
        return (
          <div
            css={{
              display: "grid",
              flexDirection: "column",
              gap: "0.5rem",
            }}
            key={groupName}
          >
            <Text
              as={"h4"}
              color={"steelDarker"}
              size={"medium"}
              weight={"semibold"}
            >
              {groupName}
            </Text>
            <DocumentsList documents={docs} generateHref={generateHref} />
          </div>
        );
      })}
    </div>
  );
}
