import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import type { TextSize } from "@10xdev/types";
import Anchor from "../../Anchor/Anchor";
import Icon from "../../Icon/Icon";
import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";
import type { SectionLink } from "./types";

interface Props {
  container?: ContainerProps;
  gap?: string;
  links: SectionLink[];
  size?: TextSize;
}

const Links = ({ container, links, size = "medium" }: Props) => {
  return (
    <Container
      css={css`
        list-style: none;
        margin: 0;
        padding: ${container?.padding || 0};
      `}
      {...container}
    >
      {links.map((link) => {
        return (
          <Anchor
            color={link.color}
            css={css`
              align-items: center;
              display: flex;
              gap: 0.75rem;
            `}
            hoverColor={link.hoverColor}
            href={link.url}
            key={uuidv4()}
            target={link.target}
          >
            <Text
              as={"div"}
              color={"inherit"}
              responsive={true}
              size={size}
              weight={"semibold"}
            >
              {link.label}
            </Text>
            <Icon
              color={"inherit"}
              css={css`
                flex-shrink: 0;
              `}
              size={"11.5px"}
              source={link.icon}
              yPos={"1px"}
            />
          </Anchor>
        );
      })}
    </Container>
  );
};

export default Links;
