import {
  borderRadiusMedium,
  borderStandard,
  colorBlueMedium,
  colorRed,
  colorSteelDarkest,
  colorSteelLight,
  colorSteelLightest,
  colorSteelMedium,
  colorWhite,
  fontFamilyBase,
  fontSizeMedium,
  fontWeightRegular,
  sizeMedium,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FormikErrors } from "formik";
import type {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  FunctionComponent,
} from "react";

import ErrorLabel from "../Form/ErrorLabel";

function isOptionObject(
  option: OptionGroup | OptionObject | string,
): option is OptionObject {
  return (
    !isOptionGroup(option) &&
    (typeof (option as any).label === "string" ||
      typeof (option as any).label === "number")
  );
}

function isOptionGroup(
  options: OptionGroup | OptionObject | string,
): options is OptionGroup {
  return Array.isArray((options as any).options);
}

function isOptionString(
  option: OptionGroup | OptionObject | string,
): option is string {
  return typeof option === "string";
}

interface OptionObject {
  label: string;
  value?: string | number;
}

type OptionGroup = {
  label: string;
  options: OptionObject[];
};

interface Props {
  className?: string;

  /** Activates or deactivates the input. */
  disabled?: boolean;

  /** An error message indicating failed validation. */
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];

  /**
   * A unique identifier that enables the input to
   * be associated with a label for accessibility.
   */
  id?: string;

  /** A string used for input label or aria-label . */
  label?: string;

  /**
   * Indicates whether multiple options can be selected.
   */
  multiple?: boolean;

  /** A string used to identify this input during form submission. */
  name?: string;

  /** A callback to be invoked when the input loses focus. */
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;

  /** A callback to be invoked when the contents of the input change. */
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;

  /**
   * A list of options to display as potential selections. The list can be:
   *
   * 1. An array of option objects, each containing "label" and "value" attributes.
   *    This approach tells the component to display a single list of options.
   *
   * 2. An array of group objects, each containing "label" and "options" attributes,
   *    where "label" is the name of the group and "options" is an array of option
   *    objects. Each option object should contain "label" and "value" attributes.
   *    This approach tells the component to display `optgroups`.
   */
  options: (OptionGroup | OptionObject | string)[];

  /** Placeholder text to display when the input is empty. */
  placeholder?: string;

  /** Prevents the parent form from being submitted if this input is empty. */
  required?: boolean;

  /** The number of option rows to display. */
  size?: number;

  style?: CSSProperties;

  /** The current value of the input. */
  value?: string;
}

/**
 * A generic select input. Implements a subset of
 * `<select />`, for use with Formik.
 */
const Select: FunctionComponent<Props> = ({
  className,
  disabled,
  error,
  id,
  label,
  multiple,
  name,
  onBlur,
  onChange,
  options,
  placeholder,
  size,
  value,
  style,
}) => {
  return (
    <>
      <select
        aria-label={label}
        className={className}
        css={css`
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-color: ${colorWhite};
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' fill='%236E7F99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.969 0C.406 0 .125.688.53 1.094l4 4c.25.25.656.25.906 0l4-4C9.844.687 9.562 0 9 0H.969z' /%3E%3C/svg%3E");
          background-position: right ${sizeMedium} top calc(50% + 1px);
          background-repeat: no-repeat, repeat;
          background-size: 0.65em auto, 100%;
          border: ${borderStandard};
          border-color: ${error ? colorRed : null};
          border-radius: ${borderRadiusMedium};
          box-sizing: border-box;
          color: ${value ? colorSteelDarkest : colorSteelLight};
          font-family: ${fontFamilyBase};
          font-size: ${fontSizeMedium};
          font-weight: ${fontWeightRegular};
          height: ${sizeXxlarge};
          outline: none;
          padding: 0 ${sizeMedium};
          width: 100%;

          :focus,
          :hover {
            border-color: ${error ? colorRed : colorBlueMedium};
            color: ${value ? colorSteelDarkest : colorSteelMedium};
          }

          :disabled {
            background-color: ${colorSteelLightest};
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' fill='%23C4CBD5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.969 0C.406 0 .125.688.53 1.094l4 4c.25.25.656.25.906 0l4-4C9.844.687 9.562 0 9 0H.969z' /%3E%3C/svg%3E");
            color: ${colorSteelMedium};
            pointer-events: none;
          }

          /* Hide default expand icon in IE11 */
          ::-ms-expand {
            display: none;
          }

          /* Remove inner dotted rectangle from Firefox */
          ::-moz-focus-inner {
            outline: none !important;
          }
          :-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
          }
        `}
        disabled={disabled}
        id={id}
        multiple={multiple}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        size={size}
        style={style}
        value={value}
      >
        {placeholder ? <option value={""}>{placeholder}</option> : null}

        {/* Render a single list or split into groups? Look for nested options. */}
        {options.map((option) => {
          if (isOptionString(option)) {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          } else if (isOptionObject(option)) {
            return (
              <option key={option.label + option.value} value={option.value}>
                {option.label}
              </option>
            );
          } else if (isOptionGroup(option)) {
            return (
              <optgroup key={option.label} label={option.label}>
                {option.options.map((subOption) => (
                  <option
                    key={subOption.label + subOption.value}
                    value={subOption.value}
                  >
                    {subOption.label}
                  </option>
                ))}
              </optgroup>
            );
          } else {
            throw new Error(
              `Invalid option: ${JSON.stringify(option)} on ${name}`,
            );
          }
        })}
      </select>

      {error ? <ErrorLabel error={error} htmlFor={id} /> : null}
    </>
  );
};

export default Select;
