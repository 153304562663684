import {
  borderRadiusMedium,
  colorBlueLighter,
  colorSteelDarker,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import { buttonCss } from "../../Button";
import Grid from "../../Grid";
import Icon from "../../Icon";
import Text from "../../Text";
import Link from "./Link";

type Link = {
  label: string;
  url: string;
};

interface Props {
  list: string[];
  product: Link | null;
  store: Link | null;
  title: string;
}

const Callout = ({ list, product, store, title }: Props) => {
  return (
    <Grid
      css={css`
        background: ${colorBlueLighter};
        border-radius: ${borderRadiusMedium};
        padding: 0.75rem;
      `}
      gap={"1.125rem"}
    >
      <Text as={"p"} size={"xsmall"} weight={"semibold"}>
        {title}
      </Text>
      <ul
        css={css`
          display: grid;
          gap: 0.5rem;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {list.map((item) => {
          return (
            <li key={item}>
              <Grid
                alignItems={"center"}
                css={css`
                  color: ${colorSteelDarker};
                  min-height: 2rem;
                `}
                gap={".5rem"}
                gridTemplateColumns={"2rem 1fr"}
              >
                <Icon
                  color={"inherit"}
                  css={css`
                    justify-self: center;
                  `}
                  size={"13px"}
                  source={"checkmark"}
                />
                <Text as={"p"} color={"inherit"} size={"xsmall"}>
                  {item}
                </Text>
              </Grid>
            </li>
          );
        })}
      </ul>
      <Grid gap={"0.5rem"}>
        {store ? (
          <Anchor
            css={[
              buttonCss({}),
              css`
                align-items: center;
                display: flex;
                height: 40px;
              `,
            ]}
            href={store.url}
          >
            <Text
              as={"span"}
              color={"inherit"}
              size={"medium"}
              responsive={true}
              weight={"medium"}
            >
              {store.label}
            </Text>
          </Anchor>
        ) : null}
        {product ? (
          <Link
            css={css`
              height: 2rem;
              justify-self: center;
            `}
            linkText={product.label}
            linkUrl={product.url}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Callout;
